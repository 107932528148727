<template>
    <div class="fixed h-14 top-0 right-0 left-0 bg-white shadow-2 flex items-center z-40" >
        <img :src="defineLogoByClient" class="mx-7 w-36 cursor-pointer" @click="$emit('logoClick')"/>
        <div class="flex items-center justify-end flex-1">
            <slot />
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue';
import vm from "@/viewModels/MainViewModel";

const Header = defineComponent({
    setup() {

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const defineLogoByClient = computed(() => {
            return vm.client?.logoUrl || 'https://iarisdev.blob.core.windows.net/aws/logo-easy.svg';
        });
        
        return { defineLogoByClient, defineColorByClient };
    }
});

export default Header;
</script>

<style>

</style>