
// Modules
import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { format } from "date-fns";
import { formatISO } from 'date-fns/fp';
import { ptBR } from "date-fns/locale";
import { Chart } from 'highcharts-vue';
import { titleCase } from '@/utils/stringFunctions';
import RecordService from '@/services/RecordService';
import AlertRankDTO from '@/dtos/AlertRankDTO';
import ReportsDTO from '@/dtos/ReportsDTO';

// Components
import CalendarComponent from '@/components/CalendarComponent.vue';
import SearchInput from '@/components/SearchInput.vue';
import SuperStatus from '@/views/super/SuperStatus.vue';
import ReportCard from '@/components/ReportCard.vue';


interface DashboardState {
    currentPage: "reports" | "alerts";
    calendarOpened: boolean;
    selectedDate: Date;
    proctoringClientId: string;
    startDate: string,
    finishDate: string,
    reports: ReportsDTO[] | undefined,
}

interface chartDTO {
    chart?: {
        type: string
    };
    title: {
        text: string,
    },
    xAxis: {
        categories: Array<string>,
        title: {
            text: null,
        },
    },
    yAxis: {
        min: 0,
        title: {
            text: string,
            align: string,
        },
        labels: {
            overflow: string,
        },
    },
    tooltip: {
        valueSuffix: string,
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: boolean,
            },
        },
    },
    credits: {
        enabled: boolean,
    },
    series: Array<{ name: string, data: number[] }>,
}

const SuperDashboard = defineComponent({    
    components: { CalendarComponent, SearchInput, SuperStatus, ReportCard, highcharts: Chart  },
    setup() {
        const route = useRoute();
        
        const state = reactive<DashboardState>({
            currentPage: "reports",
            calendarOpened: false,
            selectedDate: new Date(),
            proctoringClientId: "",
            startDate: "",
            finishDate: "",
            reports: undefined
        });
        
        const chartOptions = reactive<chartDTO>({
            chart: {
                type: 'bar',
            },
            title: {
                text: 'Quantidade de Alertas',
            },
            xAxis: {
                // categories: ['Release', 'Blocked', 'Review', 'Failure', 'Total'],
                categories: [],
                title: {
                    text: null,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Alertas',
                    align: 'high',
                },
                labels: {
                    overflow: 'justify',
                },
            },
            tooltip: {
                valueSuffix: ' ',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [],
        });

        const formattedDate = computed(() => {
            return format(state.selectedDate, "dd / MMMM / yyyy", { locale: ptBR });
        });

        const formattedDateTemp = computed(() => {
            let startDate = route.query["startDate"] ? new Date(route.query["startDate"].toString()) : new Date();
            let finishDate = route.query["endDate"] ? new Date(route.query["endDate"].toString()) : new Date();

            return `${titleCase(format(startDate, "E', 'dd LLL", { locale: ptBR }))} - ${titleCase(format(finishDate, "E', 'dd LLL", { locale: ptBR }))}`;
        });

        let data = {};

        const loadData = async () => {
            state.proctoringClientId = route.query["search"] ? route.query["search"].toString() : "";
            state.startDate = route.query["startDate"] ? route.query["startDate"].toString() : formatISO(new Date());
            state.startDate = state.startDate.substring(0, state.startDate.indexOf("T"));
            state.finishDate = route.query["endDate"] ? route.query["endDate"].toString() : formatISO(new Date());
            state.finishDate = state.finishDate.substring(0, state.finishDate.indexOf("T"));

            if(state.proctoringClientId) {
                if(state.currentPage === "reports") {
                    const [request] = RecordService.Report({
                        proctoringClientId: state.proctoringClientId,
                        startDate: state.startDate,
                        finishDate: state.finishDate,
                        list: true
                    });
                    request
                        .then((resp: ReportsDTO[]|undefined) => {
                            state.reports = resp;

                            // STARTED       FINISHING      PROICESSING        AUDITING      AUDITED         ERROR
                            let categories: string[] = [];
        
                            let started: number = 0;
                            let finishing: number = 0;
                            let processing: number = 0;
                            let auditing: number = 0;
                            let audited: number = 0;
                            let error: number = 0;

                            resp?.map((item: ReportsDTO) => {
                                switch(item.status) {
                                    case "STARTED":
                                        started++;
                                        break;
                                    case "FINISHING":
                                        finishing++;
                                        break;
                                    case "PROICESSING":
                                        processing++;
                                        break;
                                    case "AUDITING":
                                        auditing++;
                                        break;
                                    case "AUDITED":
                                        audited++;
                                        break;
                                    case "ERROR":
                                        error++;
                                        break;   
                                }
                            });
        
                            chartOptions.xAxis.categories = ["Started", "Finishing", "Processing", "Auditing", "Audited", "Error"];
                            chartOptions.series = [
                                { name: 'Quantidade', data: [started, finishing, processing, auditing, audited, error] },
                            ];
                            chartOptions.title.text = "Relatórios Gerados";
                        });
                } else {
                    const [request] = RecordService.AlertsRank({
                        proctoringClientId: state.proctoringClientId,
                        startDate: state.startDate,
                        finishDate: state.finishDate,
                        list: true
                    });
                    await request
                        .then((resp: AlertRankDTO[]) => {
                            console.log("Alerts Rank response:", resp);

                            let categories: string[] = [];
        
                            let release: number[] = [];
                            let blocked: number[] = [];
                            let review: number[] = [];
                            let failure: number[] = [];
                            let total: number[] = [];
        
                            resp.map((item: AlertRankDTO) => {
                                categories.push(item.category);
        
                                release.push(item.release);
                                blocked.push(item.blocked);
                                review.push(item.review);
                                failure.push(item.failure);
                                total.push(item.total);
                            });
        
                            chartOptions.xAxis.categories = categories;
                            chartOptions.series = [
                                { name: 'Release', data: release },
                                { name: 'Blocked', data: blocked },
                                { name: 'Review', data: review },
                                { name: 'Failure', data: failure },
                                { name: 'Total', data: total }
                            ];
                            chartOptions.title.text = "Quantidade de Alertas";
                        });
                }
            }

        };

        const download = async () => {
            const [request] = RecordService.Report({
                proctoringClientId: state.proctoringClientId,
                startDate: state.startDate,
                finishDate: state.finishDate,
                list: false
            });
            request
                .then((resp: any) => {
                    const blob = new Blob([resp], { type: 'text/csv' });
  
                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${state.proctoringClientId} ${state.startDate} - ${state.finishDate}`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
        };

        watch(() => route.params, loadData, { deep: true });
        watch(() => state.currentPage, loadData, { deep: true });

        onMounted(() => {
            window.scrollTo(0,0);
            loadData();
        });
       
        return { state, download, formattedDate, chartOptions, data, formattedDateTemp };
    }
});

export default SuperDashboard;
