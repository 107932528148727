
import { computed, defineComponent, PropType, reactive } from 'vue';
import vm from "@/viewModels/MainViewModel";

type OptionsType = {
    label: string
    value: string
};

const DropdownActionButton = defineComponent({
    emits: ['selected'],
    props: {
        frameId: {
            type: Number,
            required: false,
        },
        sessionId: {
            type: Number,
            required: false,
        },
        options: {
            type: Object as PropType<OptionsType>,
            required: true,
        },
        classes: {
            type: String,
            required: false
        },
        alertId: Number,
        disabled: Boolean,
    },
    setup() {
        const state = reactive({
            opened: false,
            selected: -1,
            default_classes: "border rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between"
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const handleSetOption = (option: number) => {
            state.selected = option;
        };

        return { handleSetOption, state, defineColorByClient };
    }
});

export default DropdownActionButton;
