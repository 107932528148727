
import { computed, defineComponent, ref } from "vue";
import { useAlert } from "@/hooks/alert";
import vm from "@/viewModels/MainViewModel";
import Icon from "@/components/Icon.vue";

//  Components
import Header from "@/components/Header.vue";
import ConfigModal from "@/components/ConfigModal.vue";
import Modal from "@/components/Modal.vue";

import UserDTO from "@/dtos/UserDTO";
import { useRouter } from "vue-router";

const AdminMainPage = defineComponent({
    components: { Header, Icon, ConfigModal, Modal },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
        const alert = useAlert();
        const { replace } = useRouter();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        /** Logout */
        const logout = () => {
            alert({
                icon: "logout",
                title: "Logout",
                message: "Tem certeza que deseja sair do sistema?",
                actions: [
                    {
                        title: "Cancelar",
                        primary: true,
                    },
                    {
                        title: "Sair",
                        action: () => {
                            replace({ name: "login" });
                            vm.logout();
                        },
                    },
                ],
            });
        };

        const configModal = ref<typeof ConfigModal | null>(null);
        const handleConfig = () => {
            configModal.value!.toogleModal();
        };

        return { logout, handleConfig, user, defineColorByClient, configModal };
    },
});

export default AdminMainPage;
