<template>
    <div class="relative">
        <div>
            <button
                class="border rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between dropdown"
                :style="{ borderColor: `${defineColorByClient}` }"
                @click="() => state.opened = !state.opened"
            >
                <slot />
            </button>
        </div>
        <div 
            class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" 
            role="menu" 
            aria-orientation="vertical" 
            aria-labelledby="menu-button" 
            tabindex="-1"
            v-if="state.opened"
        >
            <div 
                class="py-1" 
                @click="() => state.opened = !state.opened"
            >
                <a 
                    v-for="(option, index) in options" 
                    :key="index"
                    class="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                    :class="{
                        'font-bold': index === state.selected,
                    }"
                    @click="handleSetOption(index, option.value)"
                >
                    {{ option.label }}
                </a>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, PropType, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import vm from "@/viewModels/MainViewModel";

type OptionsType = {
    label: string
    value: string
};

const DropdownFilterButton = defineComponent({
    props: {
        options: {
            type: Object as PropType<OptionsType>,
            required: true,
        }
    },
    setup() {
        const { replace } = useRouter();
        const route = useRoute();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive({
            opened: false,
            selected: 1,
            value: route.query.examStatus || '',
        });

        const closeModal = () => {
            if (state.opened) state.opened = false;
        };

        const handleSetOption = (option: number, value: string) => {
            state.value = value;
            state.selected = option;
        };

        const order = () => {
            const order = state.value.length > 0 ? state.value : null;
            replace({ query: { ...route.query, order } });
        };

        watch(() => state.value, () => {
            order();
        });

        return {
            state,
            handleSetOption,
            closeModal,
            defineColorByClient
        };
    }
});

export default DropdownFilterButton;
</script>
