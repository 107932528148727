
import { computed, defineComponent, PropType, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import vm from "@/viewModels/MainViewModel";

type OptionsType = {
    label: string
    value: string
};

const DropdownFilterButton = defineComponent({
    props: {
        options: {
            type: Object as PropType<OptionsType>,
            required: true,
        }
    },
    setup() {
        const { replace } = useRouter();
        const route = useRoute();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive({
            opened: false,
            selected: 1,
            value: route.query.examStatus || '',
        });

        const closeModal = () => {
            if (state.opened) state.opened = false;
        };

        const handleSetOption = (option: number, value: string) => {
            state.value = value;
            state.selected = option;
        };

        const order = () => {
            const order = state.value.length > 0 ? state.value : null;
            replace({ query: { ...route.query, order } });
        };

        watch(() => state.value, () => {
            order();
        });

        return {
            state,
            handleSetOption,
            closeModal,
            defineColorByClient
        };
    }
});

export default DropdownFilterButton;
