<template>
    <div class="w-full h-full overflow-hidden flex flex-col items-stretch z-30" >
        <!-- Header -->
        <div class="relative h-18 w-full bg-white border-b border-neutral-1 px-7 flex items-center z-30 flex-shrink-0">
            <img src="@/assets/icons/calendar.svg"/>
            <div class="flex-1 pl-2">
                <p class="text-sm leading-5">{{ year }}</p>
                <p class="font-bold text-lg leading-5">{{ formattedDateTemp }}</p>
                <!-- <p class="font-bold text-lg leading-5">{{ dates }}</p> -->
            </div>
        </div>

        <!-- Conteúdo -->
        <div class="absolute mt-20 bg-white flex-1 flex flex-col overflow-hidden">
            <div class="flex-1 overflow-auto px-10 py-2 light-scroll">
                <Calendar v-model="dates" selectionMode="range" :manualInput="false" :inline="true" class="mb-10"/>
            </div>

            <div class="flex px-10 py-2">
                <button class="hollow flex-1 h-12" @click="$emit('closeCalendar')">Cancelar</button>
                <div class="w-4"></div>
                <button class="primary flex-1 h-12" @click="filter">Ok</button>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { titleCase } from '@/utils/stringFunctions';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import Calendar from 'primevue/calendar';
import Range from '@/components/Range.vue';
import { useRoute, useRouter } from 'vue-router';
import { formatISO } from 'date-fns/fp';

const CalendarComponent = defineComponent({
    components: { Calendar, Range },
    setup(props, context) {
        const { query } = useRoute();
        const { replace } = useRouter();

        const dates = ref<Date[]>([
            query['startDate'] ? new Date(query['startDate'].toString()) : new Date(),
            query['endDate'] ? new Date(query['endDate'].toString()) : new Date()
        ]);

        const state = reactive({
            startDate: query['startDate'] ? new Date(query['startDate'].toString()) : new Date(),
            endDate: query['endDate'] ? new Date(query['endDate'].toString()) : new Date()
        });

        const year = computed(() => state.startDate.getFullYear());

        const formattedDateTemp = computed(() => {
            return `${titleCase(format(state.startDate, "E', 'dd LLL", { locale: ptBR }))} - ${titleCase(format(state.endDate, "E', 'dd LLL", { locale: ptBR }))}`;
        });

        watch(dates, async (newDate, oldDate) => {
            state.startDate = new Date(dates.value[0].toDateString());
            state.endDate = dates.value[1] ? new Date(dates.value[1].toDateString()) : state.startDate;
        });

        const filter = () => {
            const newQuery = { ...query };
            newQuery['startDate'] = formatISO(state.startDate);
            newQuery['endDate'] = formatISO(state.endDate);

            replace({ query: newQuery });
            context.emit("closeCalendar");
        };

        return { state, year, formattedDateTemp, filter, dates };
    }
});

export default CalendarComponent;
</script>

<style>
.p-calendar {
    width: 100%;
}
.p-datepicker-group-container {
    width: 100%;
}
.p-datepicker-month {
    margin-right: 5px;
}
.p-link {
    width: 14px;
    height: 14px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.p-datepicker-prev {
    background-image: url("~@/assets/icons/caret-left.svg")
}
.p-datepicker-next  {
    background-image: url("~@/assets/icons/caret-right.svg")
}
.p-datepicker-calendar th {
    font-size: 0.875rem;
    opacity: .7;
    font-weight: normal;
    height: 50px;
}

.p-datepicker-calendar td span {
    font-size: 0.875rem;
    height: 34px;
    width: 34px;
    outline: none;
    transition: .3s;
    border-radius: 18px;
    color: #363738;
}
.p-datepicker-calendar td span[tabindex="0"] {
    background: #04986D;
    color: white;
    font-weight: 500;
}
.p-datepicker-calendar td span.p-highlight {
    color: #f4f4f4;
    background: #04986D;
    opacity: 1;
}
.p-datepicker-calendar td span.p-highlight.p-disabled{
    background: transparent;
}
.p-datepicker-header {
    margin-bottom: 8px;
}
.p-datepicker-header span {
    font-weight: 500;
    color: #363738;
}
.p-disabled {
    opacity: .4;
}
</style>