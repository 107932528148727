
import { titleCase } from '@/utils/stringFunctions';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import Calendar from 'primevue/calendar';
import Range from '@/components/Range.vue';
import { useRoute, useRouter } from 'vue-router';
import { formatISO } from 'date-fns/fp';

const CalendarComponent = defineComponent({
    components: { Calendar, Range },
    setup(props, context) {
        const { query } = useRoute();
        const { replace } = useRouter();

        const dates = ref<Date[]>([
            query['startDate'] ? new Date(query['startDate'].toString()) : new Date(),
            query['endDate'] ? new Date(query['endDate'].toString()) : new Date()
        ]);

        const state = reactive({
            startDate: query['startDate'] ? new Date(query['startDate'].toString()) : new Date(),
            endDate: query['endDate'] ? new Date(query['endDate'].toString()) : new Date()
        });

        const year = computed(() => state.startDate.getFullYear());

        const formattedDateTemp = computed(() => {
            return `${titleCase(format(state.startDate, "E', 'dd LLL", { locale: ptBR }))} - ${titleCase(format(state.endDate, "E', 'dd LLL", { locale: ptBR }))}`;
        });

        watch(dates, async (newDate, oldDate) => {
            state.startDate = new Date(dates.value[0].toDateString());
            state.endDate = dates.value[1] ? new Date(dates.value[1].toDateString()) : state.startDate;
        });

        const filter = () => {
            const newQuery = { ...query };
            newQuery['startDate'] = formatISO(state.startDate);
            newQuery['endDate'] = formatISO(state.endDate);

            replace({ query: newQuery });
            context.emit("closeCalendar");
        };

        return { state, year, formattedDateTemp, filter, dates };
    }
});

export default CalendarComponent;
