
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Icon from "@/components/Icon.vue";
import BaseAvatarGeneric from "@/components/BaseAvatarGeneric.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import AlertList from "@/components/audit/AlertList.vue";
import Respostas from "@/views/admin/exames/Respostas.vue";
import { alertCategories } from "@/dtos/AlertaDTO";
import AlertListDTO from "@/dtos/AlertListDTO";
import ImageAlertListDTO, { ImageAlertMatrixDTO } from "@/dtos/ImageAlertListDTO";
import Loading from "@/components/Loading.vue";
import ExameService from "@/services/ExameService";
import AlertDTO from "@/dtos/AlertDTO";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import AuditoriaDTO from "@/dtos/AuditoriaDTO";
import AuditoriaImagemDTO from "@/dtos/AuditoriaImagemDTO";
import RecordService from "@/services/RecordService";
import FrameCard from "@/components/auditFrame/FrameCard.vue";
import AudioCard from "@/components/auditFrame/AudioCard.vue";
import ProctoringResponseDTO, { sessionObject } from "@/dtos/ProctoringResponseDTO";
import DropdownActionButton from "@/components/DropdownActionButton.vue";
import vm from "@/viewModels/MainViewModel";
import { tradutorAlerta } from "@/utils/stringFunctions";

interface ExamesState {
    loading: boolean;
    faceImageURL: string;
    clientId: string;
    userCpf: string;
    userName: string;
    examId: string;
    examStartTime: string;
    examEndTime: string;
    proctoringType: string;
    status: "Started" | "Finished" | "Processing" | "Auditing" | "Audited" | null;
    alerts: AlertListDTO[];
    imageAlerts: ImageAlertListDTO[];
    audioCameraURL: string;
    videoCameraURL: string;
    videoScreenURL: string;
    hashStatus: boolean;
    videoAudit: boolean;
    audioAudit: boolean;
    frames: any[];
    id: string;
    key: number;
    creationDate: string;
    lastUpdateDate: string;
    deletedFlag: boolean;
    idAgendamentoSelecionado: string;
    selectedDate: Date;
    lastDate: Date;
    realtimeSeconds: number;
    sessions: sessionObject[];

    totalPages: number;
    totalPagesAlerts: number;
    page: number;
    pageSessions: number;
    itemsPage: number;
    alertsPage: ImageAlertMatrixDTO[][];
    onlyAlertsPage: ImageAlertMatrixDTO[][];
    showedAlertsPage: ImageAlertMatrixDTO[][];
    imgAlertsQtd: number;

    audioAlertsPage: any;

    filter?: string;

    currentExam: string,
    nextExam: string,
    previousExam: string,

    imageCardModal: boolean,
    imageModal: string,
    categoryModal: alertCategories,
    alertStatusModal: string,
    idModal: number,
    frameIdModal: number,
    candidates: ProctoringResponseDTO[],
    candidatePage: number,
    currentExamIndex: number,
    showLegend: boolean,
}
const AuditPage = defineComponent({
    components: {
        Icon,
        avatar: BaseAvatarGeneric,
        AlertList,
        VideoPlayer,
        FrameCard,
        AudioCard,
    },
    setup() {
        const router = useRouter();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive<ExamesState>({
            loading: true,
            faceImageURL: "",
            alerts: [],
            imageAlerts: [],
            audioAudit: false,
            clientId: '',
            creationDate: '',
            deletedFlag: false,
            examEndTime: '',
            examId: '',
            examStartTime: '',
            proctoringType: '',
            hashStatus: false,
            id: '',
            key: 0,
            lastUpdateDate: '',
            status: null,
            userCpf: '',
            userName: '',
            videoAudit: false,
            audioCameraURL: '',
            videoCameraURL: '',
            videoScreenURL: '',
            idAgendamentoSelecionado: "",
            selectedDate: new Date(),
            lastDate: new Date(),
            realtimeSeconds: 30,
            totalPages: 1,
            totalPagesAlerts: 1,
            page: 1,
            pageSessions: 1,
            itemsPage: 8,
            alertsPage: [],
            onlyAlertsPage: [],
            showedAlertsPage: [],
            sessions: [],
            frames: [],
            imgAlertsQtd: 0,

            audioAlertsPage: [],

            filter: "alerts",

            currentExam: "",
            nextExam: "",
            previousExam: "",

            imageCardModal: false,
            imageModal: "",
            categoryModal: "",
            alertStatusModal: "",
            idModal: 0,
            frameIdModal: 0,
            candidates: [],
            candidatePage: 1,
            currentExamIndex: -1,
            showLegend: true
        });

        const handleSelectFilter = (value: string) => {
            state.filter = value;
            state.page = 1;
        };
        
        watch(() => state.filter, () => {
            state.filter == 'alerts' ? state.showedAlertsPage = state.onlyAlertsPage : state.showedAlertsPage = state.alertsPage;
        });

        const activeBtn = ref<string>(state.proctoringType.toUpperCase());

        watch(() => state.proctoringType, (newValue) => {
            if(!newValue) {
                activeBtn.value = newValue;
            }
        });

        watch(() => activeBtn.value, () => {
            resize();
            state.page = 1;
            activeBtn.value === "AUDIO" && paginationAudioAlerts();
            // activeBtn.value === "IMAGE" && paginationAudioAlerts();
            activeBtn.value === "IMAGE" && paginationImageAlerts();
        });

        const formatStatus = (status: string) => {
            switch (status) {
                case "Active":
                    return "Agendado";
                case "Executed":
                    return "Executado";
                case "Canceled":
                    return "Cancelado";
                case "Running":
                    return "Em execução";
                case "AUDITING":
                    return "Aguardando auditoria";
                case "PROCESSING":
                    return "Em processamento";
                case "AUDITED":
                    return "Auditado";
                case "STARTED":
                    return "Iniciado";
                case "FINISHED":
                    return "Executado";
                default:
                    return "-";
            }
        };

        const formattedDate = (value: any) => {
            return format(new Date(value), "dd/MM/yyyy HH:mm:ss", { locale: ptBR });
        };

        const alertasFiltrados = computed(() => {
            const alerts: Array<AlertListDTO> = [];
            for (let i = 0; i < state.alerts.length; i++) {
                if (state.alerts[i].type.toUpperCase() === activeBtn.value) {
                    alerts.push(state.alerts[i]);
                }
                if (activeBtn.value === 'VIDEO') {
                    if (state.alerts[i].type.toUpperCase() === 'AUDIO') {
                        alerts.push(state.alerts[i]);
                    }
                }
            }

            const finalAlerts = JSON.parse(JSON.stringify(alerts));

            return finalAlerts;
        });

        const paginationImageAlerts = () => {
            state.totalPages = Math.ceil(state.frames.length / state.itemsPage) || 1;
            let contAuditavel = 0;
            let aux: any[] = [];
            state.alertsPage = [];
            state.onlyAlertsPage = [];
            let cont = 0;
            
            // Create pages and add items
            for (let i = 0; i < state.totalPages; i++) {
                state.alertsPage.push([]);
                state.onlyAlertsPage.push([]);

                // Add items on page
                for (let c = 0; c < state.itemsPage; c++) {
                    if (state.frames[cont]) {
                        const alerta = { ...state.alerts.find((item) => item.proctoringResultFrameId === state.frames[cont].id) };

                        state.alertsPage[i].push({ ...state.frames[cont], index: cont, alerta: alerta });
                        (JSON.stringify(alerta) !== '{}') && aux.push({ ...state.frames[cont], index: cont, alerta: alerta });
                        (JSON.stringify(alerta) !== '{}') && contAuditavel++;

                        cont++;
                    }
                }                
            }
            
            // Create pages and add items from only alerts filter
            let contAlerts = 0;
            state.totalPagesAlerts = Math.ceil(aux.length / state.itemsPage) || 1;
            for (let i = 0; i < state.totalPagesAlerts; i++) {
                state.onlyAlertsPage.push([]);
                for (let c = 0; c < state.itemsPage; c++) {
                    if (aux[contAlerts]) {
                        state.onlyAlertsPage[i].push({ ...aux[contAlerts], index: contAlerts });
                        contAlerts++;
                    }
                }
            }

            state.imgAlertsQtd = contAuditavel;

            state.filter == 'alerts' ? state.showedAlertsPage = state.onlyAlertsPage : state.showedAlertsPage = state.alertsPage;
        };
        
        const paginationAudioAlerts = () => {
            state.totalPages = Math.ceil(alertasFiltrados.value.length / state.itemsPage) || 1;
            let contAuditavel = 0;
            let aux = [];
            state.alertsPage = [];
            let cont = 0;
            for (let i = 0; i < state.totalPages; i++) {
                state.alertsPage.push([]);
                for (let c = 0; c < state.itemsPage; c++) {
                    if (alertasFiltrados.value[cont]) {
                        state.alertsPage[i].push({ ...alertasFiltrados.value[cont], index: cont });
                        if (alertasFiltrados.value[cont]?.length){
                            contAuditavel += alertasFiltrados.value[cont]?.length;
                            aux.push({ ...alertasFiltrados.value[cont], index: cont });
                        }
                        cont++;
                    }
                }
            }
            state.imgAlertsQtd = contAuditavel;
                        
            state.audioAlertsPage = state.alertsPage;
        };

        const carregarExame = () => {
            state.loading = true;

            const alertas: Array<AlertDTO> = [];

            if(router.currentRoute.value.params.id.toString()){
                state.loading = true;
                const [request] = ExameService.GetExame(state.currentExam || router.currentRoute.value.params.id.toString());
                request
                    .then((resp) => {
                        state.alerts = resp.alerts;
                        state.frames = resp.frames;
                        state.faceImageURL = resp.faceImageURL;
                        state.imageAlerts = resp.imageAlerts;
                        state.audioAudit = resp.audioAudit;
                        state.clientId = resp.clientId;
                        state.creationDate = resp.creationDate;
                        state.deletedFlag = resp.deletedFlag;
                        state.examEndTime = resp.examEndTime;
                        state.examId = resp.examId;
                        state.proctoringType = resp.proctoringType;
                        activeBtn.value = activeBtn.value || (resp.proctoringType.toUpperCase() === 'REALTIME' && 'VIDEO') || resp.proctoringType.toUpperCase() || "SCREEN";
                        state.examStartTime = resp.examStartTime;
                        state.hashStatus = resp.hashStatus;
                        state.id = resp.id;
                        state.lastUpdateDate = resp.lastUpdateDate;
                        state.status = resp.status;
                        state.userCpf = resp.userCpf;
                        state.videoAudit = resp.videoAudit;
                        state.audioCameraURL = resp.audioCameraURL;
                        state.videoCameraURL = resp.videoCameraURL;
                        state.videoScreenURL = resp.videoScreenURL;
                    })
                    .then(() => {
                        state.lastDate = new Date();
                        paginationImageAlerts();
                        paginationAudioAlerts();
                        // state.sessions = resp.sessions;

                        candidatesConfig();
                    })
                    .then(() => {
                        paginationImageAlerts();
                    })
                    .catch((error) => {
                        activeBtn.value = state.proctoringType.toUpperCase() || "SCREEN";
                    })
                    .finally(() => (state.loading = false));
                
                return alertas;
            }
        };

        const loadCandidates = (page: number, save: boolean = true) => {
            const [request] = ExameService.GetExams({
                page: page
            });

            return request
                .then((resp) => {
                    save && (state.candidates = resp.data);
                    return resp.data;
                })
                .catch((error) => console.log(error));
        };

        const candidatesConfig = async () => {
            // Encontre o exame atual
            let candidatePageSearch = 1;
            state.candidates && (state.currentExamIndex = state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString()));
            
            while(state.currentExamIndex == -1) {
                await loadCandidates(candidatePageSearch, true);
                state.currentExamIndex = state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString());

                if(state.currentExamIndex == -1) {
                    candidatePageSearch++;
                } else {
                    state.candidatePage = candidatePageSearch;
                    candidatePageSearch = 0;
                }
            }
            
            const respLength = state.candidates.length;
            
            if(state.currentExamIndex >= 0) {
                if(state.currentExamIndex === 0 && state.candidatePage !== 1) {
                    const previousList = await loadCandidates(state.candidatePage - 1, false);
                    previousList && (state.previousExam = previousList[previousList.length-1].id);
                } else {
                    state.previousExam = state.currentExamIndex > 0 ? state.candidates[(state.currentExamIndex - 1)].id : "";
                }
    
                if(state.currentExamIndex === 9) {
                    const newPage = await loadCandidates(state.candidatePage+1, false);
                    newPage && (state.nextExam = newPage[0].id);
                } else {
                    state.nextExam = state.candidates[(state.currentExamIndex + 1) % respLength].id;
                }
            }

        };

        const previousOrNextCandidate = (id: string, clickPrevious: boolean = false) => {
            router.push({ name: 'exame', params: { id: id } });

            if(state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString()) === 0 && state.candidatePage !== 1 && clickPrevious) state.candidatePage--;
            if(state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString()) === state.candidates.length-1) state.candidatePage++;
            
            state.currentExam = id;
            carregarExame();
        };

        const updateScheduleStatus = async () => {
            if(router.currentRoute.value.params.id.toString()){
                state.loading = true;
                const [request] = ExameService.GetExame(router.currentRoute.value.params.id.toString());
                await request
                    .then((resp) => {
                        state.alerts = resp.alerts;
                        state.imageAlerts = resp.imageAlerts;
                    });
            }
        };

        onMounted(() => {
            resize();
            carregarExame();
            setInterval(() => {
                // console.log(state.status);
                if (
                    state.proctoringType === 'REALTIME' && 
                    (
                        state.status?.toUpperCase() === 'PROCESSING' || 
                        state.status?.toUpperCase() === 'FINISHED' || 
                        state.status?.toUpperCase() === 'STARTED'
                    )
                ) {
                    carregarExame();
                    state.realtimeSeconds = 30;
                }
            }, 30000);

            setInterval(() => {
                if (
                    state.proctoringType === 'REALTIME' && 
                    (
                        state.status?.toUpperCase() === 'PROCESSING' || 
                        state.status?.toUpperCase() === 'FINISHED' || 
                        state.status?.toUpperCase() === 'STARTED'
                    )
                ) {
                    state.realtimeSeconds = state.realtimeSeconds-1;
                }
            }, 1000);
        });
        
        // window.addEventListener("resize", () => {
        //     window.innerHeight >= 800 ? state.itemsPage = 12 : state.itemsPage = 8;
        //     // paginationImageAlerts();
        // });

        window.addEventListener("resize", () => {
            resize();
            paginationImageAlerts();
            paginationAudioAlerts();
        });

        const resize = () => {
            if(activeBtn.value === "AUDIO") {
                window.innerHeight >= 800 ? state.itemsPage = 18 : state.itemsPage = 8;
            }
            if(activeBtn.value === "IMAGE") {
                window.innerHeight >= 800 ? state.itemsPage = 12 : state.itemsPage = 8;
            }
        };

        const auditar = (
            situacao: "REVIEWING" | "RELEASED" | "BLOCKED" | "SYSTEM_FAILURE",
            alerta?: number,
            type?: "page" | "all",
        ) => {
            let auditoria: AuditoriaDTO[] = [];
            
            if(type) {
                let alerts = alertasFiltrados.value;
                
                if(type === "page") {
                    alerts = state.audioAlertsPage[state.page-1];
                }
            
                if(alerts) {
                    (alerts as any[]).map((alert: any) => {
                        auditoria.push({ alertId: alert.id, alertStatus: situacao });
                    }) || [];
                }
            } else if (alerta != undefined) {
                auditoria = [{
                    alertId: alerta,
                    alertStatus: situacao,
                }];
            }

            const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), auditoria);
            request
                .then(() => {
                    if(type) {
                        alertasFiltrados.value.map((item: any) => {
                            const matchingAlert = auditoria.find(audited => item.id === audited.alertId);
                            if (matchingAlert) {
                                item.alertStatus = situacao;
                            }
                            return item;
                        });
                    } else {
                        alertasFiltrados.value.forEach((item: { id: number; alertStatus: string; }) => item.id === auditoria[0].alertId && (item.alertStatus = auditoria[0].alertStatus));
                    }

                    paginationAudioAlerts();
                });
        };

        const handleAuditImage = (type: "page" | "all") => {
            activeBtn.value === "IMAGE" && auditarImagem("RELEASED", undefined, undefined, type);
            activeBtn.value === "AUDIO" && auditar("RELEASED", undefined, type);
            carregarExame();
        };

        const auditarImagem = async (
            situacao: "REVIEWING" | "RELEASED" | "BLOCKED" | "SYSTEM_FAILURE",
            alerta?: number,
            frameId?: number,
            type?: "page" | "all",
        ) => {
            let auditoria: AuditoriaImagemDTO[] = [];

            if(type) {
                let alerts: ImageAlertMatrixDTO[] | ImageAlertMatrixDTO[][] | AlertListDTO[] = state.alerts;
                
                if(type === "page") {
                    alerts = state.showedAlertsPage[state.page-1];
                } else if (type === "all") {
                    alerts = state.alerts;
                }
            
                if(alerts) {
                    (alerts as any[]).map((item: any) => {
                        type === "page" && auditoria.push({ alertId: item.alerta.id, alertStatus: situacao, frameId: item.alerta.proctoringResultFrameId });
                        type === "all" && auditoria.push({ alertId: item.id, alertStatus: situacao, frameId: item.proctoringResultFrameId });
                    }) || [];
                }

            } else if (alerta != undefined && frameId != undefined) {
                auditoria = [{
                    alertId: alerta,
                    alertStatus: situacao,
                    frameId: frameId,
                }];
            }
            
            const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), auditoria);
            await request
                .then(() => {
                    if(type) {
                        state.alerts.map(alert => {
                            const matchingAlert = auditoria.find(item => alert.proctoringResultFrameId === item.frameId && alert.id === item.alertId);
                            if (matchingAlert) {
                                alert.alertStatus = situacao;
                            }
                            return alert;
                        });
                        
                    } else {
                        state.alerts.map(frame => {
                            frame.proctoringResultFrameId === frameId && 
                            ((frame.id === alerta) && (frame.alertStatus = situacao));
                        });
                    }
                });
            paginationImageAlerts();
        };

        /** Redirect */
        const redirect = () => {
            const url = router.resolve({
                path: `/exam-report/${router.currentRoute.value.params.id.toString()}`,
            });
            window.open(url.href, "_blank");
        };

        /** Reprocessing */
        const handleReprocessing = () => {
            const [request] = ExameService.GetReprocessing(router.currentRoute.value.params.id.toString());

            return request
                .then((resp) => {
                    carregarExame();
                })
                .catch((error) => console.log(error));
        };

        const openImage = (item: ImageAlertMatrixDTO) => {
            state.imageCardModal = true;
            state.categoryModal = item.alerta.category,
            state.idModal = item.alerta.id,
            state.frameIdModal = item.id,
            state.imageModal = item.url;
            state.alertStatusModal = item.alerta.alertStatus;
        };

        const goBack = () => {
            window.history.go(-1);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        };

        return {
            state,
            redirect,
            formatStatus,
            updateScheduleStatus,
            alertasFiltrados,
            activeBtn,
            formattedDate,
            auditar,
            auditarImagem,
            handleSelectFilter,
            defineColorByClient,
            previousOrNextCandidate,
            openImage,
            handleAuditImage,
            tradutorAlerta,
            goBack,
            handleReprocessing,
        };
    },
});

export default AuditPage;
