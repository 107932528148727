<template>
    <div class="relative">
        <div>
            <button
                :class="classes ? classes : state.default_classes"
                :disabled=disabled
                @click="() => state.opened = !state.opened"
            >
                <slot/>
            </button>
        </div>
        <div
            class="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
            v-if="state.opened"
        >
            <div
                class="py-1"
                @click="() => state.opened = !state.opened"
            >
                <a
                    v-for="(option, index) in options"
                    :key="index"
                    class="text-gray-700 block px-4 py-2 text-sm cursor-pointer"
                    :class="{
                        'font-bold': index === state.selected,
                    }"
                    @click="() => {
                        handleSetOption(index)
                        $emit('selected', option.value, alertId, frameId, sessionId)
                    }"
                >
                    {{ option.label }}
                </a>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, PropType, reactive } from 'vue';
import vm from "@/viewModels/MainViewModel";

type OptionsType = {
    label: string
    value: string
};

const DropdownActionButton = defineComponent({
    emits: ['selected'],
    props: {
        frameId: {
            type: Number,
            required: false,
        },
        sessionId: {
            type: Number,
            required: false,
        },
        options: {
            type: Object as PropType<OptionsType>,
            required: true,
        },
        classes: {
            type: String,
            required: false
        },
        alertId: Number,
        disabled: Boolean,
    },
    setup() {
        const state = reactive({
            opened: false,
            selected: -1,
            default_classes: "border rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between"
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const handleSetOption = (option: number) => {
            state.selected = option;
        };

        return { handleSetOption, state, defineColorByClient };
    }
});

export default DropdownActionButton;
</script>
