
import { computed, defineComponent, PropType, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { IAlert } from "@/dtos/AlertaDTO";
import DropdownActionButton from "../DropdownActionButton.vue";
import { tradutorAlerta } from "@/utils/stringFunctions";

const AudioCard = defineComponent({
    components: {
        Icon,
        DropdownActionButton,
    },
    emits: ['check', 'audit'],
    props: {
        index: {
            type: Number,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
        alertStatus: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        start: {
            type: Number,
            required: true,
        },        
        end: {
            type: Number,
            required: true,
        },        
    },
    setup(props, { emit }) {
        const state = reactive({
            collapsed: true,
        });

        const playButton = (start: number) => {
            const auditAudio = document.getElementById(
                "auditAudio"
            ) as HTMLAudioElement;
            const time = start / 1000;
            auditAudio.currentTime = time;
            auditAudio.play();
        };

        const text = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'Não auditado';
                case "RELEASED":
                    return 'Alerta liberado';
                case "BLOCKED":
                    return 'Indício de fraude';
                case "SYSTEM_FAILURE":
                    return 'Alerta inválido';
            }
        };

        const getColor = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'bg-gray-5';
                case "RELEASED":
                    return 'bg-success';
                case "BLOCKED":
                    return 'bg-error';
                case "SYSTEM_FAILURE":
                    return 'bg-eyellow';
            }
        };

        const handleCollapse = () => {
            state.collapsed = !state.collapsed;
        };

        const handleAudit = (value: string, id: number ) => {
            // console.log(value, id, resultFrameId);
            emit('audit', value, id);
        };

        const VALIDATE_ACTIONS_OPTIONS = [
            {
                label: "Não auditado",
                value: "REVIEWING",
            },
            {
                label: "Liberar Alerta",
                value: "RELEASED",
            },
            {
                label: "Indício de fraude",
                value: "BLOCKED",
            },
            {
                label: "Alerta Inválido",
                value: "SYSTEM_FAILURE",
            },
        ];

        function millisToMinutesAndSeconds(millis: number) {
            let minutes = Math.floor(millis / 60000);
            let seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
        }

        return {
            tradutorAlerta,
            playButton,
            handleCollapse,
            text,
            handleAudit,
            state,
            VALIDATE_ACTIONS_OPTIONS,
            getColor,
            millisToMinutesAndSeconds
        };
    },
});

export default AudioCard;
