
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DropdownFilterButton from '@/components/DropdownFilterButton.vue';
import Icon from '@/components/Icon.vue';
import StatusFilterButton from '@/components/StatusFilterButton.vue';
import vm from "@/viewModels/MainViewModel";

const StatusFilter = defineComponent({
    components: { DropdownFilterButton, Icon, StatusFilterButton },
    setup(){
        const route = useRoute();
        const { replace } = useRouter();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive({
            filterBy: route.query.status || -1,
            selected: -1,
            fraud: false,
        });

        const setFilter = (status: number, fraud: boolean = false) => {
            state.filterBy = status;
            state.selected = fraud ? 6 : status;
            state.fraud = fraud;
        };

        const filter = () => {
            const status = state.filterBy > -1 ? state.filterBy : null;
            replace({ query: { 
                ...route.query, 
                ...(status !== 4 && route.query.order === '0' ? { order: 1 } : undefined),
                status, 
                fraud: state.fraud.toString()
            } });
        };

        watch(() => state.filterBy, () => {
            filter();
        });
        watch(() => state.fraud, () => {
            filter();
        });

        onMounted(() => {
            replace({ query: { ...route.query, order: 1 } });
        });

        const dropdown = ref();
        const closeModal = () => {
            if (dropdown.value) dropdown.value.closeModal();
        };

        return { state, setFilter, filter, dropdown, closeModal, defineColorByClient };
    }
});

export default StatusFilter;
