
import { computed, defineComponent, PropType, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { IAlert } from "@/dtos/AlertaDTO";
import DropdownActionButton from "../DropdownActionButton.vue";
import { tradutorAlerta } from "@/utils/stringFunctions";
import { alertCategories } from "@/dtos/AlertaDTO";
import AlertListDTO from "@/dtos/AlertListDTO";

const FrameCard = defineComponent({
    components: {
        Icon,
        DropdownActionButton,
    },
    emits: ['check', 'audit', 'openImage'],
    props: {
        id: {
            type: Number,
        },
        frameId: {
            type: Number,
        },
        index: {
            type: Number,
        },
        sessionId: {
            type: Number,
        },        
        image: {
            type: String,
        },
        category: {
            type: String as PropType<alertCategories>,
        },
        alertStatus: {
            type: String,
        },
        alerta: {
            type: Object as PropType<AlertListDTO>,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            collapsed: true,
            loadedImage: false,
        });

        const playButton = (start: number) => {
            const auditVideo = document.getElementById(
                "auditVideo"
            ) as HTMLVideoElement;
            const time = start / 1000;
            auditVideo.currentTime = time;
            auditVideo.play();
        };

        const text = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'Não auditado';
                case "RELEASED":
                    return 'Alerta liberado';
                case "BLOCKED":
                    return 'Indício de fraude';
                case "SYSTEM_FAILURE":
                    return 'Alerta inválido';
            }
        };

        const getColor = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return '#C5C5C5';
                case "RELEASED":
                    return '#2CC89B';
                case "BLOCKED":
                    return '#F66F6F';
                case "SYSTEM_FAILURE":
                    return '#FBC55D';
            }
        };

        const handleCollapse = () => {
            state.collapsed = !state.collapsed;
        };

        const handleAudit = (value: string, id?: number, resultFrameId?: number | string ) => {
            // console.log(value, id, resultFrameId);
            emit('audit', value, id, resultFrameId);
        };

        const VALIDATE_ACTIONS_OPTIONS = [
            {
                label: "Não auditado",
                value: "REVIEWING",
            },
            {
                label: "Liberar Alerta",
                value: "RELEASED",
            },
            {
                label: "Indício de fraude",
                value: "BLOCKED",
            },
            {
                label: "Alerta Inválido",
                value: "SYSTEM_FAILURE",
            },
        ];

        const onImageLoad = () => {
            state.loadedImage = true;
        };

        return {
            tradutorAlerta,
            playButton,
            handleCollapse,
            text,
            handleAudit,
            state,
            VALIDATE_ACTIONS_OPTIONS,
            getColor,
            onImageLoad
        };
    },
});

export default FrameCard;
