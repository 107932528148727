
import { defineComponent } from 'vue';

const FinishIllustration = defineComponent({
    props: {
        color: {
            type: String,
            default: "#04986D"
        },
    },
    setup() {
        function LightenDarkenColor(col: string, amt: number) {
            let num = parseInt(col.replace("#", ""), 16);
            let r = (num >> 16) + amt;
            let b = ((num >> 8) & 0x00FF) + amt;
            let g = (num & 0x0000FF) + amt;
            let newColor = g | (b << 8) | (r << 16);

            return newColor.toString(16);
        }

        return { LightenDarkenColor };
    }
});

export default FinishIllustration;
