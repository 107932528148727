<template>
    <div class="flex flex-col mt-5 md:max-h-60vh">
        <div
            :class="
                (overlay ? 'bg-ewhite-rgba' : '') +
                    ' filters h-14 flex items-center px-3'
            "
        >
            <label class="inline-flex items-center check-all">
                <input
                    type="checkbox"
                    class="form-checkbox text-red-500 h-4 w-4 rounded"
                    :style="state.checked ? { backgroundColor: defineColorByClient } : { backgroundColor: '#ffffff' }"
                    v-model="state.checked"
                    @click="checkAllAlerts"
                />
            </label>
            
            <DropdownActionButton
                class="ml-2 alert-text"
                :options="VALIDATE_ACTIONS_OPTIONS"
                classes="border h-9 rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between"
                @selected="handleAudit"
            >
                <p :style="{ color: defineColorByClient }">Avaliar</p>
                <Icon icon="arrow_drop_down" :color="defineColorByClient" class="ml-2" />
            </DropdownActionButton>
            <DropdownActionButton
                class="ml-2 alert-text"
                classes="border h-9 rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between"
                :options="FILTER_OPTIONS"
                @selected="handleSelectFilter"
            >
                <p :style="{ color: defineColorByClient }">Filtrar</p>
                <Icon icon="arrow_drop_down" :color="defineColorByClient" class="ml-2" />
            </DropdownActionButton>
            <DropdownActionButton
                class="ml-2 alert-text"
                classes="border h-9 rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between"
                :options="ORDER_OPTIONS"
                @selected="handleSelectOrder"
            >
                <Icon icon="sort" :color="defineColorByClient" class="mr-2" />
                <p :style="{ color: defineColorByClient }">Ordenar</p>
                <Icon icon="arrow_drop_down" :color="defineColorByClient" class="ml-2" />
            </DropdownActionButton>
        </div>
        <div
            :class="
                (overlay ? 'bg-gray-4-rgba' : 'bg-neutral-3') + ' rounded py-5 overflow'
            "
            v-if="state.alerts_array.length"
        >
        
            <div class="flex align-center ml-4">
                <Icon icon="bell" class="mr-4 float-left" :color="defineColorByClient" />
                <span class="mr-3 alerts-text"> Quantidade total de alertas: </span>
                <span
                    class="
                                        px-2
                                        text-white text-s
                                        font-medium
                                        rounded
                                        alerts-text
                                    "
                    :style="{ backgroundColor: defineColorByClient }"
                >{{state.alerts_array.length}}</span>
            </div>
            
            <div v-if="state.loading" class="flex justify-center h-screen">
                <Loading color="var(--primary-color)" />
            </div>
            <alertCard
                v-for="(alerts, index) in state.alerts_array"
                :alerts="alerts"
                :key="index"
                :filter="state.filter"
                :overlay="overlay"
                :proctoringStatus="proctoringStatus"
                @check="toogleCheck"
                @audit="handleAudit"
            />
        </div>
        <div v-else>
            <p
                class="flex justify-center text-gray-400 mt-2"
            >Nenhum alerta encontrado!</p>
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, PropType, reactive, watch } from "vue";

import { AlertaDTO, IAlert } from "@/dtos/AlertaDTO";
import { useRoute, useRouter } from "vue-router";
import AlertCard from "./AlertCard.vue";
import DropdownActionButton from "../DropdownActionButton.vue";
import Icon from "../Icon.vue";
import RecordService from "@/services/RecordService";
// import ProctoringService from "@/services/ProctoringService";
import Loading from "@/components/Loading.vue";
import AuditoriaDTO from "@/dtos/AuditoriaDTO";
import vm from "@/viewModels/MainViewModel";

interface State {
    alerts_array: IAlert[][];
    order_array: IAlert[];
    checkedStatus: string;
    checked: boolean;
    filter?: string;
    loading: boolean;
}

export const VALIDATE_ACTIONS_OPTIONS = [
    {
        label: "Não auditado",
        value: "REVIEWING",
    },
    {
        label: "Liberar Alerta",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Alerta Inválido",
        value: "SYSTEM_FAILURE",
    },
];

const FILTER_OPTIONS = [
    {
        label: "Liberados",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Não auditados",
        value: "REVIEWING",
    },
    {
        label: "Inválidos",
        value: "SYSTEM_FAILURE",
    },
    {
        label: "Vídeo",
        value: "Video",
    },
    {
        label: "Áudio",
        value: "Audio",
    },
    {
        label: "Todos",
        value: "",
    },
];

const ORDER_OPTIONS = [
    {
        label: "Liberados",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Não auditados",
        value: "REVIEWING",
    },
    {
        label: "Inválidos",
        value: "SYSTEM_FAILURE",
    },
    {
        label: "Todos",
        value: "",
    },
];

const AlertList = defineComponent({
    components: {
        alertCard: AlertCard,
        DropdownActionButton,
        Icon,
        Loading,
    },
    emits: ['updateScheduleStatus', 'updateState'],
    props: {
        alerts: Array as PropType<AlertaDTO[]>,
        overlay: { 
            type: Boolean,
            default: false,
        },
        proctoringStatus: String
    },

    setup(props, { emit }) {
        const router = useRouter();
        const route = useRoute();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive<State>({
            alerts_array: [],
            order_array: [],
            checkedStatus: "NONE",
            checked: false,
            filter: "",
            loading: false
        });

        const checkAllAlerts = () => {
            state.checkedStatus = "ALL";
        };

        const handleSelectFilter = (value: string) => {
            toogleCheckAll(true);
            state.filter = value;
        };

        const handleSelectOrder = async (value: string) => {
            toogleCheckAll(true);
            let array = state.order_array;
            let tempFullArray: IAlert[] = [];
            array?.forEach(item => item.alertStatus === value ? tempFullArray.push({ ...item, checked: false }) : null);
            array?.forEach(item => item.alertStatus !== value ? tempFullArray.push({ ...item, checked: false }) : null );
            
            // console.log(tempFullArray);
            state.alerts_array = fetchAlerts(tempFullArray);
        };

        // const handleAudit = (
        //     situacao: situacaoData,
        //     alerta: number
        // ) => {
        //     const auditoria: AuditoriaDTO[] = [{
        //         alertId: alerta,
        //         alertStatus: situacao,
        //     }];

        //     const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), auditoria);
        //     request
        //         .then((resp) => {
        //             emit("updateScheduleStatus", resp);
        //         });
        // };

        const handleAudit = (
            audit: "REVIEWING" | "RELEASED" | "BLOCKED" | "SYSTEM_FAILURE",
            alertId = null
        ) => {
            state.loading = true;
            // console.log(alertId);
            const alerts = alertId !== null
                ? state.alerts_array.flat(1).filter((alert) => alert.id === alertId)
                : state.alerts_array.flat(1).filter((alert) => alert.checked);
            
            const data =
                alerts.map((alert) => {
                    return { alertId: alert.id, alertStatus: audit };
                }) || [];

            emit("updateState", data);

            const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), data);
            request
                .then((resp) => {
                    alerts.forEach((alert) => {
                        alert.alertStatus = audit;
                    });
                })
                .catch((error) => console.log(error))
                .finally(() => state.loading = false);
        };

        const toogleCheck = (id: number) => {
            state.alerts_array.forEach((alerts) => {
                alerts.forEach((alert) => {
                    if (alert.id === id) {
                        alert.checked = !alert.checked;
                        if (alerts.findIndex(x => x.id === alert.id) === 0) {
                            for (let i = 1; i < alerts.length; i++) {
                                alerts[i].checked = alert.checked;
                            }
                        }
                    }
                });
            });
        };

        const toogleCheckAll = (forceUncheck?: boolean) => {
            state.alerts_array.forEach((alerts) => {
                alerts.forEach((alert) => {
                    if (forceUncheck) {
                        alert.checked = false;
                    } else {
                        alert.checked = state.checked;
                    }
                });
            });
        };

        watch(
            () => state.checked,
            () => toogleCheckAll()
        );

        const fetchAlerts = (alerts: IAlert[]): IAlert[][] => {
            const alerts_array: IAlert[][] = [];
            let temp_array;
            for (let i = 0; i < alerts.length; ) {
                temp_array = [];
                for (let x = i; ; x++) {
                    if (alerts[x] && alerts[x].category === alerts[i].category) {
                        temp_array.push(alerts[x]);
                    } else {
                        i = x;
                        break;
                    }
                }
                alerts_array.push(temp_array);
            }
            return alerts_array;
        };

        watch(
            () => props.alerts,
            () => {
                if (props.alerts) {
                    const alerts = props.alerts.map((alert) => {
                        return { ...alert, checked: false };
                    });
                    state.alerts_array = fetchAlerts(alerts);
                    state.order_array = alerts;
                }
            }
        );

        onMounted(() => {
            if (props.alerts) {
                const alerts = props.alerts.map((alert) => {
                    return { ...alert, checked: false };
                });
                state.alerts_array = fetchAlerts(alerts);
            }
        });

        return {
            FILTER_OPTIONS,
            VALIDATE_ACTIONS_OPTIONS,
            ORDER_OPTIONS,
            checkAllAlerts,
            handleAudit,
            handleSelectFilter,
            handleSelectOrder,
            state,
            toogleCheck,
            defineColorByClient
        };
    },
});

export default AlertList;
</script>

<style>
.filters {
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 6px 6px 0px 0px;
}

.overflow {
  height: 520px;
  overflow-y: scroll;
}

.check-all input{
  all: unset;
  border: 1px solid black;
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 3px;
}
.check-all input:checked{
    background: url('../../assets/icons/check.svg') no-repeat center;
    background-color: #04986D;
}

.alert-text {
    font-size: 9pt;
    line-height: 12pt;
}
@media screen and (min-width: 1400px) {
    .alert-text {
        font-size: 11pt;
    }
}
</style>