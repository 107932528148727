<template>
    <div class="w-full flex flex-col rounded-md shadow-md my-4 cursor-pointer" @click="$emit('examCardAction')">
        <div 
            class="w-full px-5 py-4 flex align-center justify-between rounded-t-md bg-gray-100"
        >
            <div class="flex flex-row">
                <!-- <Icon color="green" icon="edit" size="16px" @click="$emit('examCardEditAction')" class="cursor-pointer"/> -->
                <p class="text-black ml-3 font-semibold">
                    {{ isCpf(userCpf) ? maskCpf(userCpf) : userCpf }}
                </p>
            </div>
            <p>{{ proctoringId }}</p>
            <div v-if="alertsCount > 0">
                <Icon icon="bell" class="mr-2 float-left" :color="defineColorByClient"/>
                <span class="mr-1 alerts-text"> {{ alertsCount }}</span>
            </div>
                           
            <!-- <p class="text-black text-xs font-medium">
                {{ status }}  
            </p> -->
        </div>
        <div class="w-full px-5 py-2 mt-3 flex justify-between rounded-t-md">
            <p class="text-sm text-black">Data do exame: <span class="font-medium">{{ state.formattedStartDate }}</span></p>
            <p class="text-sm text-black">Horário: <span class="font-medium">{{ state.formattedStartTime }}</span></p>
        </div>
        <div class="w-full px-5 py-2 mb-3 flex justify-between rounded-t-md">
            <p class="text-sm text-black">
                <!-- {{ state.text }}: 
                <span class="font-medium" v-if="examsAnswers">{{ examsAnswers }}</span>
                <span class="font-medium" v-else-if="candidatesNumber">{{ candidatesNumber }}</span>
                <span class="font-medium" v-else-if="!candidatesNumber">0</span> -->
            </p>
            <button class="flex items-center cursor-pointer" @click="$emit('examCardAction')">
                <p class="font-medium mr-2" :style="{ color: `${defineColorByClient}` }">
                    Conferir exame 
                </p>
                <Icon :color="defineColorByClient" icon="arrow_right" size="14px" />
            </button>
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { format } from "date-fns";
import { maskCpf, isCpf } from "@/utils/stringFunctions";
import vm from "@/viewModels/MainViewModel";

const ExamCard = defineComponent({
    components: { Icon },
    props: {
        examId: {
            type: String,
            required: true,
        },
        proctoringId: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: true,
        },
        userCpf: {
            type: String,
            required: true,
        },
        alertsCount: {
            type: Number,
            required: false,
        },
    },
    setup(props) {
        const state = reactive({
            button: 'Conferir exame',
            text: 'Candidatos cadastrados',
            formattedStartDate: '',
            formattedStartTime: '',
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const handleGenerateData = () => {
            var date = new Date(props.startDate);
            state.formattedStartDate = format(new Date(props.startDate), "dd/MM/yyyy");
            state.formattedStartTime = format(new Date(props.startDate), "HH:mm");
        };

        onMounted(() => handleGenerateData());

        return { state, maskCpf, isCpf, defineColorByClient };
    },
});

export default ExamCard;
</script>

<style>
</style>