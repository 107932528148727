<template>
    <div class="w-2/4 flex flex-col rounded-md shadow-md my-4 cursor-pointer mx-auto" @click="$emit('examCardAction')">
        <div 
            class="w-full px-5 py-4 flex align-center justify-between rounded-t-md bg-gray-100"
        >
            <div class="flex flex-row">
                <Icon color="black" :icon="proctoringType === 'VIDEO' ? 'video-solid' : 'camera' " size="16px" />
                <p class="text-black ml-3 font-semibold">
                    {{ id }}
                </p>
            </div>
            <p class="text-black text-xs font-medium">
                {{ status }}  
            </p>
        </div>
        <div class="w-full px-5 py-2 mt-3 flex justify-between rounded-t-md">
            <p class="text-sm text-black">Data do exame: <span class="font-medium">{{ state.formattedStartDate }}</span></p>
            <p class="text-sm text-black">Horário: <span class="font-medium">{{ state.formattedStartTime }}</span></p>
        </div>
        <div class="w-full px-5 py-2 mb-3 flex justify-between rounded-t-md">
            <p class="text-sm text-black">
                Id do exame: <span class="font-medium">{{ examId }}</span>
            </p>
            <p class="text-sm text-black">
                CPF: <span class="font-medium">{{ maskCpf(userCpf) }}</span>
            </p>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { format } from "date-fns";
import { maskCpf } from "@/utils/stringFunctions";

const ReportCard = defineComponent({
    components: { Icon },
    props: {
        examId: {
            type: String,
            required: true,
        },
        examStartTime: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        proctoringType: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: true,
        },
        userCpf: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const state = reactive({
            formattedStartDate: '',
            formattedEndDate: '',
            formattedStartTime: '',
            formattedEndTime: '',
        });

        const handleGenerateData = () => {
            var date = new Date(props.examStartTime);
            state.formattedStartDate = format(date, "dd/MM/yyyy");
            state.formattedStartTime = format(date, "HH:mm");
        };

        onMounted(() => handleGenerateData());

        return { state, maskCpf };
    },
});

export default ReportCard;
</script>

<style>
</style>