
import { defineComponent, onMounted, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { format } from "date-fns";
import { maskCpf } from "@/utils/stringFunctions";

const ReportCard = defineComponent({
    components: { Icon },
    props: {
        examId: {
            type: String,
            required: true,
        },
        examStartTime: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        proctoringType: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            required: true,
        },
        userCpf: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const state = reactive({
            formattedStartDate: '',
            formattedEndDate: '',
            formattedStartTime: '',
            formattedEndTime: '',
        });

        const handleGenerateData = () => {
            var date = new Date(props.examStartTime);
            state.formattedStartDate = format(date, "dd/MM/yyyy");
            state.formattedStartTime = format(date, "HH:mm");
        };

        onMounted(() => handleGenerateData());

        return { state, maskCpf };
    },
});

export default ReportCard;
