<template>
    <div>
        <!-- Return Button -->
        <div class="mt-20 flex items-center">
            <div class="text-gray-1 font-medium cursor-pointer ml-4 flex items-center"  @click="goBack" >
                <Icon icon="caret-left" class="mr-2 float-left" color="#898989"/>
                <span>Voltar</span>
            </div>
            <div class="flex justify-between w-full mr-8">
                <div class="flex justify-end h-8 ml-28">
                    <button 
                        v-if="state.proctoringType === 'IMAGE'"
                        class="rounded-md border-2 font-medium text-sm px-2 py-1 mx-1"
                        :class="{
                            ' text-white px-4': activeBtn === 'IMAGE', 
                            'bg-white': activeBtn !== 'IMAGE'
                        }"
                        :style="activeBtn === 'IMAGE' ? { backgroundColor: defineColorByClient, borderColor: defineColorByClient } : { color: defineColorByClient, borderColor: defineColorByClient }"
                        @click="activeBtn = 'IMAGE'"
                    >Imagem</button>
                    
                    <button 
                        v-if="state.proctoringType === 'IMAGE'"
                        class="rounded-md border-2 font-medium text-sm px-2 py-1 mx-1"
                        :class="{
                            ' text-white px-4': activeBtn === 'AUDIO', 
                            'bg-white': activeBtn !== 'AUDIO'
                        }"
                        :style="activeBtn === 'AUDIO' ? { backgroundColor: defineColorByClient, borderColor: defineColorByClient } : { color: defineColorByClient, borderColor: defineColorByClient }"
                        @click="activeBtn = 'AUDIO'"
                    >Áudio</button>
    
                    <button
                        v-if="state.proctoringType !== 'IMAGE'"
                        class="rounded-md border-2 font-medium text-sm px-2 py-1 mx-1"
                        :class="{
                            'text-white px-4': activeBtn === 'VIDEO', 
                            'bg-white': activeBtn !== 'VIDEO'
                        }"
                        :style="activeBtn === 'VIDEO' ? { backgroundColor: defineColorByClient, borderColor: defineColorByClient } : { color: defineColorByClient, borderColor: defineColorByClient }"
                        @click="activeBtn = 'VIDEO'"
                    >Camera</button>
    
                    <button
                        v-if="state.videoScreenURL && state.videoScreenURL != ''" 
                        class="rounded-md border-2 font-medium text-sm px-2 py-1 mx-1"
                        :class="{
                            'text-white px-4': activeBtn === 'SCREEN', 
                            'bg-white': activeBtn !== 'SCREEN'
                        }"
                        :style="activeBtn === 'SCREEN' ? { backgroundColor: defineColorByClient, borderColor: defineColorByClient } : { color: defineColorByClient, borderColor: defineColorByClient }"
                        @click="activeBtn = 'SCREEN'"
                    >Tela</button>
    
                    <button 
                        class="rounded-md border-2 font-medium text-sm bg-white px-2 py-1 mx-1"
                        style="width: 100px;"
                        :style="{ color: defineColorByClient, borderColor: defineColorByClient }"
                        @click="redirect()"
                    >Relatório</button>
                </div>

                <div v-if="state.proctoringType === 'REALTIME'">
                    <button 
                        class="p-2 px-3 rounded text-sm bg-egreen text-white" 
                        @click="handleReprocessing"
                    >Reprocessar</button>
                </div>
            </div>
        </div>
        <div v-if="!state.errorPage">
            <!-- Header -->
            <div class="flex flex-auto border-b border-egray-4 p-4 mx-8 items-center" :class="state.loading && 'animate-pulse'">
                <Icon 
                    icon="circle-chevron-left-solid" 
                    class="pr-4 mr-2 float-left cursor-pointer" 
                    :class="!state.previousExam && 'opacity-50 cursor-not-allowed'"
                    :color="defineColorByClient" 
                    size="30px"
                    @click="state.previousExam && previousOrNextCandidate(state.previousExam, true)"
                />
                <div>
                    <avatar :name="state.userName" color="egreen" size="md" :src="state.faceImageURL"></avatar>
                </div>
                <div class="flex flex-auto ml-8">
                    <div class="student-info mx-6">
                        <span class="font-regular text-gray-2">CPF</span>
                        <span class="font-medium text-gray-1">{{ state.userCpf }}</span>
                    </div>
                    <div class="student-info mx-6">
                        <span class="font-regular text-gray-2">Status</span>
                        <span class="font-medium text-gray-1">{{ formatStatus(state.status) }}</span>
                    </div>
                    <div class="student-info mx-6">
                        <span class="font-regular text-gray-2">Tipo</span>
                        <span class="font-medium text-gray-1">{{ state.proctoringType }}</span>
                    </div>
                    <!-- <div class="student-info mx-6">
                        <span class="font-regular text-gray-2">Data da prova</span>
                        <span class="font-medium text-gray-1">{{ formattedDate(state.examStartTime) }}</span>
                    </div> -->
                    <div class="student-info mx-6 w-150" v-if="state.proctoringType === 'IMAGE'">
                        <div class="flex cursor-pointer" @click="state.showLegend = !state.showLegend">
                            <span class="font-regular text-gray-2">Legenda</span>
                            <div class="transition transform duration-300 origin-center ml-2" :class="!state.showLegend ? 'rotate-180' : ''">
                                <Icon 
                                    icon="arrow-up" 
                                    class="pr-4 float-left"
                                    color="#363738" 
                                    size="20px"
                                />
                            </div>
                        </div>
                        <div class="flex flex-wrap gap-1 w-120" v-show="state.showLegend">
                            <p class="bg-gray-5 p-1 rounded text-sm text-ewhite w-28 text-center">Não auditado</p>
                            <p class="bg-error p-1 rounded text-sm text-ewhite w-28 text-center">Indício de fraude</p>
                            <p class="bg-eyellow p-1 rounded text-sm text-ewhite w-28 text-center">Alerta inválido</p>
                            <p class="bg-success p-1 rounded text-sm text-ewhite w-28 text-center">Alerta liberado</p>
                            <p class="bg-blue-400 p-1 rounded text-sm text-ewhite w-28 text-center">Aviso</p>
                        </div>
                    </div>
                    <div class="student-info mx-6">
                        <span class="font-regular text-gray-2" v-if="activeBtn === 'IMAGE'">Filtros</span>
                        <div class="flex gap-2" v-if="activeBtn === 'IMAGE'">
                            <button 
                                class="p-1 rounded text-sm" 
                                :class="state.filter === 'alerts' ? 'bg-egreen text-white' : 'bg-gray-300 text-gray-700'"
                                @click="handleSelectFilter('alerts')"
                            >Apenas com alertas</button>
                            <button 
                                class="p-1 rounded text-sm" 
                                :class="state.filter === 'all' ? 'bg-egreen text-white' : 'bg-gray-300 text-gray-700'"
                                @click="handleSelectFilter('all')"
                            >Todos</button>
                        </div>
                        <span class="font-regular text-gray-2 mt-2" v-if="activeBtn === 'AUDIO' || activeBtn === 'IMAGE'">Opções</span>
                        <div class="flex gap-2" v-if="activeBtn === 'AUDIO' || activeBtn === 'IMAGE'">
                            <button 
                                class="p-2 px-3 rounded text-sm bg-egreen text-white" 
                                @click="handleAuditImage('page')"
                            >Liberar alertas da página</button>
                            <button 
                                class="p-2 px-3 rounded text-sm bg-egreen text-white" 
                                @click="handleAuditImage('all')"
                            >Liberar todos os alertas</button>
                        </div>
                    </div>
                </div>
                <Icon 
                    icon="circle-chevron-right-solid" 
                    class="pl-4 ml-2 float-right cursor-pointer" 
                    :class="!state.nextExam && 'opacity-50 cursor-not-allowed'"
                    :color="defineColorByClient" 
                    size="30px"
                    @click="state.nextExam && previousOrNextCandidate(state.nextExam)"
                />
            </div>
            <!-- Content -->
            <!-- Image -->
                    
            <div class="mt-2 ml-10 h-2" v-if="state.proctoringType === 'REALTIME' && state.status == 'STARTED'" >
                Atualizado em {{formattedDate(state.lastDate)}} ({{state.realtimeSeconds}}s)
            </div>

            <transition name="fade" v-if="activeBtn === 'IMAGE'" >
                <div :class="state.loading && 'animate-pulse'">
                    <div class="mt-5">
                        <div 
                            v-for="(array, index) in state.showedAlertsPage" 
                            :key="index"
                            class="grid grid-cols-4-el gap-2 items-center justify-center"
                        >
                            <div v-for="(item, index2) in array" :key="index2">
                                <div v-if="index === state.page-1">
                                    <!-- :frameId="item.alerta.id" 
                                    :id="item.alerta.proctoringResultFrameId"
                                    :sessionId="item.alerta.id"
                                    :index="item.alerta.index"
                                    :category="item.alerta.category" 
                                    :alertStatus="item.alerta.alertStatus"  -->
                                    <FrameCard 
                                        :alerta="item.alerta" 
                                        :image="item.url"
                                        @audit="auditarImagem"
                                        @openImage="() => openImage(item)"
                                    />
                                </div>
                            </div>
                        </div>
    
                        <div v-if="state.showedAlertsPage[0].length === 0" class="flex flex-col">
                            <p class="flex justify-center text-gray-400 my-2">Nenhum alerta encontrado!</p>
                            <p class="flex justify-center text-gray-400" v-if="state.filter !== 'all'">Verifique o filtro todos!</p>
                        </div>
    
                        <!-- Paginação -->
                        <div class="flex justify-center px-32 py-3 flex-shrink-0" v-if="!(state.showedAlertsPage[0].length === 0)">
                            <button
                                class="flex mx-4 btn"
                                @click="state.page--"
                                :disabled="state.page == 1"
                            >
                                <Icon icon="arrow_frames" color="#696969" class="w-4 transform" />
                            </button>
                            <span><span class="font-bold">{{ state.page }}</span> de {{ state.filter === 'all' ? state.totalPages : state.totalPagesAlerts }}</span>
                            <button
                                class="flex mx-4 btn"
                                @click="state.page++"
                                :disabled="state.page === (state.filter === 'all' ? state.totalPages : state.totalPagesAlerts)"
                            >
                                <Icon icon="arrow_frames" color="#696969" class="w-4 transform rotate-180" />
                            </button>
                        </div>
                    </div>                    
                </div> 
            </transition>
            
            <!-- Audio -->
            <transition name="fade" v-else-if="activeBtn === 'AUDIO'" >
                <div :class="state.loading && 'animate-pulse'">
                    <div class="mt-5">
                        <audio controls class="mx-auto mb-4" id="auditAudio">
                            <source :src="state.audioCameraURL" type="audio/mpeg">
                            Your browser does not support the audio tag.
                        </audio>        

                        <div class="relative">
                            <div 
                                v-for="(array, index) in state.audioAlertsPage" 
                                v-show="index === state.page-1"
                                :key="index"
                                class="grid grid-cols-4-el gap-4 items-center justify-center 2xl:grid-cols-6-el"
                            >
                                <div v-for="(item, index2) in array" :key="index2">
                                    <AudioCard 
                                        v-if="index === state.page-1"
                                        :index="item.index"
                                        :id="item.id"
                                        :alertStatus="item.alertStatus"
                                        :description="item.description"
                                        :start="item.start"
                                        :end="item.edn" 
                                        @audit="auditar"
                                    />
                                </div>
                            </div>
                        </div>

                        <div v-if="state.audioAlertsPage[0].length === 0" class="flex flex-col">
                            <p class="flex justify-center text-gray-400 my-2">Nenhum alerta encontrado!</p>
                        </div>

                        <!-- Paginação -->
                        <div class="flex justify-center px-32 py-3 flex-shrink-0" v-if="!(state.audioAlertsPage[0].length === 0)">
                            <button
                                class="flex mx-4 btn"
                                @click="state.page--"
                                :disabled="state.page == 1"
                            >
                                <Icon icon="arrow_frames" color="#696969" class="w-4 transform" />
                            </button>
                            <span><span class="font-bold">{{ state.page }}</span> de {{ state.totalPages }}</span>
                            <button
                                class="flex mx-4 btn"
                                @click="state.page++"
                                :disabled="state.page === state.totalPages"
                            >
                                <Icon icon="arrow_frames" color="#696969" class="w-4 transform rotate-180" />
                            </button>
                        </div>
                    </div>                    
                </div> 
            </transition>

            <transition name="fade" v-else>
                <!-- Camera and Screen -->
                <div class="pt-2 audit-main-content px-4 item-start">
                    <div class="audit-video px-5">
                        <transition name="fade">
                            <div v-if="activeBtn === 'VIDEO'">
                                <VideoPlayer :src="state.videoCameraURL" :alerts="alertasFiltrados" :id="state.id" />
                            </div>
                        </transition>
                        <transition name="fade">
                            <div v-if="activeBtn === 'SCREEN'">
                                <VideoPlayer :src="state.videoScreenURL" :alerts="alertasFiltrados" :id="state.id" />
                            </div>
                        </transition>
                    </div>
                    <div class="right-bar" v-if="activeBtn !== 'AUDIO' && activeBtn !== 'IMAGE'">
                        <!-- <div class="flex align-center my-2" v-if="alertasFiltrados">
                            <Icon icon="bell" class="mr-4 float-left" :color="defineColorByClient" v-show="activeBtn !== 'response'" />
                            <span class="mr-3 alerts-text" v-show="activeBtn !== 'response'">Quantidade total de alertas: </span>
                            <span
                                class="
                                        px-2
                                        text-white text-s
                                        font-medium
                                        rounded
                                        alerts-text
                                    "
                                :style="{ backgroundColor: defineColorByClient }"
                            >{{ state.alerts.length }}</span>
                        </div> -->
    
                        <AlertList
                            class="max-h-2/5"
                            v-show="activeBtn === 'VIDEO' || activeBtn === 'SCREEN'" 
                            :alerts="alertasFiltrados"
                            :proctoringStatus="state.status"
                            @updateScheduleStatus="updateScheduleStatus"
                        />
                    </div>
                </div>                    
            </transition> 
        </div>
        <div v-else>
            <div class="pt-14 audit-main-content px-4 mt-14 items-baseline">
                <div class="w-90% absolute">
                    <div class="w-full h-100% flex flex-col justify-center items-center">
                        <img src="@/assets/img/server-down.svg" alt="" class="mt-20 w-50% h-50% object-cover" />
                        <h2 class="mt-20 text-xl text-gray-500">Auditoria do exame selecionado não encontrada!</h2>
                        <h2 class="mt-3 text-xl text-gray-500">Tente novamente mais tarde</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Transition name="fade">
        <div class="fixed left-0 top-0 w-full h-screen z-40 flex items-center justify-center bg-black bg-opacity-60" v-if="state.imageCardModal" @click="state.imageCardModal = false">
            <div class="rounded flex flex-col items-center justify-center w-3/6 p-6 bg-gray-200">
                <img class="rounded-md" :src="state.imageModal" />
                <div class="mx-2 my-4 grow text-center">
                    <span class="text-gray-1 font-bold">
                        {{ tradutorAlerta(state.categoryModal) }}
                    </span>
                </div>
                <div class="flex flex-wrap mx-2 gap-2">
                    <span 
                        title="Não auditado"
                        class="bg-gray-5 rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                        :class="state.alertStatusModal === 'REVIEWING' && 'border-blue-500 border-3'" 
                        @click="auditarImagem('REVIEWING', state.idModal, state.frameIdModal)"
                    >
                        <Icon icon="timer" color="#FEFEFE" size="18px" />
                    </span>
                    <span 
                        title="Indício de fraude"
                        class="bg-error rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                        :class="state.alertStatusModal === 'BLOCKED' && 'border-3 border-blue-500'" 
                        @click="auditarImagem('BLOCKED', state.idModal, state.frameIdModal)"
                    >
                        <div class="rounded-full border text-white p-1">
                            <Icon icon="error" color="#FEFEFE" size="8px" />
                        </div>
                    </span>
                    <span 
                        title="Alerta inválido"
                        class="bg-eyellow rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                        :class="state.alertStatusModal === 'SYSTEM_FAILURE' && 'border-3 border-blue-500'" 
                        @click="auditarImagem('SYSTEM_FAILURE', state.idModal, state.frameIdModal)"    
                    >
                        <Icon icon="alert" color="#FEFEFE" size="18px" />
                    </span>
                    <span 
                        title="Alerta liberado"
                        class="bg-success rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                        :class="state.alertStatusModal === 'RELEASED' && 'border-3 border-blue-500'" 
                        @click="auditarImagem('RELEASED', state.idModal, state.frameIdModal)"
                    >
                        <Icon icon="check-circle" color="#FEFEFE" size="18px" />
                    </span>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Icon from "@/components/Icon.vue";
import BaseAvatarGeneric from "@/components/BaseAvatarGeneric.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import AlertList from "@/components/audit/AlertList.vue";
import Respostas from "@/views/admin/exames/Respostas.vue";
import { alertCategories } from "@/dtos/AlertaDTO";
import AlertListDTO from "@/dtos/AlertListDTO";
import ImageAlertListDTO, { ImageAlertMatrixDTO } from "@/dtos/ImageAlertListDTO";
import Loading from "@/components/Loading.vue";
import ExameService from "@/services/ExameService";
import AlertDTO from "@/dtos/AlertDTO";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import AuditoriaDTO from "@/dtos/AuditoriaDTO";
import AuditoriaImagemDTO from "@/dtos/AuditoriaImagemDTO";
import RecordService from "@/services/RecordService";
import FrameCard from "@/components/auditFrame/FrameCard.vue";
import AudioCard from "@/components/auditFrame/AudioCard.vue";
import ProctoringResponseDTO, { sessionObject } from "@/dtos/ProctoringResponseDTO";
import DropdownActionButton from "@/components/DropdownActionButton.vue";
import vm from "@/viewModels/MainViewModel";
import { tradutorAlerta } from "@/utils/stringFunctions";

interface ExamesState {
    loading: boolean;
    faceImageURL: string;
    clientId: string;
    userCpf: string;
    userName: string;
    examId: string;
    examStartTime: string;
    examEndTime: string;
    proctoringType: string;
    status: "Started" | "Finished" | "Processing" | "Auditing" | "Audited" | null;
    alerts: AlertListDTO[];
    imageAlerts: ImageAlertListDTO[];
    audioCameraURL: string;
    videoCameraURL: string;
    videoScreenURL: string;
    hashStatus: boolean;
    videoAudit: boolean;
    audioAudit: boolean;
    frames: any[];
    id: string;
    key: number;
    creationDate: string;
    lastUpdateDate: string;
    deletedFlag: boolean;
    idAgendamentoSelecionado: string;
    selectedDate: Date;
    lastDate: Date;
    realtimeSeconds: number;
    sessions: sessionObject[];

    totalPages: number;
    totalPagesAlerts: number;
    page: number;
    pageSessions: number;
    itemsPage: number;
    alertsPage: ImageAlertMatrixDTO[][];
    onlyAlertsPage: ImageAlertMatrixDTO[][];
    showedAlertsPage: ImageAlertMatrixDTO[][];
    imgAlertsQtd: number;

    audioAlertsPage: any;

    filter?: string;

    currentExam: string,
    nextExam: string,
    previousExam: string,

    imageCardModal: boolean,
    imageModal: string,
    categoryModal: alertCategories,
    alertStatusModal: string,
    idModal: number,
    frameIdModal: number,
    candidates: ProctoringResponseDTO[],
    candidatePage: number,
    currentExamIndex: number,
    showLegend: boolean,
}
const AuditPage = defineComponent({
    components: {
        Icon,
        avatar: BaseAvatarGeneric,
        AlertList,
        VideoPlayer,
        FrameCard,
        AudioCard,
    },
    setup() {
        const router = useRouter();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive<ExamesState>({
            loading: true,
            faceImageURL: "",
            alerts: [],
            imageAlerts: [],
            audioAudit: false,
            clientId: '',
            creationDate: '',
            deletedFlag: false,
            examEndTime: '',
            examId: '',
            examStartTime: '',
            proctoringType: '',
            hashStatus: false,
            id: '',
            key: 0,
            lastUpdateDate: '',
            status: null,
            userCpf: '',
            userName: '',
            videoAudit: false,
            audioCameraURL: '',
            videoCameraURL: '',
            videoScreenURL: '',
            idAgendamentoSelecionado: "",
            selectedDate: new Date(),
            lastDate: new Date(),
            realtimeSeconds: 30,
            totalPages: 1,
            totalPagesAlerts: 1,
            page: 1,
            pageSessions: 1,
            itemsPage: 8,
            alertsPage: [],
            onlyAlertsPage: [],
            showedAlertsPage: [],
            sessions: [],
            frames: [],
            imgAlertsQtd: 0,

            audioAlertsPage: [],

            filter: "alerts",

            currentExam: "",
            nextExam: "",
            previousExam: "",

            imageCardModal: false,
            imageModal: "",
            categoryModal: "",
            alertStatusModal: "",
            idModal: 0,
            frameIdModal: 0,
            candidates: [],
            candidatePage: 1,
            currentExamIndex: -1,
            showLegend: true
        });

        const handleSelectFilter = (value: string) => {
            state.filter = value;
            state.page = 1;
        };
        
        watch(() => state.filter, () => {
            state.filter == 'alerts' ? state.showedAlertsPage = state.onlyAlertsPage : state.showedAlertsPage = state.alertsPage;
        });

        const activeBtn = ref<string>(state.proctoringType.toUpperCase());

        watch(() => state.proctoringType, (newValue) => {
            if(!newValue) {
                activeBtn.value = newValue;
            }
        });

        watch(() => activeBtn.value, () => {
            resize();
            state.page = 1;
            activeBtn.value === "AUDIO" && paginationAudioAlerts();
            // activeBtn.value === "IMAGE" && paginationAudioAlerts();
            activeBtn.value === "IMAGE" && paginationImageAlerts();
        });

        const formatStatus = (status: string) => {
            switch (status) {
                case "Active":
                    return "Agendado";
                case "Executed":
                    return "Executado";
                case "Canceled":
                    return "Cancelado";
                case "Running":
                    return "Em execução";
                case "AUDITING":
                    return "Aguardando auditoria";
                case "PROCESSING":
                    return "Em processamento";
                case "AUDITED":
                    return "Auditado";
                case "STARTED":
                    return "Iniciado";
                case "FINISHED":
                    return "Executado";
                default:
                    return "-";
            }
        };

        const formattedDate = (value: any) => {
            return format(new Date(value), "dd/MM/yyyy HH:mm:ss", { locale: ptBR });
        };

        const alertasFiltrados = computed(() => {
            const alerts: Array<AlertListDTO> = [];
            for (let i = 0; i < state.alerts.length; i++) {
                if (state.alerts[i].type.toUpperCase() === activeBtn.value) {
                    alerts.push(state.alerts[i]);
                }
                if (activeBtn.value === 'VIDEO') {
                    if (state.alerts[i].type.toUpperCase() === 'AUDIO') {
                        alerts.push(state.alerts[i]);
                    }
                }
            }

            const finalAlerts = JSON.parse(JSON.stringify(alerts));

            return finalAlerts;
        });

        const paginationImageAlerts = () => {
            state.totalPages = Math.ceil(state.frames.length / state.itemsPage) || 1;
            let contAuditavel = 0;
            let aux: any[] = [];
            state.alertsPage = [];
            state.onlyAlertsPage = [];
            let cont = 0;
            
            // Create pages and add items
            for (let i = 0; i < state.totalPages; i++) {
                state.alertsPage.push([]);
                state.onlyAlertsPage.push([]);

                // Add items on page
                for (let c = 0; c < state.itemsPage; c++) {
                    if (state.frames[cont]) {
                        const alerta = { ...state.alerts.find((item) => item.proctoringResultFrameId === state.frames[cont].id) };

                        state.alertsPage[i].push({ ...state.frames[cont], index: cont, alerta: alerta });
                        (JSON.stringify(alerta) !== '{}') && aux.push({ ...state.frames[cont], index: cont, alerta: alerta });
                        (JSON.stringify(alerta) !== '{}') && contAuditavel++;

                        cont++;
                    }
                }                
            }
            
            // Create pages and add items from only alerts filter
            let contAlerts = 0;
            state.totalPagesAlerts = Math.ceil(aux.length / state.itemsPage) || 1;
            for (let i = 0; i < state.totalPagesAlerts; i++) {
                state.onlyAlertsPage.push([]);
                for (let c = 0; c < state.itemsPage; c++) {
                    if (aux[contAlerts]) {
                        state.onlyAlertsPage[i].push({ ...aux[contAlerts], index: contAlerts });
                        contAlerts++;
                    }
                }
            }

            state.imgAlertsQtd = contAuditavel;

            state.filter == 'alerts' ? state.showedAlertsPage = state.onlyAlertsPage : state.showedAlertsPage = state.alertsPage;
        };
        
        const paginationAudioAlerts = () => {
            state.totalPages = Math.ceil(alertasFiltrados.value.length / state.itemsPage) || 1;
            let contAuditavel = 0;
            let aux = [];
            state.alertsPage = [];
            let cont = 0;
            for (let i = 0; i < state.totalPages; i++) {
                state.alertsPage.push([]);
                for (let c = 0; c < state.itemsPage; c++) {
                    if (alertasFiltrados.value[cont]) {
                        state.alertsPage[i].push({ ...alertasFiltrados.value[cont], index: cont });
                        if (alertasFiltrados.value[cont]?.length){
                            contAuditavel += alertasFiltrados.value[cont]?.length;
                            aux.push({ ...alertasFiltrados.value[cont], index: cont });
                        }
                        cont++;
                    }
                }
            }
            state.imgAlertsQtd = contAuditavel;
                        
            state.audioAlertsPage = state.alertsPage;
        };

        const carregarExame = () => {
            state.loading = true;

            const alertas: Array<AlertDTO> = [];

            if(router.currentRoute.value.params.id.toString()){
                state.loading = true;
                const [request] = ExameService.GetExame(state.currentExam || router.currentRoute.value.params.id.toString());
                request
                    .then((resp) => {
                        state.alerts = resp.alerts;
                        state.frames = resp.frames;
                        state.faceImageURL = resp.faceImageURL;
                        state.imageAlerts = resp.imageAlerts;
                        state.audioAudit = resp.audioAudit;
                        state.clientId = resp.clientId;
                        state.creationDate = resp.creationDate;
                        state.deletedFlag = resp.deletedFlag;
                        state.examEndTime = resp.examEndTime;
                        state.examId = resp.examId;
                        state.proctoringType = resp.proctoringType;
                        activeBtn.value = activeBtn.value || (resp.proctoringType.toUpperCase() === 'REALTIME' && 'VIDEO') || resp.proctoringType.toUpperCase() || "SCREEN";
                        state.examStartTime = resp.examStartTime;
                        state.hashStatus = resp.hashStatus;
                        state.id = resp.id;
                        state.lastUpdateDate = resp.lastUpdateDate;
                        state.status = resp.status;
                        state.userCpf = resp.userCpf;
                        state.videoAudit = resp.videoAudit;
                        state.audioCameraURL = resp.audioCameraURL;
                        state.videoCameraURL = resp.videoCameraURL;
                        state.videoScreenURL = resp.videoScreenURL;
                    })
                    .then(() => {
                        state.lastDate = new Date();
                        paginationImageAlerts();
                        paginationAudioAlerts();
                        // state.sessions = resp.sessions;

                        candidatesConfig();
                    })
                    .then(() => {
                        paginationImageAlerts();
                    })
                    .catch((error) => {
                        activeBtn.value = state.proctoringType.toUpperCase() || "SCREEN";
                    })
                    .finally(() => (state.loading = false));
                
                return alertas;
            }
        };

        const loadCandidates = (page: number, save: boolean = true) => {
            const [request] = ExameService.GetExams({
                page: page
            });

            return request
                .then((resp) => {
                    save && (state.candidates = resp.data);
                    return resp.data;
                })
                .catch((error) => console.log(error));
        };

        const candidatesConfig = async () => {
            // Encontre o exame atual
            let candidatePageSearch = 1;
            state.candidates && (state.currentExamIndex = state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString()));
            
            while(state.currentExamIndex == -1) {
                await loadCandidates(candidatePageSearch, true);
                state.currentExamIndex = state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString());

                if(state.currentExamIndex == -1) {
                    candidatePageSearch++;
                } else {
                    state.candidatePage = candidatePageSearch;
                    candidatePageSearch = 0;
                }
            }
            
            const respLength = state.candidates.length;
            
            if(state.currentExamIndex >= 0) {
                if(state.currentExamIndex === 0 && state.candidatePage !== 1) {
                    const previousList = await loadCandidates(state.candidatePage - 1, false);
                    previousList && (state.previousExam = previousList[previousList.length-1].id);
                } else {
                    state.previousExam = state.currentExamIndex > 0 ? state.candidates[(state.currentExamIndex - 1)].id : "";
                }
    
                if(state.currentExamIndex === 9) {
                    const newPage = await loadCandidates(state.candidatePage+1, false);
                    newPage && (state.nextExam = newPage[0].id);
                } else {
                    state.nextExam = state.candidates[(state.currentExamIndex + 1) % respLength].id;
                }
            }

        };

        const previousOrNextCandidate = (id: string, clickPrevious: boolean = false) => {
            router.push({ name: 'exame', params: { id: id } });

            if(state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString()) === 0 && state.candidatePage !== 1 && clickPrevious) state.candidatePage--;
            if(state.candidates.findIndex((item) => state.currentExam ? item.id == state.currentExam : item.id == router.currentRoute.value.params.id.toString()) === state.candidates.length-1) state.candidatePage++;
            
            state.currentExam = id;
            carregarExame();
        };

        const updateScheduleStatus = async () => {
            if(router.currentRoute.value.params.id.toString()){
                state.loading = true;
                const [request] = ExameService.GetExame(router.currentRoute.value.params.id.toString());
                await request
                    .then((resp) => {
                        state.alerts = resp.alerts;
                        state.imageAlerts = resp.imageAlerts;
                    });
            }
        };

        onMounted(() => {
            resize();
            carregarExame();
            setInterval(() => {
                // console.log(state.status);
                if (
                    state.proctoringType === 'REALTIME' && 
                    (
                        state.status?.toUpperCase() === 'PROCESSING' || 
                        state.status?.toUpperCase() === 'FINISHED' || 
                        state.status?.toUpperCase() === 'STARTED'
                    )
                ) {
                    carregarExame();
                    state.realtimeSeconds = 30;
                }
            }, 30000);

            setInterval(() => {
                if (
                    state.proctoringType === 'REALTIME' && 
                    (
                        state.status?.toUpperCase() === 'PROCESSING' || 
                        state.status?.toUpperCase() === 'FINISHED' || 
                        state.status?.toUpperCase() === 'STARTED'
                    )
                ) {
                    state.realtimeSeconds = state.realtimeSeconds-1;
                }
            }, 1000);
        });
        
        // window.addEventListener("resize", () => {
        //     window.innerHeight >= 800 ? state.itemsPage = 12 : state.itemsPage = 8;
        //     // paginationImageAlerts();
        // });

        window.addEventListener("resize", () => {
            resize();
            paginationImageAlerts();
            paginationAudioAlerts();
        });

        const resize = () => {
            if(activeBtn.value === "AUDIO") {
                window.innerHeight >= 800 ? state.itemsPage = 18 : state.itemsPage = 8;
            }
            if(activeBtn.value === "IMAGE") {
                window.innerHeight >= 800 ? state.itemsPage = 12 : state.itemsPage = 8;
            }
        };

        const auditar = (
            situacao: "REVIEWING" | "RELEASED" | "BLOCKED" | "SYSTEM_FAILURE",
            alerta?: number,
            type?: "page" | "all",
        ) => {
            let auditoria: AuditoriaDTO[] = [];
            
            if(type) {
                let alerts = alertasFiltrados.value;
                
                if(type === "page") {
                    alerts = state.audioAlertsPage[state.page-1];
                }
            
                if(alerts) {
                    (alerts as any[]).map((alert: any) => {
                        auditoria.push({ alertId: alert.id, alertStatus: situacao });
                    }) || [];
                }
            } else if (alerta != undefined) {
                auditoria = [{
                    alertId: alerta,
                    alertStatus: situacao,
                }];
            }

            const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), auditoria);
            request
                .then(() => {
                    if(type) {
                        alertasFiltrados.value.map((item: any) => {
                            const matchingAlert = auditoria.find(audited => item.id === audited.alertId);
                            if (matchingAlert) {
                                item.alertStatus = situacao;
                            }
                            return item;
                        });
                    } else {
                        alertasFiltrados.value.forEach((item: { id: number; alertStatus: string; }) => item.id === auditoria[0].alertId && (item.alertStatus = auditoria[0].alertStatus));
                    }

                    paginationAudioAlerts();
                });
        };

        const handleAuditImage = (type: "page" | "all") => {
            activeBtn.value === "IMAGE" && auditarImagem("RELEASED", undefined, undefined, type);
            activeBtn.value === "AUDIO" && auditar("RELEASED", undefined, type);
            carregarExame();
        };

        const auditarImagem = async (
            situacao: "REVIEWING" | "RELEASED" | "BLOCKED" | "SYSTEM_FAILURE",
            alerta?: number,
            frameId?: number,
            type?: "page" | "all",
        ) => {
            let auditoria: AuditoriaImagemDTO[] = [];

            if(type) {
                let alerts: ImageAlertMatrixDTO[] | ImageAlertMatrixDTO[][] | AlertListDTO[] = state.alerts;
                
                if(type === "page") {
                    alerts = state.showedAlertsPage[state.page-1];
                } else if (type === "all") {
                    alerts = state.alerts;
                }
            
                if(alerts) {
                    (alerts as any[]).map((item: any) => {
                        type === "page" && auditoria.push({ alertId: item.alerta.id, alertStatus: situacao, frameId: item.alerta.proctoringResultFrameId });
                        type === "all" && auditoria.push({ alertId: item.id, alertStatus: situacao, frameId: item.proctoringResultFrameId });
                    }) || [];
                }

            } else if (alerta != undefined && frameId != undefined) {
                auditoria = [{
                    alertId: alerta,
                    alertStatus: situacao,
                    frameId: frameId,
                }];
            }
            
            const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), auditoria);
            await request
                .then(() => {
                    if(type) {
                        state.alerts.map(alert => {
                            const matchingAlert = auditoria.find(item => alert.proctoringResultFrameId === item.frameId && alert.id === item.alertId);
                            if (matchingAlert) {
                                alert.alertStatus = situacao;
                            }
                            return alert;
                        });
                        
                    } else {
                        state.alerts.map(frame => {
                            frame.proctoringResultFrameId === frameId && 
                            ((frame.id === alerta) && (frame.alertStatus = situacao));
                        });
                    }
                });
            paginationImageAlerts();
        };

        /** Redirect */
        const redirect = () => {
            const url = router.resolve({
                path: `/exam-report/${router.currentRoute.value.params.id.toString()}`,
            });
            window.open(url.href, "_blank");
        };

        /** Reprocessing */
        const handleReprocessing = () => {
            const [request] = ExameService.GetReprocessing(router.currentRoute.value.params.id.toString());

            return request
                .then((resp) => {
                    carregarExame();
                })
                .catch((error) => console.log(error));
        };

        const openImage = (item: ImageAlertMatrixDTO) => {
            state.imageCardModal = true;
            state.categoryModal = item.alerta.category,
            state.idModal = item.alerta.id,
            state.frameIdModal = item.id,
            state.imageModal = item.url;
            state.alertStatusModal = item.alerta.alertStatus;
        };

        const goBack = () => {
            window.history.go(-1);
            setTimeout(() => {
                window.location.reload();
            }, 500);
        };

        return {
            state,
            redirect,
            formatStatus,
            updateScheduleStatus,
            alertasFiltrados,
            activeBtn,
            formattedDate,
            auditar,
            auditarImagem,
            handleSelectFilter,
            defineColorByClient,
            previousOrNextCandidate,
            openImage,
            handleAuditImage,
            tradutorAlerta,
            goBack,
            handleReprocessing,
        };
    },
});

export default AuditPage;
</script>

<style>
.btn:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.left-bar {
  grid-area: "left-bar";
  display: flex;
  justify-content: center;
  align-items: center;
}

.audit-video {
  grid-area: "video";
  display: flex;
  flex-direction: column;
}

.right-bar {
  grid-area: "right-bar";
}

.audit-main-content {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  grid-template-areas: "video right-bar";
}

.student-info {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.alerts-text {
  font-size: 11pt;
  /* line-height: 12pt; */
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>