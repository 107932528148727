<template>
    <div class="w-full h-full flex flex-col items-center pt-16 mt-4 mb-8">
        <!-- Select Information -->
        <div class="w-full flex items-center justify-center">
            <button 
                :class="state.currentPage === 'reports' ? 
                    'border border-primary bg-primary text-white rounded-l-3xl py-2 w-28' : 
                    'border border-primary bg-transparent text-black rounded-l-3xl py-2 w-28'"
                @click="state.currentPage = 'reports'"
            >Relatórios</button>
            <button 
                :class="state.currentPage === 'alerts' ? 
                    'border border-primary bg-primary text-white rounded-r-3xl py-2 w-28' : 
                    'border border-primary bg-transparent text-black rounded-r-3xl py-2 w-28'"
                @click="state.currentPage = 'alerts'"
            >Alertas</button>
        </div>

        <!-- Filters -->
        <div>
            <!-- Search -->
            <SearchInput 
                placeholder="Digite o clientId"
                style="margin-bottom: 0px !important;"
                class="mx-auto mt-6"
            />
            <!-- Status -->
            <!-- <SuperStatus class="mt-6" /> -->
            <!-- Calendar -->
            <div class="mx-auto z-0 mt-3 w-80 flex items-center">
                <CalendarComponent
                    v-if="state.calendarOpened"
                    @closeCalendar="state.calendarOpened = false"
                />
                <div
                    class="w-full h-full overflow-hidden flex flex-col items-stretch"
                    v-else
                >
                    <div
                        class="relative h-18 w-full bg-white border-b border-neutral-1 pl-7 pr-3 flex items-center z-30 flex-shrink-0"
                    >
                        <img src="@/assets/icons/calendar.svg" />
                        <div class="flex-1 pl-7">
                            <p class="text-sm leading-5">Exames realizados</p>
                            <div
                                class="flex items-center cursor-pointer"
                                @click="state.calendarOpened = true"
                            >
                                <p class="font-bold text-lg leading-5">{{ formattedDateTemp }}</p>
                                <img
                                    class="transform transition-transform duration-200 w-3 mx-2"
                                    :class="{ 'rotate-180': state.calendarOpened }"
                                    src="@/assets/icons/arrow-down.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Show cards with data -->
        <!-- <div v-for="report in state.reports" :key="report.id" v-show="state.currentPage === 'reports'" >
            <ReportCard 
                :examId="report.examId"
                :examStartTime="report.examStartTime"
                :id="report.id"
                :proctoringType="report.proctoringType"
                :status="report.status"
                :userCpf="report.userCpf"
            />
        </div> -->
        <p v-if="!state.proctoringClientId" class="mt-12 text-gray-700">Digite um identificador de cliente válido para buscar os dados</p>
        <highcharts :options="chartOptions"  class="mt-8" v-else />
        <button 
            v-if="state.proctoringClientId"
            class="border border-primary bg-primary text-white rounded p-4 m-4"
            @click="download"
        >Baixar CSV</button>
    </div>
</template>

<script lang='ts'>
// Modules
import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { format } from "date-fns";
import { formatISO } from 'date-fns/fp';
import { ptBR } from "date-fns/locale";
import { Chart } from 'highcharts-vue';
import { titleCase } from '@/utils/stringFunctions';
import RecordService from '@/services/RecordService';
import AlertRankDTO from '@/dtos/AlertRankDTO';
import ReportsDTO from '@/dtos/ReportsDTO';

// Components
import CalendarComponent from '@/components/CalendarComponent.vue';
import SearchInput from '@/components/SearchInput.vue';
import SuperStatus from '@/views/super/SuperStatus.vue';
import ReportCard from '@/components/ReportCard.vue';


interface DashboardState {
    currentPage: "reports" | "alerts";
    calendarOpened: boolean;
    selectedDate: Date;
    proctoringClientId: string;
    startDate: string,
    finishDate: string,
    reports: ReportsDTO[] | undefined,
}

interface chartDTO {
    chart?: {
        type: string
    };
    title: {
        text: string,
    },
    xAxis: {
        categories: Array<string>,
        title: {
            text: null,
        },
    },
    yAxis: {
        min: 0,
        title: {
            text: string,
            align: string,
        },
        labels: {
            overflow: string,
        },
    },
    tooltip: {
        valueSuffix: string,
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: boolean,
            },
        },
    },
    credits: {
        enabled: boolean,
    },
    series: Array<{ name: string, data: number[] }>,
}

const SuperDashboard = defineComponent({    
    components: { CalendarComponent, SearchInput, SuperStatus, ReportCard, highcharts: Chart  },
    setup() {
        const route = useRoute();
        
        const state = reactive<DashboardState>({
            currentPage: "reports",
            calendarOpened: false,
            selectedDate: new Date(),
            proctoringClientId: "",
            startDate: "",
            finishDate: "",
            reports: undefined
        });
        
        const chartOptions = reactive<chartDTO>({
            chart: {
                type: 'bar',
            },
            title: {
                text: 'Quantidade de Alertas',
            },
            xAxis: {
                // categories: ['Release', 'Blocked', 'Review', 'Failure', 'Total'],
                categories: [],
                title: {
                    text: null,
                },
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Alertas',
                    align: 'high',
                },
                labels: {
                    overflow: 'justify',
                },
            },
            tooltip: {
                valueSuffix: ' ',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            credits: {
                enabled: false,
            },
            series: [],
        });

        const formattedDate = computed(() => {
            return format(state.selectedDate, "dd / MMMM / yyyy", { locale: ptBR });
        });

        const formattedDateTemp = computed(() => {
            let startDate = route.query["startDate"] ? new Date(route.query["startDate"].toString()) : new Date();
            let finishDate = route.query["endDate"] ? new Date(route.query["endDate"].toString()) : new Date();

            return `${titleCase(format(startDate, "E', 'dd LLL", { locale: ptBR }))} - ${titleCase(format(finishDate, "E', 'dd LLL", { locale: ptBR }))}`;
        });

        let data = {};

        const loadData = async () => {
            state.proctoringClientId = route.query["search"] ? route.query["search"].toString() : "";
            state.startDate = route.query["startDate"] ? route.query["startDate"].toString() : formatISO(new Date());
            state.startDate = state.startDate.substring(0, state.startDate.indexOf("T"));
            state.finishDate = route.query["endDate"] ? route.query["endDate"].toString() : formatISO(new Date());
            state.finishDate = state.finishDate.substring(0, state.finishDate.indexOf("T"));

            if(state.proctoringClientId) {
                if(state.currentPage === "reports") {
                    const [request] = RecordService.Report({
                        proctoringClientId: state.proctoringClientId,
                        startDate: state.startDate,
                        finishDate: state.finishDate,
                        list: true
                    });
                    request
                        .then((resp: ReportsDTO[]|undefined) => {
                            state.reports = resp;

                            // STARTED       FINISHING      PROICESSING        AUDITING      AUDITED         ERROR
                            let categories: string[] = [];
        
                            let started: number = 0;
                            let finishing: number = 0;
                            let processing: number = 0;
                            let auditing: number = 0;
                            let audited: number = 0;
                            let error: number = 0;

                            resp?.map((item: ReportsDTO) => {
                                switch(item.status) {
                                    case "STARTED":
                                        started++;
                                        break;
                                    case "FINISHING":
                                        finishing++;
                                        break;
                                    case "PROICESSING":
                                        processing++;
                                        break;
                                    case "AUDITING":
                                        auditing++;
                                        break;
                                    case "AUDITED":
                                        audited++;
                                        break;
                                    case "ERROR":
                                        error++;
                                        break;   
                                }
                            });
        
                            chartOptions.xAxis.categories = ["Started", "Finishing", "Processing", "Auditing", "Audited", "Error"];
                            chartOptions.series = [
                                { name: 'Quantidade', data: [started, finishing, processing, auditing, audited, error] },
                            ];
                            chartOptions.title.text = "Relatórios Gerados";
                        });
                } else {
                    const [request] = RecordService.AlertsRank({
                        proctoringClientId: state.proctoringClientId,
                        startDate: state.startDate,
                        finishDate: state.finishDate,
                        list: true
                    });
                    await request
                        .then((resp: AlertRankDTO[]) => {
                            console.log("Alerts Rank response:", resp);

                            let categories: string[] = [];
        
                            let release: number[] = [];
                            let blocked: number[] = [];
                            let review: number[] = [];
                            let failure: number[] = [];
                            let total: number[] = [];
        
                            resp.map((item: AlertRankDTO) => {
                                categories.push(item.category);
        
                                release.push(item.release);
                                blocked.push(item.blocked);
                                review.push(item.review);
                                failure.push(item.failure);
                                total.push(item.total);
                            });
        
                            chartOptions.xAxis.categories = categories;
                            chartOptions.series = [
                                { name: 'Release', data: release },
                                { name: 'Blocked', data: blocked },
                                { name: 'Review', data: review },
                                { name: 'Failure', data: failure },
                                { name: 'Total', data: total }
                            ];
                            chartOptions.title.text = "Quantidade de Alertas";
                        });
                }
            }

        };

        const download = async () => {
            const [request] = RecordService.Report({
                proctoringClientId: state.proctoringClientId,
                startDate: state.startDate,
                finishDate: state.finishDate,
                list: false
            });
            request
                .then((resp: any) => {
                    const blob = new Blob([resp], { type: 'text/csv' });
  
                    const url = window.URL.createObjectURL(blob);

                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${state.proctoringClientId} ${state.startDate} - ${state.finishDate}`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
        };

        watch(() => route.params, loadData, { deep: true });
        watch(() => state.currentPage, loadData, { deep: true });

        onMounted(() => {
            window.scrollTo(0,0);
            loadData();
        });
       
        return { state, download, formattedDate, chartOptions, data, formattedDateTemp };
    }
});

export default SuperDashboard;
</script>

<style>

</style>