<template>
    <div class="h-2px w-full bg-primary bg-opacity-30 relative range mt-14 mb-5">
        <input min="0" step="1" :max="options.length - 1" class="vs-range__range" type="range" v-model="firstInputValue"/>
        <input min="0" step="1" :max="options.length - 1" class="vs-range__range" type="range" v-model="secondInputValue"/>

        <!-- Balão -->
        <div class="flex flex-col items-center w-0 absolute -top-13" :style="{'left': `${stepSize * minIndexValue}%`}">
            <div class="bg-primary text-white font-bold px-3 py-1 rounded-md z-10">
                {{ options[minIndexValue] }}
            </div>
            <div class="w-5 h-5 bg-primary transform rotate-45  rounded-br-md -mt-3 z-0"></div>
        </div>

        <!-- Balão -->
        <div class="flex flex-col items-center w-0 absolute -top-13" :style="{'right':  `${stepSize * (options.length - 1 - maxIndexValue)}%`   }">
            <div class="bg-primary text-white font-bold px-3 py-1 rounded-md z-10">
                {{ options[maxIndexValue] }}
            </div>
            <div class="w-5 h-5 bg-primary transform rotate-45  rounded-br-md -mt-3 z-0"></div>
        </div>


        <!-- Barra -->
        <div class="h-px absolute bg-primary" :style="[
            {'left': `${stepSize * minIndexValue}%`},
            {'right': `${stepSize * (options.length - 1 - maxIndexValue)}%`}
        ]"></div>

        <!-- Ticks -->
        <div class="w-px flex flex-col items-center text-center absolute top-0" v-for="n in options.length" :key="n" :style="{left: `${(100 / (options.length -1)) * (n - 1)}%`}">
            <div class="w-px h-2 bg-primary bg-opacity-30 mb-1"></div>
            <p class="text-sm">{{ options[n - 1]}}</p>
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, ref } from 'vue';

const Range = defineComponent({
    props: {
        options: {
            type: Array,
            required: true
        },
        minIndex: {
            type: Number,
            required: true
        },
        maxIndex: {
            type: Number,
            required: true
        }
    },
    setup(props, context){


        const firstInputValue = ref(props.minIndex);
        const secondInputValue = ref(props.maxIndex);

        const minIndexValue = computed(() => {
            const value = Math.min(firstInputValue.value, secondInputValue.value);
            context.emit('update:minIndex', value);
            return value;
        });
        const maxIndexValue = computed(() => {
            const value = Math.max(firstInputValue.value, secondInputValue.value);
            context.emit('update:maxIndex', value);
            return value;
        });

        const stepSize = computed(() => (100 / (props.options.length - 1)));

        return { firstInputValue, secondInputValue, minIndexValue, maxIndexValue, stepSize };
    }
});

export default Range;
</script>

<style>
.range input {
    position: absolute;
    width: calc(100% + 20px);
    left: -10px;
    transform: translateY(-40%);
    pointer-events: none;
    background: transparent;
    /* opacity: 0; */
    -webkit-appearance: none;
    outline: none;
    z-index: 10;
}

.range input[type=range]::-webkit-slider-thumb {
     -webkit-appearance: none;
    pointer-events: all;
    height: 18px;
    width: 18px;
    border-radius: 9px;
    background:  var(--primary-color);
    cursor: pointer;
}
</style>