<template>
    <div class="h-full">
        <!-- Header -->
        <Header @logoClick="$router.replace({ name: 'admin' })">
            <Icon icon="settings" color="var(--primary-color)" size="24px" class="cursor-pointer" @click="handleConfig" />

            <p>{{ user && user.name }}</p>
            
            <button class="h-14 px-8" @click="() => logout()">
                <!-- <img src="@/assets/icons/logout.svg" class="w-4" /> -->
                <Icon icon="logout" :color="defineColorByClient" />
            </button>
        </Header>

        <RouterView #default="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component" />
            </transition>
        </RouterView>

        <ConfigModal ref="configModal" />
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, ref } from "vue";
import { useAlert } from "@/hooks/alert";
import vm from "@/viewModels/MainViewModel";
import Icon from "@/components/Icon.vue";

//  Components
import Header from "@/components/Header.vue";
import ConfigModal from "@/components/ConfigModal.vue";
import Modal from "@/components/Modal.vue";

import UserDTO from "@/dtos/UserDTO";
import { useRouter } from "vue-router";

const AdminMainPage = defineComponent({
    components: { Header, Icon, ConfigModal, Modal },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
        const alert = useAlert();
        const { replace } = useRouter();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        /** Logout */
        const logout = () => {
            alert({
                icon: "logout",
                title: "Logout",
                message: "Tem certeza que deseja sair do sistema?",
                actions: [
                    {
                        title: "Cancelar",
                        primary: true,
                    },
                    {
                        title: "Sair",
                        action: () => {
                            replace({ name: "login" });
                            vm.logout();
                        },
                    },
                ],
            });
        };

        const configModal = ref<typeof ConfigModal | null>(null);
        const handleConfig = () => {
            configModal.value!.toogleModal();
        };

        return { logout, handleConfig, user, defineColorByClient, configModal };
    },
});

export default AdminMainPage;
</script>

<style>
</style>