
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DropdownFilterButton from '@/components/DropdownFilterButton.vue';
import Icon from '@/components/Icon.vue';
import StatusFilterButton from '@/components/StatusFilterButton.vue';

const SuperStatus = defineComponent({
    components: { DropdownFilterButton, Icon, StatusFilterButton },
    setup(){
        const route = useRoute();
        const { replace } = useRouter();

        const state = reactive({
            filterBy: Number(route.query.status) || -1,
            selected: -1,
        });

        const setFilter = (status: number) => {
            state.filterBy = status;
            state.selected = status;
        };

        const filter = () => {
            const status = state.filterBy > -1 ? state.filterBy : null;
            replace({ query: { ...route.query, status } });
        };

        watch(() => state.filterBy, () => {
            filter();
        });

        onMounted(() => {
            replace({ query: { ...route.query, order: 1 } });
        });

        const dropdown = ref();
        const closeModal = () => {
            if (dropdown.value) dropdown.value.closeModal();
        };

        return { state, setFilter, filter, dropdown, closeModal };
    }
});

export default SuperStatus;
