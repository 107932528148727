<template>
    <div v-for="(alert, index) in alerts" :key="index" class="flex items-center">
        <div
            :class="
                'flex items-center justify-between my-2 border-b border-gray-4 py-4 mx-4 w-10/12' +
                    (index > 0 ? ' pl-3' : '')
            "
            v-if="
                (!filter || alert.alertStatus === filter || alert.type === filter) &&
                    (alerts.length === 1 ||
                        !state.collapsed ||
                        (state.collapsed && index === 0))
            "
        >
            <label class="inline-flex items-center check-all">
                <input
                    type="checkbox"
                    class="form-checkbox text-eblack h-4 w-4 rounded"
                    :style="alert.checked ? { backgroundColor: defineColorByClient } : { backgroundColor: '#ffffff' }"
                    :checked="alert.checked"
                    @click="$emit('check', alert.id)"
                />
            </label>
            <div class="w-2/5 mx-2 alert-text">
                <span
                    :class="
                        (overlay ? 'text-eblack' : 'text-gray-1') +
                            'text-gray-1'
                    "
                >
                    <span class="alert-text" v-if="alert.type === 'Video'" >Vídeo: </span>
                    <span class="alert-text" v-if="alert.type === 'Screen'" >Tela: </span>
                    <span class="alert-text" v-if=" alert.type === 'Audio'" >Áudio: Transcrição: </span>
                    <span v-if=" alert.type === 'Video'" class="alert-text text-eblack font-bold"> {{ tradutorAlerta(alert.category, alert.description) }} </span>
                    <span v-if=" alert.type === 'Screen'" class="alert-text text-eblack font-bold"> {{ tradutorAlerta(alert.category, alert.description) }} </span>
                    <span v-if="alert.type === 'Audio'" class="alert-text text-eblack font-bold">{{ alert.description }}</span>
      
                </span>
            </div>
            <div class="flex items-center alert-text">
                <p class="mr-1">{{ millisToMinutesAndSeconds(alert.start) }}</p>
                <Icon
                    icon="play"
                    :color="overlay ? '#333333' : defineColorByClient"
                    size="22px"
                    @click="playButton(alert.start)"
                />
            </div>
            <div class="inline-flex items-center justify-center mx-2 w-15">
                <DropdownActionButton
                    classes="disabled:cursor-not-allowed alert-text text-ewhite font-medium py-2 px-3 rounded border-r border-ewhite btn-validation text-sm flex items-center justify-between"
                    :style="{ 'background-color': getColor(alert.alertStatus), 'border-radius': '0.25rem', 'opacity': alert.alertStatus == 'WARNING' || proctoringStatus != 'AUDITING' ? 0.6: 1}"
                    :options="VALIDATE_ACTIONS_OPTIONS"
                    :alertId="alert.id"
                    :disabled="alert.alertStatus == 'WARNING' || proctoringStatus != 'AUDITING' ? true: false"
                    @selected="handleAudit"
                >
                    <span class="font-normal">{{text(alert.alertStatus)}}</span>
                    <Icon icon="caret-right" color="#FEFEFE" size="18px" />
                </DropdownActionButton>
            </div>
        </div>
        <div class="w-1" v-if="(!filter || alert.alertStatus === filter) && alerts.length > 1 && index === 0">
            <Icon
                icon="caret-down"
                :color="'#333333'"
                size="25px"
                @click="handleCollapse"
                v-if="state.collapsed"
            />
            <Icon
                icon="caret-up"
                :color="'#333333'"
                size="25px"
                @click="handleCollapse"
                v-else
            />
        </div>
        <div class="w-1" v-else>
            <span></span>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, computed, PropType, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { IAlert } from "@/dtos/AlertaDTO";
import DropdownActionButton from "../DropdownActionButton.vue";
import { VALIDATE_ACTIONS_OPTIONS } from "@/components/audit/AlertList.vue";
import vm from "@/viewModels/MainViewModel";
import { tradutorAlerta } from "@/utils/stringFunctions";

const AlertCard = defineComponent({
    components: {
        Icon,
        DropdownActionButton,
    },
    emits: ['check', 'audit'],
    props: {
        filter: {
            type: String,
            required: true,
        },
        overlay: Boolean,
        alerts: {
            type: Array as PropType<IAlert[]>,
            required: true,
        },
        proctoringStatus: String
    },
    setup(props, { emit }) {
        const state = reactive({
            collapsed: true,
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const playButton = (start: number) => {
            let videoElements = document.querySelectorAll('video');

            const time = start / 1000;
            if (!videoElements) return null;
            for (let i = 0; i < videoElements.length; i++) {
                videoElements[i].currentTime = time;
            }

            if (videoElements[0].paused) document.getElementById('playBtn')?.click();
        };

        const text = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'Não auditado';
                case "RELEASED":
                    return 'Alerta liberado';
                case "BLOCKED":
                    return 'Indício de fraude';
                case "SYSTEM_FAILURE":
                    return 'Alerta inválido';
                case "WARNING":
                    return 'Aviso';
            }
        };

        const getColor = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return '#C5C5C5';
                case "RELEASED":
                    return '#2CC89B';
                case "BLOCKED":
                    return '#F66F6F';
                case "SYSTEM_FAILURE":
                    return '#FBC55D';
                case "WARNING":
                    return '#60a5fa';
            }
        };

        const handleCollapse = () => {
            state.collapsed = !state.collapsed;
        };

        const handleAudit = (value: string, id: number) => {
            // if (props.alerts.length > 1) {
            //     console.log(props.alerts);
            //     props.alerts.forEach(item => item.checked === true ? emit('audit', value, null) : emit('audit', value, id));
            // } else {
            //     emit('audit', value, id);
            // }

            if (props.alerts.length > 1) {
                let count = 0;

                props.alerts.map(item => {
                    if (item.checked === true) {
                        count++;
                    }
                });

                if (count >= 1) {
                    emit('audit', value, null);
                } else {
                    emit('audit', value, id);
                }
                // props.alerts.forEach(item => item.checked === true ? emit('audit', value, null) : emit('audit', value, id));
            } else {
                emit('audit', value, id);
            }
        };

        function millisToMinutesAndSeconds(millis: number) {
            let minutes = Math.floor(millis / 60000);
            let seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
        }

        return {
            tradutorAlerta,
            playButton,
            handleCollapse,
            text,
            handleAudit,
            state,
            VALIDATE_ACTIONS_OPTIONS,
            getColor,
            millisToMinutesAndSeconds,
            defineColorByClient
        };
    },
});

export default AlertCard;
</script>

<style scoped>
.btn-validation {
  min-width: 140px;
}

.alert-card:not(:first-child) {
  background-color: #523443;
}

.alert-text {
    font-size: 9pt;
    line-height: 12pt;
}

.check-all input{
  all: unset;
  border: 1px solid black;
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 3px;
}
.check-all input:checked{
    background: url('../../assets/icons/check.svg') no-repeat center;
  background-color: #04986D;
}

@media screen and (min-width: 1400px) {
    .alert-text {
        font-size: 11pt;
    }
}
</style>