<template>
    <!-- <div
        class="w-full mx-auto max-w-3xl
        flex justify-between items-center"
    > -->
    <div
        class="w-full mx-auto max-w-5xl
        flex items-center"
    >
        <StatusFilterButton
            :active="state.selected === -1"
            @click="() => {
                setFilter(-1)
            }"
            class="mx-1"
        >
            Todos
        </StatusFilterButton>
        <StatusFilterButton
            :active="state.selected === 1"
            @click="() => {
                setFilter(1)
            }"
            class="mx-1"
        >
            Executando
        </StatusFilterButton>
        <StatusFilterButton
            :active="state.selected === 3"
            @click="() => {
                setFilter(3)
            }"
            class="mx-1"
        >
            Processando
        </StatusFilterButton>
        <StatusFilterButton
            :active="state.selected === 4"
            @click="() => {
                setFilter(4)
            }"
            class="mx-1"
        >
            Em auditoria
        </StatusFilterButton>
        <StatusFilterButton
            :active="state.selected === 5"
            @click="() => {
                setFilter(5)
            }"
            class="mx-1"
        >
            Auditados
        </StatusFilterButton>
        <StatusFilterButton
            :active="state.selected === 6"
            @click="() => {
                setFilter(5, true)
            }"
            class="mx-1"
        >
            Fraudes
        </StatusFilterButton>
        <DropdownFilterButton v-if="state.selected !== 4" ref="dropdown" :options="[{label: 'Crescente', value: '2'}, {label: 'Decrescente', value: '1'}]" class="mx-1 dropdown">
            <Icon icon="sort" :color="defineColorByClient" class="mr-2 dropdown" />
            <p class="text-egreen dropdown" :style="{ color: `${defineColorByClient}` }">Recentes</p>
            <Icon icon="arrow_drop_down" :color="defineColorByClient" class="ml-2 dropdown" />
        </DropdownFilterButton>
        <DropdownFilterButton v-if="state.selected === 4" ref="dropdown" :options="[{label: 'Crescente', value: '2'}, {label: 'Decrescente', value: '1'}, {label: 'Alertas', value: '0'}]" class="mx-1 dropdown">
            <Icon icon="sort" :color="defineColorByClient" class="mr-2 dropdown" />
            <p class="text-egreen dropdown" :style="{ color: `${defineColorByClient}` }">Recentes</p>
            <Icon icon="arrow_drop_down" :color="defineColorByClient" class="ml-2 dropdown" />
        </DropdownFilterButton>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DropdownFilterButton from '@/components/DropdownFilterButton.vue';
import Icon from '@/components/Icon.vue';
import StatusFilterButton from '@/components/StatusFilterButton.vue';
import vm from "@/viewModels/MainViewModel";

const StatusFilter = defineComponent({
    components: { DropdownFilterButton, Icon, StatusFilterButton },
    setup(){
        const route = useRoute();
        const { replace } = useRouter();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive({
            filterBy: route.query.status || -1,
            selected: -1,
            fraud: false,
        });

        const setFilter = (status: number, fraud: boolean = false) => {
            state.filterBy = status;
            state.selected = fraud ? 6 : status;
            state.fraud = fraud;
        };

        const filter = () => {
            const status = state.filterBy > -1 ? state.filterBy : null;
            replace({ query: { 
                ...route.query, 
                ...(status !== 4 && route.query.order === '0' ? { order: 1 } : undefined),
                status, 
                fraud: state.fraud.toString()
            } });
        };

        watch(() => state.filterBy, () => {
            filter();
        });
        watch(() => state.fraud, () => {
            filter();
        });

        onMounted(() => {
            replace({ query: { ...route.query, order: 1 } });
        });

        const dropdown = ref();
        const closeModal = () => {
            if (dropdown.value) dropdown.value.closeModal();
        };

        return { state, setFilter, filter, dropdown, closeModal, defineColorByClient };
    }
});

export default StatusFilter;
</script>

<style>

</style>
