
import { computed, defineComponent, onMounted, PropType, reactive, watch } from "vue";

import { AlertaDTO, IAlert } from "@/dtos/AlertaDTO";
import { useRoute, useRouter } from "vue-router";
import AlertCard from "./AlertCard.vue";
import DropdownActionButton from "../DropdownActionButton.vue";
import Icon from "../Icon.vue";
import RecordService from "@/services/RecordService";
// import ProctoringService from "@/services/ProctoringService";
import Loading from "@/components/Loading.vue";
import AuditoriaDTO from "@/dtos/AuditoriaDTO";
import vm from "@/viewModels/MainViewModel";

interface State {
    alerts_array: IAlert[][];
    order_array: IAlert[];
    checkedStatus: string;
    checked: boolean;
    filter?: string;
    loading: boolean;
}

export const VALIDATE_ACTIONS_OPTIONS = [
    {
        label: "Não auditado",
        value: "REVIEWING",
    },
    {
        label: "Liberar Alerta",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Alerta Inválido",
        value: "SYSTEM_FAILURE",
    },
];

const FILTER_OPTIONS = [
    {
        label: "Liberados",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Não auditados",
        value: "REVIEWING",
    },
    {
        label: "Inválidos",
        value: "SYSTEM_FAILURE",
    },
    {
        label: "Vídeo",
        value: "Video",
    },
    {
        label: "Áudio",
        value: "Audio",
    },
    {
        label: "Todos",
        value: "",
    },
];

const ORDER_OPTIONS = [
    {
        label: "Liberados",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Não auditados",
        value: "REVIEWING",
    },
    {
        label: "Inválidos",
        value: "SYSTEM_FAILURE",
    },
    {
        label: "Todos",
        value: "",
    },
];

const AlertList = defineComponent({
    components: {
        alertCard: AlertCard,
        DropdownActionButton,
        Icon,
        Loading,
    },
    emits: ['updateScheduleStatus', 'updateState'],
    props: {
        alerts: Array as PropType<AlertaDTO[]>,
        overlay: { 
            type: Boolean,
            default: false,
        },
        proctoringStatus: String
    },

    setup(props, { emit }) {
        const router = useRouter();
        const route = useRoute();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive<State>({
            alerts_array: [],
            order_array: [],
            checkedStatus: "NONE",
            checked: false,
            filter: "",
            loading: false
        });

        const checkAllAlerts = () => {
            state.checkedStatus = "ALL";
        };

        const handleSelectFilter = (value: string) => {
            toogleCheckAll(true);
            state.filter = value;
        };

        const handleSelectOrder = async (value: string) => {
            toogleCheckAll(true);
            let array = state.order_array;
            let tempFullArray: IAlert[] = [];
            array?.forEach(item => item.alertStatus === value ? tempFullArray.push({ ...item, checked: false }) : null);
            array?.forEach(item => item.alertStatus !== value ? tempFullArray.push({ ...item, checked: false }) : null );
            
            // console.log(tempFullArray);
            state.alerts_array = fetchAlerts(tempFullArray);
        };

        // const handleAudit = (
        //     situacao: situacaoData,
        //     alerta: number
        // ) => {
        //     const auditoria: AuditoriaDTO[] = [{
        //         alertId: alerta,
        //         alertStatus: situacao,
        //     }];

        //     const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), auditoria);
        //     request
        //         .then((resp) => {
        //             emit("updateScheduleStatus", resp);
        //         });
        // };

        const handleAudit = (
            audit: "REVIEWING" | "RELEASED" | "BLOCKED" | "SYSTEM_FAILURE",
            alertId = null
        ) => {
            state.loading = true;
            // console.log(alertId);
            const alerts = alertId !== null
                ? state.alerts_array.flat(1).filter((alert) => alert.id === alertId)
                : state.alerts_array.flat(1).filter((alert) => alert.checked);
            
            const data =
                alerts.map((alert) => {
                    return { alertId: alert.id, alertStatus: audit };
                }) || [];

            emit("updateState", data);

            const [request] = RecordService.Audit(router.currentRoute.value.params.id.toString(), data);
            request
                .then((resp) => {
                    alerts.forEach((alert) => {
                        alert.alertStatus = audit;
                    });
                })
                .catch((error) => console.log(error))
                .finally(() => state.loading = false);
        };

        const toogleCheck = (id: number) => {
            state.alerts_array.forEach((alerts) => {
                alerts.forEach((alert) => {
                    if (alert.id === id) {
                        alert.checked = !alert.checked;
                        if (alerts.findIndex(x => x.id === alert.id) === 0) {
                            for (let i = 1; i < alerts.length; i++) {
                                alerts[i].checked = alert.checked;
                            }
                        }
                    }
                });
            });
        };

        const toogleCheckAll = (forceUncheck?: boolean) => {
            state.alerts_array.forEach((alerts) => {
                alerts.forEach((alert) => {
                    if (forceUncheck) {
                        alert.checked = false;
                    } else {
                        alert.checked = state.checked;
                    }
                });
            });
        };

        watch(
            () => state.checked,
            () => toogleCheckAll()
        );

        const fetchAlerts = (alerts: IAlert[]): IAlert[][] => {
            const alerts_array: IAlert[][] = [];
            let temp_array;
            for (let i = 0; i < alerts.length; ) {
                temp_array = [];
                for (let x = i; ; x++) {
                    if (alerts[x] && alerts[x].category === alerts[i].category) {
                        temp_array.push(alerts[x]);
                    } else {
                        i = x;
                        break;
                    }
                }
                alerts_array.push(temp_array);
            }
            return alerts_array;
        };

        watch(
            () => props.alerts,
            () => {
                if (props.alerts) {
                    const alerts = props.alerts.map((alert) => {
                        return { ...alert, checked: false };
                    });
                    state.alerts_array = fetchAlerts(alerts);
                    state.order_array = alerts;
                }
            }
        );

        onMounted(() => {
            if (props.alerts) {
                const alerts = props.alerts.map((alert) => {
                    return { ...alert, checked: false };
                });
                state.alerts_array = fetchAlerts(alerts);
            }
        });

        return {
            FILTER_OPTIONS,
            VALIDATE_ACTIONS_OPTIONS,
            ORDER_OPTIONS,
            checkAllAlerts,
            handleAudit,
            handleSelectFilter,
            handleSelectOrder,
            state,
            toogleCheck,
            defineColorByClient
        };
    },
});

export default AlertList;
