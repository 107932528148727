
import { defineComponent } from 'vue';

const HomeIllustration = defineComponent({
    props: {
        color: {
            type: String,
            default: "#04986D"
        },
    },
});

export default HomeIllustration;
