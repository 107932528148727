
import { computed, defineComponent, onMounted, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { format } from "date-fns";
import { maskCpf, isCpf } from "@/utils/stringFunctions";
import vm from "@/viewModels/MainViewModel";

const ExamCard = defineComponent({
    components: { Icon },
    props: {
        examId: {
            type: String,
            required: true,
        },
        proctoringId: {
            type: String,
            required: true,
        },
        startDate: {
            type: String,
            required: true,
        },
        userCpf: {
            type: String,
            required: true,
        },
        alertsCount: {
            type: Number,
            required: false,
        },
    },
    setup(props) {
        const state = reactive({
            button: 'Conferir exame',
            text: 'Candidatos cadastrados',
            formattedStartDate: '',
            formattedStartTime: '',
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const handleGenerateData = () => {
            var date = new Date(props.startDate);
            state.formattedStartDate = format(new Date(props.startDate), "dd/MM/yyyy");
            state.formattedStartTime = format(new Date(props.startDate), "HH:mm");
        };

        onMounted(() => handleGenerateData());

        return { state, maskCpf, isCpf, defineColorByClient };
    },
});

export default ExamCard;
