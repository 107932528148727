<template>
    <div class="player w-full mt-5">
        <div ref="myPlayer">
            <video v-if="!state.error" controls :src="src" :onerror="erroLoading" class="w-full rounded" :class="state.isFullScreen ? 'max-h-full' : ' max-h-60vh'" ref="auditVideo" id="auditVideo" :muted="state.muted" @click="play" autobuffer />
            
            <div v-else class="w-full rounded min-h-60vh bg-gray-800 flex justify-center items-center">
                <p class="text-white">{{ state.error }}</p>
            </div>
            
            <!-- <div class="controls py-1 px-4 justify-between mx-auto max-w-5xl">
                <Icon v-if="!state.playing" id="playBtn" icon="play2" color="#04986D" size="15px" @click="play" class="cursor-pointer"/>
                <Icon v-if="state.playing" id="playBtn" icon="pause" color="#04986D" size="15px" @click="play" class="cursor-pointer"/>
                <Icon icon="stop" color="#FEFEFE" size="15px" @click="stop" class="cursor-pointer"/>
                <Icon v-if="!state.muted" icon="volume" color="#FEFEFE" size="15px" @click="mute" class="cursor-pointer"/>
                <Icon v-if="state.muted" icon="volume-off" color="#FEFEFE" size="15px" @click="mute" class="cursor-pointer"/>

                <div class="h-2 w-9/12 video-progress rounded bg-white" ref="progress">
                    <div
                        class="h-2 bg-egreen rounded absolute"
                        :style="`width: ${state.percentage}%`"
                    ></div>
                </div>
                <Icon
                    icon="full_screen"
                    color="#FEFEFE"
                    size="15px"
                    class="cursor-pointer"
                    @click="fullScreen"
                    v-if="!state.isFullScreen"
                />
                <Icon
                    icon="small_screen"
                    color="#FEFEFE"
                    size="15px"
                    class="cursor-pointer"
                    @click="exitfullScreen"
                    v-else
                />
                <div class="timer cursor-default">
                    <div></div>
                    <span aria-label="timer" class="text-white text-xs">{{
                        `${currentDurationFormatted} / ${totalDurationFormatted}`
                    }}</span>
                </div>
            </div> -->
            <div class="alert-list-button-overlay" v-if="state.isFullScreen">
                <span
                    class="flex items-center bg-white"
                    @click="toogleAlertList"
                    v-if="state.alertListOpen"
                >  
                    <span class="ml-3">Fechar </span>
                    <Icon icon="close" color="#04986D" size="22px" class="mx-2"
                    /></span>
                <span class="flex items-center bg-white" @click="toogleAlertList" v-else
                >
                    <span class="ml-3">Lista de Alertas </span>
                    <Icon icon="bell" color="#04986D" size="22px" class="mx-2"
                    /></span>
            </div>
            <alertList
                v-if="state.isFullScreen"
                v-show="state.alertListOpen"
                :alerts="alerts"
                :id="id"
                :overlay="true"
                class="alert-list-overlay bg-gray-200 bg-opacity-70"
            />
        </div>
    </div>
</template>

<script lang='ts'>
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    computed,
    WritableComputedRef,
    PropType,
} from "vue";
import Icon from "@/components/Icon.vue";
import moment from "moment";
import AlertList from "./audit/AlertList.vue";
import { AlertaDTO } from "@/dtos/AlertaDTO";

const VideoPlayer = defineComponent({
    components: {
        Icon,
        alertList: AlertList,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        // options: {
        //   type: Object,
        //   default: {
        //     controls: false,
        //   },
        // },
        alerts: {
            type: Array as PropType<AlertaDTO[]>,
            required: true,
        },
        id: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const auditVideo = ref<HTMLMediaElement | null>(null);
        const myPlayer = ref<HTMLDivElement | null>(null);
        const progress = ref<HTMLDivElement | null>(null);

        const state = reactive({
            currentDuration: 0,
            totalDuration: 0,
            percentage: 0,
            isFullScreen: false,
            alertListOpen: true,
            playing: false,
            muted: false,
            id: '',
            error: '',
        });

        const idAlerts = computed(() => {
            const newId = props.id;
            return newId;
        });

        const currentDurationFormatted: WritableComputedRef<string> = computed(
            () => {
                return moment.utc(state.currentDuration * 1000).format("mm:ss");
            }
        );

        const totalDurationFormatted: WritableComputedRef<string> = computed(() => {
            return moment.utc(state.totalDuration * 1000).format("mm:ss");
        });

        const play = () => {
            state.playing ? auditVideo.value?.pause() : auditVideo.value?.play();
            state.playing = !state.playing;
        };
        const stop = () => {
            if (!auditVideo.value) return null;
            auditVideo.value.currentTime = 0;
            auditVideo.value.pause();
            state.playing = false;
        };
        const mute = () => {
            state.muted = !state.muted;
        };

        const fullScreen = () => {
            var targetelement = myPlayer.value;

            if (targetelement?.requestFullscreen) {
                targetelement.requestFullscreen();
            }
        };

        const exitfullScreen = () => {
            document.exitFullscreen();
        };

        const loadVideo = () => {
            if (auditVideo.value) {
                auditVideo.value.currentTime = 7 * 24 * 60 * 1000;
                auditVideo.value.onseeked = () => {
                    if (auditVideo.value) {
                        state.totalDuration = auditVideo.value?.duration || 0;
                        auditVideo.value.currentTime = 0;
                        auditVideo.value.onseeked = null;

            auditVideo.value?.addEventListener("timeupdate", (event) => {
                state.currentDuration = auditVideo.value?.currentTime || 0;
                state.percentage = Math.ceil(
                    (state.currentDuration / state.totalDuration) * 100
                );
            });
            progress.value?.addEventListener("click", (e) => {
                if (progress.value && auditVideo.value) {
                    const progressTime =
                  (e.offsetX / progress.value?.offsetWidth) *
                  state.totalDuration;
                    auditVideo.value.currentTime = progressTime;
                }
            });
                    }
                };
            }

            document.onfullscreenchange = () => {
                state.isFullScreen = !!document.fullscreenElement;
            };
        };

        const toogleAlertList = () => {
            state.alertListOpen = !state.alertListOpen;
        };

        const erroLoading = () => {
            state.error = 'Este vídeo não existe!';
        };

        onMounted(() => {
            loadVideo();
            stop();
        });

        // document.getElementById('auditVideo')?.addEventListener('error', function(event) { ... }, true);

        return {
            state,
            auditVideo,
            progress,
            currentDurationFormatted,
            totalDurationFormatted,
            myPlayer,
            play,
            stop,
            mute,
            fullScreen,
            toogleAlertList,
            exitfullScreen,
            erroLoading
        };
    },
});

export default VideoPlayer;
</script>

<style>
.controls {
  background: #282828;
  box-shadow: inset -5px -5px 250px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(42px);
  border-radius: 4px;
  position: relative;
  top: -45px;
  display: flex;
  align-items: center;
}

.video-progress {
  position: relative;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
}

.video-progress-filled {
  width: 0;
  background: orangered;
}

.alert-list-button-overlay {
  position: absolute;
  top: 100px;
  right: 25px;
}

.alert-list-overlay {
  position: absolute;
  top: 115px;
  right: 25px;
}
</style>