
import { defineComponent, onMounted, reactive, watch, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import StatusFilter from "./StatusFilter.vue";

import RecordDTO from "@/dtos/RecordDTO";
import ExamAnswerDTO from "@/dtos/ExamAnswerDTO";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";

import ExamCard from "@/components/ExamCard.vue";
import Banner from "@/components/Banner.vue";
import Loading from "@/components/Loading.vue";
import Icon from "@/components/Icon.vue";
import { format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import ExameService from "@/services/ExameService";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import vm from "@/viewModels/MainViewModel";
import { removeCpfMask } from "@/utils/stringFunctions";

enum ExamStatusEnum {
    CLOSED = "Exame executado",
    OPENED = "Exame agendado",
    RUNNING = "Exame em andamento",
}

interface ExamesState {
    loading: boolean;
    exames: ProctoringResponseDTO[];
    idExamesSelecionado?: number;
    currentExame: ProctoringResponseDTO | null;
    loadingDetail: boolean;
    examRecord: RecordDTO | null;
    examAnswer: ExamAnswerDTO | null;
    exam: ExamResponseDTO | null;
    selectedDate: Date;
    status?: number;
    onlyFraud?: boolean;
    order?: number;
    calendarOpened: boolean;
    filtersOpened: boolean;
    search?: string;
    endedPages: boolean;
    page: number;
    examesFinalizados: number;
    produtosPorPagina: number;
    produtosTotal: number;
    disableNext: boolean;
    pagination: any[];
}

const ExamesPage = defineComponent({
    components: {
        Banner,
        ExamCard,
        StatusFilter,
        Loading,
        Icon
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive<ExamesState>({
            loading: false,
            exames: [],
            currentExame: null,
            idExamesSelecionado: undefined,
            loadingDetail: false,
            examRecord: null,
            examAnswer: null,
            exam: null,
            selectedDate: new Date(),
            status: undefined,
            order: undefined,
            onlyFraud: undefined,
            calendarOpened: false,
            filtersOpened: false,
            search: undefined,
            endedPages: false,
            page: 1,
            examesFinalizados: 0,
            produtosPorPagina: 5,
            produtosTotal: 0,
            disableNext: false,
            pagination: [
                { name: "currentPage", value: 0 },
                { name: "next", value: 0 },
                { name: "last", value: 0 },
                { name: "first", value: 0 },
                { name: "itensCount", value: 0 },
                { name: "itemsPerPage", value: 0 },
            ],
        });

        const formattedDate = computed(() => {
            return format(state.selectedDate, "dd / MMMM / yyyy", { locale: ptBR });
        });

        const query = (page: number) => {
            return {
                ...route.query,
                _page: page
            };
            // router.push({ path: 'register', query: { plan: 'private' }})
        };

        const nextPage = (page: number) => {
            query(page);
            loadExams();
            window.scrollTo(0,0);
        };

        const global = reactive({
            title: '',
            date: new Date(),
        });

        const handleClick = (id: string, title: string, date: Date) => {
            global.title = title;
            global.date = date;
            router.push({ name: 'exame', params: { id: id } });
        };

        // provide('global', global);

        const loadExams = async () => {
            state.loading = true;

            state.status = route.query["status"] ? parseInt(route.query["status"].toString()) : undefined;
            state.onlyFraud = route.query["fraud"] ? route.query["fraud"]=='true' : undefined;
            state.order = route.query["order"] ? parseInt(route.query["order"].toString()) : undefined;
            state.search = route.query["search"] ? removeCpfMask(route.query["search"].toString()) : undefined;

            // Calendário
            state.selectedDate = route.query["date"] ? new Date(route.query["date"].toString()) : new Date();
            const date = route.query["date"] ? new Date(route.query["date"].toString()) : undefined;
            const startHour = parse((route.query["startHour"] as string) || "00h", "HH'h'", new Date()).getHours();
            const finishHour = parse((route.query["finishHour"] as string) || "23h", "HH'h'", new Date()).getHours();

            // Filtros
            const [request] = await ExameService.GetExams({
                status: state.status,
                order: state.order,
                cpf: state.search,
                date: date,
                startHour: startHour,
                finishHour: finishHour,
                pageSize: state.produtosPorPagina,
                page: state.page,
                onlyFraud: state.onlyFraud
            });
            request
                .then(async (resp: any) => {
                    state.exames = resp.data;

                    const Xpagination = resp.headers["x-pagination"];
                    const startPagination = Xpagination.indexOf("?");
                    const paginationCorrected = Xpagination.slice(startPagination+1, Xpagination.length);
                    const paginationArray = paginationCorrected.split("&");

                    await paginationArray.map((item: any) => {
                        const equalItem = item.indexOf("=");

                        state.pagination.find((obj) => obj.name == item.slice(0, equalItem))!.value = item.slice(equalItem+1, item.length);
                    });   
                })
                .catch((error: any) => console.log(error))
                .finally(() => {
                    if (state.page >= state.pagination.find((obj) => obj.name == "last")!.value) {
                        state.disableNext = true;
                    } else {
                        state.disableNext = false;
                    }
                    state.loading = false;
                });
        };


        watch(() => route.query,() => {
            state.page = 1;
            loadExams();
        }, { deep: true });

        onMounted(() => loadExams());

        const statusFilter = ref();
        const closeModal = (event: any) => {
            if (!event.path[0].className.includes('dropdown') && statusFilter.value) {
                statusFilter.value.closeModal();
            }
        };

        return {
            ExamStatusEnum,
            state,
            nextPage,
            handleClick,
            formattedDate,
            closeModal,
            statusFilter,
            defineColorByClient
        };
    },
});

export default ExamesPage;
