<template>
    <!-- <div
        class="w-full mx-auto max-w-3xl
        flex justify-between items-center"
    > -->
    <div
        class="w-full mx-auto max-w-3xl
        flex items-center"
    >
        <StatusFilterButton
            :active="state.selected === -1"
            @click="() => {
                setFilter(-1)
            }"
            class="mx-1"
        >
            Todas as categorias
        </StatusFilterButton>
        <StatusFilterButton
            :active="state.selected === 1"
            @click="() => {
                setFilter(1)
            }"
            class="mx-1"
        >
            Realizados
        </StatusFilterButton>
        <StatusFilterButton
            :active="state.selected === 0"
            @click="() => {
                setFilter(0)
            }"
            class="mx-1"
        >
            Agendados
        </StatusFilterButton>
        <!-- TO DO: See this index -->
        <StatusFilterButton
            :active="state.selected === 2"
            @click="() => {
                setFilter(2)
            }"
            class="mx-1"
        >
            Em execução
        </StatusFilterButton>
        <DropdownFilterButton ref="dropdown" :options="[{label: 'Crescente', value: '2'}, {label: 'Decrescente', value: '1'}]" class="mx-1 dropdown">
            <Icon icon="sort" color="#04986D" class="mr-2 dropdown" />
            <p class="text-egreen dropdown">Recentes</p>
            <Icon icon="arrow_drop_down" color="#04986D" class="ml-2 dropdown" />
        </DropdownFilterButton>
    </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DropdownFilterButton from '@/components/DropdownFilterButton.vue';
import Icon from '@/components/Icon.vue';
import StatusFilterButton from '@/components/StatusFilterButton.vue';

const SuperStatus = defineComponent({
    components: { DropdownFilterButton, Icon, StatusFilterButton },
    setup(){
        const route = useRoute();
        const { replace } = useRouter();

        const state = reactive({
            filterBy: Number(route.query.status) || -1,
            selected: -1,
        });

        const setFilter = (status: number) => {
            state.filterBy = status;
            state.selected = status;
        };

        const filter = () => {
            const status = state.filterBy > -1 ? state.filterBy : null;
            replace({ query: { ...route.query, status } });
        };

        watch(() => state.filterBy, () => {
            filter();
        });

        onMounted(() => {
            replace({ query: { ...route.query, order: 1 } });
        });

        const dropdown = ref();
        const closeModal = () => {
            if (dropdown.value) dropdown.value.closeModal();
        };

        return { state, setFilter, filter, dropdown, closeModal };
    }
});

export default SuperStatus;
</script>

<style>

</style>