
import { computed, defineComponent, reactive } from 'vue';
import FinishIllustration from '@/components/illustrations/FinishIllustration.vue';
import vm from "@/viewModels/MainViewModel";
import Icon from "@/components/Icon.vue";
import router from '@/router/index';

import RecordDTO from "@/dtos/RecordDTO";
import ExamAnswerDTO from "@/dtos/ExamAnswerDTO";
import SchedulingDTO from "@/dtos/SchedulingDTO";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";

interface ExamesState {
    loading: boolean;
    agendamentos: SchedulingDTO[];
    agendamentosFiltered: SchedulingDTO[];
    idAgendamentoSelecionado: string;
    currentAgendamento: SchedulingDTO | null;
    loadingDetail: boolean;
    examRecord: RecordDTO | null;
    examAnswer: ExamAnswerDTO | null;
    exam: ExamResponseDTO | null;
    selectedDate: Date;
    calendarOpened: boolean;
    filtersOpened: boolean;
    search: string;
}

const MenuPage = defineComponent({
    components: { FinishIllustration, Icon },
    
    setup() {
        const state = reactive<ExamesState>({
            loading: false,
            agendamentos: [],
            agendamentosFiltered: [],
            currentAgendamento: null,
            idAgendamentoSelecionado: "",
            loadingDetail: false,
            examRecord: null,
            examAnswer: null,
            exam: null,
            selectedDate: new Date(),
            calendarOpened: false,
            filtersOpened: false,
            search: "",
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        /** Redirect */
        const redirect = () => {
            // let BUBBLE_URL = 'https://easyproctor-admin.bubbleapps.io'
            // if(process.env.NODE_ENV === 'production')
            //     BUBBLE_URL = 'https://easyproctor-admin.bubbleapps.io'

            // const url = process.env.BUBBLE_URL + `/?token=` + vm.user?.token;
            const url = 'https://easyproctor-admin.bubbleapps.io'+`/?token=` + vm.user?.token;
            window.open(url);
        };

        const fetchExame = (exameID: string) => {
            // let exameID = state.search;
            // console.log(exameID);
            router.push({ path: `/exame/${exameID}` });
        };

        const listExams = () => {
            router.push({ path: `/exames` });
        };
       
       
        return { state, redirect, fetchExame, listExams, vm, defineColorByClient };

    }
});

export default MenuPage;
