
import { computed, defineComponent } from 'vue';
import vm from "@/viewModels/MainViewModel";

const StatusFilterButton = defineComponent({
    props: {
        active: {
            type: Boolean
        }
    },
    setup() {
        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        return { defineColorByClient };
    }
});

export default StatusFilterButton;
