
import { computed, defineComponent } from 'vue';
import vm from "@/viewModels/MainViewModel";

const Header = defineComponent({
    setup() {

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const defineLogoByClient = computed(() => {
            return vm.client?.logoUrl || 'https://iarisdev.blob.core.windows.net/aws/logo-easy.svg';
        });
        
        return { defineLogoByClient, defineColorByClient };
    }
});

export default Header;
