
import { computed, defineComponent, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { useRoute, useRouter } from "vue-router";
import vm from "@/viewModels/MainViewModel";

const SearchInput = defineComponent({
    components: { Icon },
    setup() {
        const { replace } = useRouter();
        const route = useRoute();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });
        
        const state = reactive({
            inputText: route.query.search  || ''
        });

        const handleTextInput = () => {
            replace({ query: { ...route.query, search: state.inputText } });
        };

        return { state, handleTextInput, defineColorByClient };
    }
});

export default SearchInput;
