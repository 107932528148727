<template>
    <div class="h-screen flex items-center relative">

        <!-- Conteúdo -->
        
        <div class="w-2/5 mx-32 max-w-screen-sm flex flex-col">
            <p class="text-4xl font-bold mb-6">Bem vindo ao painel de auditoria</p>
            <p class="opacity-80 mb-10">Digite o identificador da sessão de proctoring.</p>

            <!-- Busca -->
            <div class="bg-white">
                <div
                    class="h-10 bg-gray-100 rounded border border-neutral-2 flex items-center px-4"
                >
                    <Icon icon="search" color="#363738" size="15px" class="mr-4" />
                    <input
                        class="flex-1 bg-transparent outline-none"
                        placeholder="Buscar sessão"
                        v-model="state.search"
                        autocomplete="off"
                    />
                </div>
            </div>
            <br>
            <div class="flex">
                <button class="h-14 w-80 rounded-lg text-white font-medium mt-6" :style="{ backgroundColor: `${defineColorByClient}` }" @click="fetchExame(state.search)">
                    Pesquisar Sessão
                </button>
                <button class="h-14 w-80 ml-4 bg-white rounded-lg border-2 font-medium mt-6" :style="{ borderColor: `${defineColorByClient}`, color: `${defineColorByClient}` }" @click="listExams()">
                    Listar Sessões
                </button>
            </div>
        </div>

        <!-- Ilustração -->
        <div class="flex-1 flex justify-center">
            <FinishIllustration class="w-full" style="max-width: 26rem" :color="defineColorByClient"/>
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, reactive } from 'vue';
import FinishIllustration from '@/components/illustrations/FinishIllustration.vue';
import vm from "@/viewModels/MainViewModel";
import Icon from "@/components/Icon.vue";
import router from '@/router/index';

import RecordDTO from "@/dtos/RecordDTO";
import ExamAnswerDTO from "@/dtos/ExamAnswerDTO";
import SchedulingDTO from "@/dtos/SchedulingDTO";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";

interface ExamesState {
    loading: boolean;
    agendamentos: SchedulingDTO[];
    agendamentosFiltered: SchedulingDTO[];
    idAgendamentoSelecionado: string;
    currentAgendamento: SchedulingDTO | null;
    loadingDetail: boolean;
    examRecord: RecordDTO | null;
    examAnswer: ExamAnswerDTO | null;
    exam: ExamResponseDTO | null;
    selectedDate: Date;
    calendarOpened: boolean;
    filtersOpened: boolean;
    search: string;
}

const MenuPage = defineComponent({
    components: { FinishIllustration, Icon },
    
    setup() {
        const state = reactive<ExamesState>({
            loading: false,
            agendamentos: [],
            agendamentosFiltered: [],
            currentAgendamento: null,
            idAgendamentoSelecionado: "",
            loadingDetail: false,
            examRecord: null,
            examAnswer: null,
            exam: null,
            selectedDate: new Date(),
            calendarOpened: false,
            filtersOpened: false,
            search: "",
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        /** Redirect */
        const redirect = () => {
            // let BUBBLE_URL = 'https://easyproctor-admin.bubbleapps.io'
            // if(process.env.NODE_ENV === 'production')
            //     BUBBLE_URL = 'https://easyproctor-admin.bubbleapps.io'

            // const url = process.env.BUBBLE_URL + `/?token=` + vm.user?.token;
            const url = 'https://easyproctor-admin.bubbleapps.io'+`/?token=` + vm.user?.token;
            window.open(url);
        };

        const fetchExame = (exameID: string) => {
            // let exameID = state.search;
            // console.log(exameID);
            router.push({ path: `/exame/${exameID}` });
        };

        const listExams = () => {
            router.push({ path: `/exames` });
        };
       
       
        return { state, redirect, fetchExame, listExams, vm, defineColorByClient };

    }
});

export default MenuPage;
</script>

<style>

</style>