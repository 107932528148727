<template>
    <div class="flex flex-col bg-gray-100 w-64 h-48 rounded-md">
        <div class="flex justify-between mb-2 p-2 rounded-t" :class="getColor(alertStatus)">
            <p class="text-white">{{ index+1 }}.</p>
            <div class="flex items-center alert-text">
                <p class="mr-1 text-white">{{ millisToMinutesAndSeconds(start) }}</p>
                <Icon
                    icon="play"
                    color="#fff"
                    size="22px"
                    class="cursor-pointer"
                    @click="playButton(start)"
                />
            </div>
        </div>
        <div 
            class="flex items-center justify-center border-gray-300 pb-2 w-full"
        >
            <div class="flex mr-2 gap-2">
                <span 
                    title="Não auditado"
                    class="bg-gray-5 rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                    :class="alertStatus === 'REVIEWING' && 'border-2 border-blue-500'" 
                    @click="handleAudit('REVIEWING', id)"
                >
                    <Icon icon="timer" color="#FEFEFE" size="18px" />
                </span>
                <span 
                    title="Indício de fraude"
                    class="bg-error rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                    :class="alertStatus === 'BLOCKED' && 'border-2 border-blue-500'" 
                    @click="handleAudit('BLOCKED', id)"
                >
                    <div class="rounded-full border text-white p-1">
                        <Icon icon="error" color="#FEFEFE" size="8px" />
                    </div>
                </span>
                <span 
                    title="Alerta inválido"
                    class="bg-eyellow rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                    :class="alertStatus === 'SYSTEM_FAILURE' && 'border-2 border-blue-500'" 
                    @click="handleAudit('SYSTEM_FAILURE', id)"    
                >
                    <Icon icon="alert" color="#FEFEFE" size="18px" />
                </span>
                <span 
                    title="Alerta liberado"
                    class="bg-success rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                    :class="alertStatus === 'RELEASED' && 'border-2 border-blue-500'" 
                    @click="handleAudit('RELEASED', id)"
                >
                    <Icon icon="check-circle" color="#FEFEFE" size="18px" />
                </span>
            </div>
        </div>
        <div class="mx-2 grow text-center h-full flex items-center justify-center">
            <span class="text-gray-1 font-bold max-h-24 overflow-y-auto">
                {{ description }}
            </span>
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, PropType, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { IAlert } from "@/dtos/AlertaDTO";
import DropdownActionButton from "../DropdownActionButton.vue";
import { tradutorAlerta } from "@/utils/stringFunctions";

const AudioCard = defineComponent({
    components: {
        Icon,
        DropdownActionButton,
    },
    emits: ['check', 'audit'],
    props: {
        index: {
            type: Number,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
        alertStatus: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        start: {
            type: Number,
            required: true,
        },        
        end: {
            type: Number,
            required: true,
        },        
    },
    setup(props, { emit }) {
        const state = reactive({
            collapsed: true,
        });

        const playButton = (start: number) => {
            const auditAudio = document.getElementById(
                "auditAudio"
            ) as HTMLAudioElement;
            const time = start / 1000;
            auditAudio.currentTime = time;
            auditAudio.play();
        };

        const text = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'Não auditado';
                case "RELEASED":
                    return 'Alerta liberado';
                case "BLOCKED":
                    return 'Indício de fraude';
                case "SYSTEM_FAILURE":
                    return 'Alerta inválido';
            }
        };

        const getColor = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'bg-gray-5';
                case "RELEASED":
                    return 'bg-success';
                case "BLOCKED":
                    return 'bg-error';
                case "SYSTEM_FAILURE":
                    return 'bg-eyellow';
            }
        };

        const handleCollapse = () => {
            state.collapsed = !state.collapsed;
        };

        const handleAudit = (value: string, id: number ) => {
            // console.log(value, id, resultFrameId);
            emit('audit', value, id);
        };

        const VALIDATE_ACTIONS_OPTIONS = [
            {
                label: "Não auditado",
                value: "REVIEWING",
            },
            {
                label: "Liberar Alerta",
                value: "RELEASED",
            },
            {
                label: "Indício de fraude",
                value: "BLOCKED",
            },
            {
                label: "Alerta Inválido",
                value: "SYSTEM_FAILURE",
            },
        ];

        function millisToMinutesAndSeconds(millis: number) {
            let minutes = Math.floor(millis / 60000);
            let seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
        }

        return {
            tradutorAlerta,
            playButton,
            handleCollapse,
            text,
            handleAudit,
            state,
            VALIDATE_ACTIONS_OPTIONS,
            getColor,
            millisToMinutesAndSeconds
        };
    },
});

export default AudioCard;
</script>

<style>
.btn-validation {
  min-width: 120px;
}

.alert-card:not(:first-child) {
  background-color: #523443;
}
</style>