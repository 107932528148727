<template>
    <div class="w-full bg-neutral-3 h-44 flex justify-between mb-8">
        <div class="w-56">
            <button class="mx-7 mt-8 h-7 text-neutral-4 cursor-pointer" @click="$router.push(handleBackPath)">
                <div class="flex items-center">
                    <Icon icon="caret-left" class="mr-4 float-left" color="#363738" /><span class="font-bold">Voltar</span>
                </div>
            </button>
        </div>
        <div class="flex flex-col justify-between pt-8 px-4">
            <div>
                <slot />
            </div>
            <div class="flex justify-center mt-2">
                <SearchInput 
                    :placeholder="searchPlaceholder"
                />
            </div>
        </div>
        <div class="h-full mt-2" id="banner-img">
            <HomeIllustration :color="defineColorByClient" />
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Icon from '@/components/Icon.vue';
import SearchInput from '@/components/SearchInput.vue';
import HomeIllustration from "@/components/illustrations/HomeIllustration.vue";
import vm from "@/viewModels/MainViewModel";

const Banner = defineComponent({
    components: { Icon, SearchInput, HomeIllustration },
    props: {
        searchPlaceholder: {
            type: String,
            default: "Pesquise"
        },
        handleBackPath: {
            type: String,
            default: "/"
        }
    },
    setup(){
        const { replace } = useRouter();
        const route = useRoute();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });
        
        const state = reactive({
            serch: route.query.serch || "",
        });

        const setFilter = (value: number) => {
            state.serch = value.toString();
        };

        const filter = () => {
            const search = state.serch.length > 0 ? state.serch : null;
            replace({ query: { ...route.query, search } });
        };

        watch(() => state.serch, () => {
            filter();
        });
        

        return { state, setFilter, filter, defineColorByClient };
    }
});

export default Banner;
</script>

<style>

</style>
