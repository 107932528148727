<template>
    <div class="bg-white flex items-center justify-between h-14 bg-ewhite -mb-7 pl-6 rounded-lg shadow-md"
         style="width: 478px;"
    >
        <input 
            type="text" 
            class="h-30px w-450 px-1 text-sm text-neutral-4 border-none outline-none" 
            style="width: 100%;"
            :placeholder="$attrs['placeholder']" 
            v-model="state.inputText"
            @keyup.enter="handleTextInput"
        />
        <button class="rounded h-30px w-30px mr-4 shadow-3 opacity-30"
                style="height: 30px; width: 30px;"
                :style="{ backgroundColor: `${defineColorByClient}` }"
                @click="handleTextInput">
            <Icon icon="search" color="#fff" class="m-auto" />
        </button>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { useRoute, useRouter } from "vue-router";
import vm from "@/viewModels/MainViewModel";

const SearchInput = defineComponent({
    components: { Icon },
    setup() {
        const { replace } = useRouter();
        const route = useRoute();

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });
        
        const state = reactive({
            inputText: route.query.search  || ''
        });

        const handleTextInput = () => {
            replace({ query: { ...route.query, search: state.inputText } });
        };

        return { state, handleTextInput, defineColorByClient };
    }
});

export default SearchInput;
</script>

<style>

</style>