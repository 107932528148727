<template>
    <div class="w-6 h-6 flex items-center justify-center uppercase text-white font-medium shadow"
         :class="avatarClasses"
         :style="{ backgroundColor: color }"
         id="candidate-image"
    >
        <img
            v-if="src"
            class="w-full h-full overflow-hidden object-cover"
            :class="roundClasses"
            :src="src"
        />
        <img
            v-else
            class="w-7 h-7 overflow-hidden"
            :class="roundClasses"
            src="@/assets/img/User_icon.png"
        />
    </div>
</template>
<script>
import { defineComponent, computed } from "vue";

const BaseAvatar = defineComponent({
    inheritAttrs: false,

    props: {
        name: {
            type: String,
            default: "",
        },
        color: {
            type: String,
            default: "teal",
        },
        size: {
            type: String,
            default: "md", //sm, md, lg
        },
        round: {
            type: Boolean,
            default: true,
        },
        src: {
            type: String,
            default: "",
        }
    },

    setup(props, context) {
        const sizeClasses = computed(() => {
            const sizeMappings = {
                sm: `h-12 w-12`,
                md: `h-16 w-16 text-xl`,
                lg: `text-lg h-24 w-24 text-2xl`,
            };
            return sizeMappings[props.size] || sizeMappings.md;
        });
        const roundClasses = computed(() => {
            return props.round ? "rounded-full" : "rounded";
        });
        const avatarClasses = computed(() => {
            return `${roundClasses.value} ${sizeClasses.value}`;
        });
        const avatarText = computed(() => {
            if (!props.name || typeof props.name !== "string" || !props.name.length) {
                return "";
            }
            const split = props.name.split(" ");

            return `${split[0]?.toUpperCase().charAt(0) || ""}${split[1]?.toUpperCase().charAt(0) || ""}`;
        });
        return {
            sizeClasses,
            roundClasses,
            avatarClasses,
            avatarText,
        };
    },
});

export default BaseAvatar;
</script>

<style scoped>
#candidate-image {
    transition: .5s ease-in-out;
}
#candidate-image:hover {
    /* position: relative; */
    transform: scale(2.5);
} 
</style>