<template>
    <svg :color="color" width="332" height="166" viewBox="0 0 332 166" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_103_3)">
            <mask id="mask0_103_3" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-1" y="0" width="333" height="166">
                <path d="M332 0H-0.666656V166H332V0Z" fill="#C4C4C4"/>
            </mask>
            <g mask="url(#mask0_103_3)">
                <path opacity="0.6" d="M44.9675 89.1007C-108.986 157.759 -331.318 154.434 -423.24 144.189V225.663H490.165C492.374 195.799 495.465 132.244 490.165 116.935C484.866 101.626 464.367 87.8667 425.669 73.4438C362.916 50.0555 198.922 20.4426 44.9675 89.1007Z" fill="#F8F8F8"/>
                <path opacity="0.6" d="M37.995 103.598C-115.959 172.256 -338.291 168.931 -430.212 158.687V240.16H483.193C485.401 210.296 488.492 146.741 483.193 131.432C477.894 116.123 457.394 102.364 418.697 87.9409C355.944 64.5525 191.949 34.9397 37.995 103.598Z" fill="#F4F4F4"/>
                <path opacity="0.4" d="M-97.6212 150.651C-239.513 172.686 -387.215 168.338 -422.194 166.308V228.935L447.982 223.426C484.007 199.168 577.599 178.485 536.882 172.687C496.162 166.888 553.616 136.733 469.248 136.734C272.97 113.828 118.3 117.119 -97.6212 150.651Z" fill="currentColor"/>
            </g>
            <path d="M213.341 65.6055L200.259 109.738C199.662 111.692 197.87 113.049 195.808 113.049L142.774 113.212C139.951 113.212 137.725 110.878 137.888 108.055C138.051 105.45 140.168 103.387 142.774 103.387H163.075L179.306 58.1147H207.695C211.604 58.1147 214.426 61.8601 213.341 65.6055Z" fill="currentColor"/>
            <path d="M180.5 107.187C173.814 107.187 168.395 107.113 168.395 107.024C168.395 106.934 173.814 106.861 180.5 106.861C187.185 106.861 192.605 106.934 192.605 107.024C192.605 107.113 187.185 107.187 180.5 107.187Z" fill="#263238"/>
            <path d="M209.432 59.5264C209.323 59.4718 205.74 70.0574 201.344 83.139C197.001 96.2213 193.527 106.807 193.635 106.861C193.744 106.915 197.327 96.3297 201.723 83.2481C206.066 70.1659 209.486 59.5803 209.432 59.5264Z" fill="#263238"/>
            <path d="M170.62 152.241L173.877 152.187V113.049H170.62V152.241Z" fill="currentColor"/>
            <path d="M187.285 152.078H157.212L170.837 147.356H174.094L187.285 152.078Z" fill="currentColor"/>
            <path d="M194.938 48.0181L189.944 72.8801L190.813 91.4986L157.321 85.9078L158.623 69.5684V56.0524L150.481 72.0113L144.076 59.6894L155.801 38.5189C157.918 36.2929 160.469 34.6651 163.454 33.5794C164.92 33.0366 166.44 32.7106 168.232 32.656C172.086 33.8508 175.939 33.8508 179.794 32.656C180.282 32.7106 180.933 32.7651 181.748 32.8736C181.802 32.8736 181.856 32.8736 181.911 32.8736C189.022 33.7417 194.45 39.6046 194.83 46.7701L194.938 48.0181Z" fill="#857D8E"/>
            <path d="M181.802 32.819C181.802 32.819 195.536 32.9274 197.273 47.4214L197.49 58.0609L185.656 57.9517L181.802 32.819Z" fill="#857D8E"/>
            <path d="M148.907 51.5466C148.907 51.6551 159.71 51.7096 173.064 51.7096C186.417 51.7096 197.22 51.6012 197.22 51.5466C197.22 51.4382 186.417 51.3836 173.064 51.3836C159.764 51.3298 148.907 51.4382 148.907 51.5466Z" fill="#FAFAFA"/>
            <path d="M148.907 53.8265C148.907 53.9349 159.71 53.9895 173.064 53.9895C186.417 53.9895 197.22 53.881 197.22 53.8265C197.22 53.718 186.417 53.6635 173.064 53.6635C159.764 53.6635 148.907 53.718 148.907 53.8265Z" fill="#FAFAFA"/>
            <path d="M149.721 49.5921C149.721 49.7012 160.36 49.7551 173.497 49.7551C186.633 49.7551 197.273 49.6467 197.273 49.5921C197.273 49.4837 186.633 49.4298 173.497 49.4298C160.36 49.4298 149.721 49.5382 149.721 49.5921Z" fill="#FAFAFA"/>
            <path d="M158.429 58.8636C158.269 51.6105 158.213 45.7282 158.303 45.7261C158.393 45.7246 158.595 51.6034 158.755 58.8565C158.914 66.1103 158.971 71.9919 158.881 71.994C158.791 71.9962 158.589 66.1175 158.429 58.8636Z" fill="#263238"/>
            <path d="M163.564 62.4575C163.564 62.4575 157.05 62.9458 155.911 64.6289C154.716 66.3659 152.979 71.2516 153.63 71.3054C154.282 71.36 154.825 70.9256 154.825 70.9256L156.616 67.7769C156.616 67.7769 156.344 70.8171 157.376 70.871C158.462 70.9256 163.727 72.0113 163.727 72.0113L163.564 62.4575Z" fill="#FFBE9D"/>
            <path d="M197.49 55.292V72.0658C197.274 75.9742 193.582 78.7969 189.782 77.9281L163.564 72.4996L163.618 61.7517L186.254 66.0945L186.58 54.6953L197.49 55.292Z" fill="#857D8E"/>
            <path d="M182.508 76.5709C182.508 76.5709 182.4 76.5164 182.183 76.4625C181.911 76.4079 181.585 76.3541 181.151 76.2449C180.228 76.0281 178.926 75.7567 177.297 75.4314C173.932 74.7255 169.264 73.8028 163.672 72.6625L163.781 72.8256C163.781 69.46 163.781 65.7146 163.781 61.8062L163.564 61.9692C165.029 62.2407 166.603 62.5659 168.178 62.8374C174.8 64.0861 180.988 65.2802 186.2 66.2575L186.417 66.3113V66.0945C186.254 61.1004 186.091 56.9206 185.982 53.9895C185.928 52.5778 185.874 51.4382 185.82 50.6239C185.82 50.2441 185.765 49.9727 185.765 49.7551C185.765 49.5382 185.765 49.4298 185.711 49.4298C185.711 49.4298 185.711 49.5382 185.711 49.7551C185.711 49.9727 185.711 50.2979 185.711 50.6239C185.711 51.4382 185.765 52.5778 185.765 53.9895C185.82 56.9206 185.928 61.1004 186.037 66.0945L186.2 65.9315C180.988 64.9004 174.8 63.7062 168.178 62.4575C166.603 62.1315 165.029 61.8601 163.564 61.5887L163.346 61.5348V61.7517C163.346 65.7146 163.346 69.4054 163.346 72.771V72.934H163.455C169.046 74.0197 173.769 74.9424 177.134 75.5937C178.763 75.9197 180.065 76.1365 180.988 76.2995C181.422 76.3541 181.748 76.4079 182.02 76.4625C182.4 76.5709 182.508 76.6255 182.508 76.5709Z" fill="#263238"/>
            <path d="M167.472 32.819C167.472 32.819 167.417 32.8736 167.364 33.0904C167.309 33.2534 167.255 33.5248 167.146 33.8501C167.038 34.5021 166.929 35.4787 167.254 36.6728C167.58 37.8131 168.34 39.1156 169.535 40.2013C170.729 41.287 172.466 42.0475 174.312 41.9929C176.157 41.9929 177.948 41.2332 179.034 40.0929C180.174 38.8988 180.663 37.4871 180.771 36.2929C180.88 35.0988 180.663 34.1215 180.446 33.4703C180.337 33.145 180.228 32.9274 180.12 32.7644C180.011 32.6021 179.957 32.5476 179.957 32.5476C179.903 32.5476 180.12 32.8736 180.337 33.5248C180.554 34.1215 180.717 35.0988 180.554 36.2391C180.391 37.3786 179.903 38.7358 178.817 39.8215C177.785 40.9072 176.103 41.613 174.366 41.613C172.575 41.613 170.946 40.9618 169.806 39.9299C168.666 38.8988 167.906 37.7046 167.58 36.6189C167.255 35.5332 167.255 34.556 167.364 33.9047C167.364 33.1988 167.472 32.8736 167.472 32.819Z" fill="#263238"/>
            <path d="M166.169 73.4223C166.223 73.4223 166.223 72.771 166.169 71.7944C166.115 70.7626 166.061 69.3516 166.061 67.8315C166.061 66.2575 166.061 64.9003 166.115 63.8685C166.115 62.8374 166.169 62.2407 166.115 62.2407C166.061 62.2407 165.952 62.8374 165.843 63.8685C165.735 64.9003 165.68 66.3113 165.68 67.886C165.68 69.46 165.79 70.871 165.898 71.9028C166.006 72.8255 166.169 73.4223 166.169 73.4223Z" fill="#FAFAFA"/>
            <path d="M167.527 73.6937C167.581 73.6937 167.581 73.0424 167.581 72.0651C167.581 70.9256 167.581 69.5684 167.527 68.1029C167.527 66.6373 167.527 65.2802 167.527 64.1399C167.527 63.1088 167.527 62.5114 167.472 62.5114C167.418 62.5114 167.364 63.1088 167.255 64.1399C167.201 65.1718 167.147 66.5289 167.147 68.1029C167.147 69.6769 167.201 71.034 167.31 72.0651C167.418 73.097 167.472 73.6937 167.527 73.6937Z" fill="#FAFAFA"/>
            <path d="M141.308 140.299L136.369 153.924L129.312 163.587C128.823 164.292 128.986 165.269 129.746 165.703C130.18 165.975 130.669 166.029 131.103 165.812C133.926 164.618 144.348 160.275 144.674 159.244C145.054 158.05 151.731 143.393 151.731 143.393L141.308 140.299Z" fill="#C7C2CC"/>
            <g opacity="0.6">
                <g opacity="0.6">
                    <path opacity="0.6" d="M129.691 165.757L144.944 158.375L144.727 158.972C144.51 159.461 144.184 159.895 143.696 160.112C142.447 160.763 138.918 162.555 131.048 165.866C130.668 166.083 130.125 166.029 129.691 165.757Z" fill="white"/>
                </g>
            </g>
            <g opacity="0.6">
                <g opacity="0.6">
                    <g opacity="0.6">
                        <path opacity="0.6" d="M143.913 152.947C143.479 153.055 143.153 153.544 143.262 153.978C143.37 154.413 143.804 154.738 144.239 154.629C144.673 154.521 145.053 153.978 144.944 153.544C144.836 153.109 144.239 152.784 143.859 152.947" fill="white"/>
                    </g>
                </g>
            </g>
            <path d="M135.12 161.089C135.012 161.089 135.012 160.275 134.36 159.569C133.709 158.864 132.895 158.755 132.895 158.646C132.895 158.592 133.112 158.592 133.438 158.646C133.763 158.701 134.197 158.918 134.577 159.352C134.957 159.732 135.12 160.221 135.175 160.546C135.175 160.872 135.12 161.089 135.12 161.089Z" fill="#263238"/>
            <path d="M133.437 162.501C133.328 162.555 133.111 161.903 132.46 161.469C131.862 160.981 131.211 160.872 131.211 160.764C131.211 160.655 131.971 160.655 132.676 161.198C133.382 161.74 133.491 162.501 133.437 162.501Z" fill="#263238"/>
            <path d="M137.779 159.461C137.671 159.515 137.29 158.538 136.367 157.724C135.499 156.909 134.522 156.583 134.522 156.475C134.522 156.421 135.608 156.583 136.585 157.507C137.561 158.375 137.887 159.406 137.779 159.461Z" fill="#263238"/>
            <path d="M139.626 155.389C139.571 155.444 138.92 154.846 137.997 154.413C137.02 153.978 136.152 153.924 136.206 153.815C136.206 153.761 137.128 153.652 138.16 154.087C139.137 154.63 139.734 155.389 139.626 155.389Z" fill="#263238"/>
            <path d="M141.199 151.427C141.144 151.481 140.548 150.721 139.517 150.396C138.485 150.015 137.562 150.233 137.562 150.124C137.562 150.07 137.779 149.961 138.16 149.907C138.539 149.853 139.082 149.853 139.625 150.07C140.168 150.287 140.602 150.613 140.873 150.884C141.144 151.209 141.254 151.427 141.199 151.427Z" fill="#263238"/>
            <path d="M139.842 149.69C139.842 149.69 139.842 149.472 140.005 149.147C140.168 148.821 140.439 148.387 140.928 147.953C141.145 147.735 141.47 147.519 141.905 147.464C142.122 147.464 142.393 147.573 142.502 147.845C142.611 148.061 142.556 148.333 142.502 148.55C142.176 149.419 141.091 149.961 140.059 149.69C139.028 149.419 138.376 148.496 138.268 147.627C138.268 147.41 138.268 147.139 138.485 146.976C138.702 146.759 138.974 146.813 139.191 146.921C139.57 147.139 139.733 147.464 139.896 147.735C140.222 148.278 140.331 148.821 140.385 149.147C140.439 149.472 140.439 149.69 140.385 149.69C140.331 149.69 140.276 148.876 139.625 147.845C139.462 147.573 139.245 147.302 138.974 147.139C138.865 147.084 138.702 147.084 138.594 147.193C138.485 147.302 138.485 147.464 138.485 147.627C138.594 148.387 139.191 149.201 140.059 149.419C140.928 149.635 141.85 149.147 142.122 148.496C142.176 148.333 142.23 148.17 142.176 148.007C142.122 147.898 142.013 147.79 141.85 147.79C141.525 147.79 141.254 148.007 141.036 148.17C140.113 148.93 139.896 149.69 139.842 149.69Z" fill="#263238"/>
            <path d="M120.355 136.771L120.789 147.844C120.789 147.844 109.661 152.024 109.552 154.087L131.102 154.033L131.157 136.716L120.355 136.771Z" fill="#C7C2CC"/>
            <g opacity="0.6">
                <g opacity="0.6">
                    <path opacity="0.6" d="M127.465 146.867C127.899 146.976 128.171 147.464 128.062 147.845C127.954 148.278 127.519 148.55 127.085 148.441C126.65 148.333 126.325 147.79 126.434 147.356C126.597 146.921 127.139 146.65 127.519 146.813" fill="white"/>
                </g>
            </g>
            <g opacity="0.6">
                <path opacity="0.6" d="M131.048 153.978V152.241L110.366 152.947C110.366 152.947 109.389 153.381 109.497 154.033L131.048 153.978Z" fill="white"/>
            </g>
            <path d="M120.463 147.735C120.463 147.845 121.006 147.898 121.494 148.224C122.037 148.55 122.308 148.984 122.363 148.984C122.471 148.984 122.308 148.333 121.657 147.953C121.06 147.519 120.408 147.627 120.463 147.735Z" fill="#263238"/>
            <path d="M118.183 148.659C118.183 148.767 118.618 148.93 118.997 149.364C119.377 149.798 119.486 150.287 119.54 150.287C119.649 150.287 119.703 149.69 119.269 149.147C118.78 148.604 118.183 148.55 118.183 148.659Z" fill="#263238"/>
            <path d="M116.88 151.372C116.988 151.372 117.097 150.884 116.826 150.287C116.555 149.69 116.066 149.472 116.012 149.582C115.957 149.69 116.283 149.961 116.5 150.45C116.771 150.939 116.826 151.372 116.88 151.372Z" fill="#263238"/>
            <path d="M120.626 145.184C120.68 145.293 121.169 145.13 121.766 145.184C122.363 145.184 122.797 145.402 122.906 145.293C122.96 145.239 122.526 144.804 121.82 144.804C121.06 144.804 120.571 145.13 120.626 145.184Z" fill="#263238"/>
            <g opacity="0.3">
                <path opacity="0.3" d="M120.462 138.942L120.517 140.95L131.102 140.787V138.996L120.462 138.942Z" fill="black"/>
            </g>
            <path d="M120.571 142.959C120.571 143.013 121.005 143.067 121.656 142.959C121.982 142.904 122.362 142.796 122.797 142.633C123.014 142.524 123.23 142.416 123.393 142.308C123.502 142.253 123.611 142.199 123.719 142.036C123.828 141.928 123.882 141.656 123.773 141.493C123.665 141.33 123.448 141.276 123.285 141.276C123.122 141.276 123.014 141.33 122.905 141.33C122.688 141.385 122.417 141.493 122.254 141.547C121.819 141.71 121.493 141.982 121.277 142.198C120.788 142.687 120.625 143.067 120.625 143.067C120.679 143.122 120.897 142.796 121.44 142.361C121.711 142.145 122.036 141.982 122.417 141.819C122.634 141.765 122.797 141.656 123.014 141.602C123.23 141.547 123.448 141.547 123.502 141.656C123.502 141.71 123.502 141.71 123.502 141.765C123.448 141.819 123.34 141.873 123.285 141.928C123.068 142.036 122.905 142.145 122.742 142.253C122.362 142.416 121.982 142.579 121.711 142.633C120.951 142.85 120.571 142.904 120.571 142.959Z" fill="#263238"/>
            <path d="M120.734 143.122C120.788 143.122 121.006 142.742 121.006 142.09C121.006 141.765 121.006 141.33 120.843 140.896C120.788 140.679 120.68 140.462 120.571 140.245C120.463 140.028 120.3 139.756 119.866 139.702C119.649 139.702 119.486 139.865 119.432 140.028C119.377 140.19 119.323 140.299 119.323 140.408C119.269 140.679 119.269 140.896 119.323 141.114C119.377 141.547 119.594 141.928 119.812 142.253C120.245 142.796 120.68 142.959 120.68 142.904C120.734 142.85 120.355 142.633 120.029 142.09C119.866 141.819 119.703 141.493 119.703 141.059C119.703 140.842 119.703 140.625 119.703 140.462C119.757 140.245 119.812 140.028 119.92 140.082C120.029 140.082 120.192 140.245 120.3 140.408C120.408 140.625 120.517 140.788 120.571 141.004C120.734 141.385 120.788 141.765 120.788 142.09C120.788 142.687 120.68 143.067 120.734 143.122Z" fill="#263238"/>
            <path d="M131.103 83.5734C143.805 84.876 160.687 86.613 160.687 86.613L158.407 107.349C158.407 107.349 133.6 100.781 133.817 101.324C133.98 101.867 132.677 145.402 132.677 145.402H117.749L117.478 96.1129C117.315 88.6759 123.721 82.8137 131.103 83.5734Z" fill="#263238"/>
            <path d="M191.14 91.933V98.3381C191.14 102.735 186.146 107.349 181.803 107.349H178.437C175.778 107.349 171.598 105.395 169.427 103.821L168.124 102.898L152.599 151.047C152.599 151.047 134.794 147.845 134.794 147.302C134.794 146.759 146.845 107.892 152.382 89.8162C154.662 82.4339 162.532 78.1449 169.861 80.6423C170.132 80.7507 170.349 80.8053 170.621 80.9137C178.546 84.1163 191.14 91.933 191.14 91.933Z" fill="#263238"/>
            <path d="M134.794 147.301C134.794 147.301 134.849 147.247 134.849 147.139C134.903 146.976 134.957 146.813 135.065 146.65C135.228 146.216 135.5 145.51 135.771 144.641C136.369 142.904 137.183 140.353 138.159 137.204C140.168 130.908 142.774 122.223 145.705 112.615C147.17 107.783 148.528 103.224 149.776 99.044C150.374 96.981 150.97 94.9726 151.513 93.1272C151.785 92.2045 152.056 91.2818 152.327 90.4675C152.599 89.5986 152.816 88.7844 153.142 88.0247C153.739 86.5045 154.716 85.365 155.639 84.4961C156.616 83.6273 157.485 82.976 158.244 82.4331C159.005 81.9449 159.601 81.565 160.036 81.2936C160.199 81.1852 160.361 81.076 160.524 81.0222C160.633 80.9676 160.687 80.9137 160.687 80.9137C160.687 80.9137 160.633 80.9137 160.524 80.9676C160.416 81.0222 160.253 81.1306 160.036 81.239C159.601 81.4566 159.005 81.8364 158.19 82.3247C157.43 82.8137 156.507 83.465 155.476 84.3331C154.499 85.202 153.522 86.3961 152.87 87.9162C152.545 88.6759 152.274 89.4902 152.002 90.3583C151.731 91.2272 151.459 92.096 151.188 93.0188C150.645 94.8642 150.048 96.8726 149.396 98.9355C148.148 103.115 146.737 107.675 145.325 112.506C142.448 122.114 139.788 130.799 137.888 137.096C136.911 140.245 136.151 142.741 135.608 144.533C135.337 145.402 135.12 146.053 134.957 146.541C134.903 146.758 134.849 146.921 134.794 147.084C134.794 147.247 134.794 147.301 134.794 147.301Z" fill="currentColor"/>
            <path d="M131.049 95.081C130.94 95.1356 131.809 96.4381 132.514 98.1213C133.22 99.8582 133.546 101.324 133.654 101.324C133.763 101.324 133.6 99.7498 132.84 97.9583C132.134 96.2213 131.103 95.0272 131.049 95.081Z" fill="currentColor"/>
            <path d="M128.172 97.0894C128.117 97.1985 129.42 97.7414 130.723 98.8271C132.08 99.8582 132.895 100.998 133.003 100.944C133.112 100.889 132.352 99.6414 130.94 98.5557C129.638 97.4154 128.226 97.0356 128.172 97.0894Z" fill="currentColor"/>
            <path d="M152.435 151.047C152.544 151.101 156.723 138.182 161.826 122.277C166.928 106.372 170.945 93.3986 170.837 93.3986C170.728 93.344 166.549 106.263 161.446 122.168C156.344 138.073 152.327 150.993 152.435 151.047Z" fill="currentColor"/>
            <path d="M171.489 75.9197L120.625 76.4079L111.126 49.5921L148.147 49.8635L156.723 73.9651L171.489 74.1827V75.9197Z" fill="currentColor"/>
            <path d="M130.602 63.5691C130.443 62.441 131.228 61.3977 132.356 61.2382C133.484 61.0788 134.528 61.8637 134.688 62.9917C134.846 64.1198 134.062 65.1639 132.933 65.3233C131.805 65.4827 130.761 64.6971 130.602 63.5691Z" fill="white"/>
            <path d="M175.616 3.34343C175.127 1.55209 173.77 0.683533 172.033 0.0864684C170.295 -0.510668 168.233 -0.18496 166.768 0.954955C165.79 1.71495 165.031 2.80058 163.836 3.18058C161.937 3.832 159.656 2.42058 157.811 3.28915C156.725 3.77771 156.074 5.13477 156.4 6.27468C156.725 7.4146 157.865 8.33745 159.059 8.28316C157.811 8.71744 157.431 10.5088 158.137 11.6487C158.842 12.7886 160.362 13.1686 161.665 12.9515C162.968 12.68 164.107 11.9201 165.139 10.9973C166.116 10.1288 167.039 9.09737 168.07 8.28316C169.156 7.46889 170.405 6.87182 171.762 6.92611C173.553 7.03468 175.127 8.39173 175.941 10.0202C176.755 11.6487 176.918 13.4943 177.026 15.2857C177.136 17.077 177.136 18.9224 177.678 20.6594C177.732 20.8769 177.841 21.0938 178.058 21.1484C178.33 21.2568 178.601 20.9308 178.764 20.6594C180.392 17.6198 181.424 14.2542 181.804 10.7801C182.021 8.77173 181.967 6.87182 180.881 5.18906C179.795 3.50629 177.026 2.31201 175.616 3.34343Z" fill="#263238"/>
            <path d="M179.143 11.7573L180.066 32.0047C180.229 35.4248 179.74 40.5273 174.312 40.5273C169.59 40.1475 168.178 35.7501 168.016 32.5475C167.961 31.0274 167.906 29.7794 167.853 29.6164C167.853 29.6164 162.696 29.345 162.044 24.2963C161.773 21.8542 161.556 16.7514 161.447 12.083C161.338 7.08898 165.03 2.90924 169.969 2.36639L170.458 2.3121C175.832 2.04068 179.035 6.38334 179.143 11.7573Z" fill="#FFBE9D"/>
            <path d="M167.851 29.508C167.851 29.508 171.108 29.4535 174.311 26.9022C174.311 26.9022 173.117 30.756 168.014 30.8106L167.851 29.508Z" fill="#EB996E"/>
            <path d="M163.346 15.2857C163.401 15.6656 163.726 15.9913 164.106 15.937C164.486 15.937 164.812 15.6113 164.812 15.1771C164.758 14.7971 164.432 14.4714 164.052 14.5257C163.617 14.58 163.291 14.9057 163.346 15.2857Z" fill="#263238"/>
            <path d="M162.856 14.4172C162.966 14.5257 163.454 14.0372 164.268 13.9286C165.028 13.82 165.679 14.2 165.734 14.0915C165.788 14.0372 165.679 13.8743 165.408 13.7115C165.136 13.5487 164.702 13.4401 164.213 13.4944C163.725 13.5487 163.345 13.7658 163.128 13.9829C162.856 14.2 162.803 14.4172 162.856 14.4172Z" fill="#263238"/>
            <path d="M170.999 14.7429C171.053 15.1229 171.379 15.4486 171.759 15.3943C172.139 15.3943 172.465 15.0686 172.465 14.6343C172.41 14.2543 172.085 13.9286 171.705 13.9829C171.324 14.0372 170.999 14.3629 170.999 14.7429Z" fill="#263238"/>
            <path d="M170.565 13.7658C170.674 13.8744 171.162 13.3858 171.976 13.2773C172.737 13.1687 173.388 13.5487 173.442 13.4401C173.497 13.3858 173.388 13.223 173.117 13.0601C172.845 12.8973 172.411 12.7888 171.923 12.8431C171.434 12.8973 171.054 13.1144 170.837 13.3316C170.565 13.5487 170.511 13.7115 170.565 13.7658Z" fill="#263238"/>
            <path d="M168.34 19.8451C168.34 19.7913 167.851 19.7913 167.037 19.7367C166.82 19.7367 166.657 19.7367 166.603 19.5744C166.549 19.4114 166.603 19.1938 166.657 18.977C166.82 18.4887 166.928 17.9459 167.091 17.4027C167.689 15.1229 168.069 13.3316 168.014 13.2773C167.906 13.2773 167.363 15.0686 166.765 17.2941C166.603 17.8367 166.494 18.3796 166.386 18.8686C166.332 19.0854 166.223 19.3568 166.386 19.6828C166.44 19.8451 166.603 19.8997 166.765 19.9543C166.875 19.9543 167.037 19.9543 167.091 19.9543C167.851 19.9543 168.34 19.8997 168.34 19.8451Z" fill="#263238"/>
            <path d="M170.674 20.1711C170.565 20.1711 170.619 21.04 169.968 21.6913C169.317 22.3971 168.394 22.3971 168.394 22.5055C168.394 22.5594 168.611 22.6685 168.991 22.614C169.371 22.614 169.914 22.3971 170.294 22.0165C170.674 21.5828 170.837 21.0938 170.837 20.714C170.837 20.3341 170.728 20.1711 170.674 20.1711Z" fill="#263238"/>
            <path d="M170.023 10.726C170.131 10.9432 170.891 10.726 171.814 10.7803C172.737 10.7803 173.497 10.9432 173.606 10.7803C173.66 10.6717 173.497 10.5089 173.171 10.346C172.845 10.1832 172.357 10.0203 171.814 10.0203C171.271 10.0203 170.783 10.1832 170.457 10.346C170.131 10.4546 169.969 10.6174 170.023 10.726Z" fill="#263238"/>
            <path d="M162.856 12.1374C163.019 12.3003 163.508 12.0831 164.16 11.9746C164.811 11.866 165.354 11.9746 165.462 11.7575C165.517 11.6489 165.408 11.4861 165.137 11.3232C164.865 11.1604 164.485 11.1061 164.051 11.1604C163.617 11.2147 163.237 11.4318 163.019 11.6489C162.856 11.866 162.802 12.0831 162.856 12.1374Z" fill="#263238"/>
            <path d="M179.089 15.5571C179.198 15.5028 182.455 14.1458 182.726 17.6199C182.997 21.0938 179.415 20.6594 179.415 20.551C179.415 20.4971 179.089 15.5571 179.089 15.5571Z" fill="#FFBE9D"/>
            <path d="M180.391 19.1939C180.391 19.1939 180.446 19.2484 180.554 19.2484C180.663 19.3023 180.826 19.3023 180.989 19.2484C181.369 19.0854 181.64 18.4887 181.585 17.8367C181.585 17.5113 181.477 17.2399 181.369 16.9685C181.26 16.697 181.043 16.5342 180.88 16.5342C180.717 16.4799 180.554 16.6427 180.554 16.7513C180.5 16.8599 180.554 16.9142 180.5 16.9142C180.5 16.9142 180.446 16.8599 180.446 16.697C180.446 16.6427 180.5 16.5342 180.554 16.4799C180.609 16.4256 180.772 16.3713 180.88 16.3713C181.152 16.3713 181.423 16.5885 181.532 16.8599C181.695 17.1313 181.803 17.457 181.803 17.8367C181.857 18.5426 181.532 19.2484 181.043 19.4114C180.826 19.5199 180.609 19.4114 180.5 19.3569C180.446 19.2484 180.391 19.1939 180.391 19.1939Z" fill="#EB996E"/>
            <path d="M160.307 11.1059C162.586 11.4859 165.084 10.6174 166.658 8.93458C167.798 7.74038 168.558 6.16619 170.078 5.51477C171.109 5.08049 172.249 5.18906 173.28 5.62334C174.312 6.05762 175.18 6.76325 175.886 7.57753C175.18 10.2916 176.157 13.1144 177.08 15.7199C177.189 15.9914 177.297 16.3171 177.569 16.4799C178.003 16.7513 178.6 16.4256 178.926 16.0457C179.414 15.3942 179.577 14.5257 179.632 13.6572C179.794 11.7573 179.632 9.80315 178.98 8.01181C178.383 6.22047 177.297 4.5377 175.777 3.39772C173.877 1.93209 171.38 1.33503 169.046 1.66067C166.658 1.93209 164.433 3.07208 162.695 4.70056C161.881 5.46048 161.175 6.32904 160.796 7.41467" fill="#263238"/>
            <path d="M240.049 76.0826V80.9137H213.558V152.187H211.116V80.9137L100.487 81.4566V155.821H98.0991V81.2936L81 81.4566V75.7021L240.049 76.0826Z" fill="#263238"/>
            <path d="M223.111 79.9365L212.635 87.4818L213.558 88.3499L224.089 80.4247L223.111 79.9365Z" fill="#263238"/>
            <path d="M88.2201 80.6423L100.379 89.2734L99.4562 90.1415L87.2428 81.1306L88.2201 80.6423Z" fill="#263238"/>
            <path d="M174.149 74.3457H201.508V76.3541H174.149V74.3457Z" fill="#263238"/>
        </g>
        <defs>
            <clipPath id="clip0_103_3">
                <rect width="332" height="166" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const HomeIllustration = defineComponent({
    props: {
        color: {
            type: String,
            default: "#04986D"
        },
    },
});

export default HomeIllustration;
</script>

<style>

</style>