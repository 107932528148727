<template>
    <button
        class="border rounded-full font-medium"
        :class="{
            'text-white px-4 py-2': active, 
            'bg-white px-4 py-2': !active
        }"
        :style="active ? { backgroundColor: `${defineColorByClient}`, borderColor: `${defineColorByClient}` } : { color: `${defineColorByClient}`, borderColor: `${defineColorByClient}` }"
    >
        <slot/>
    </button>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue';
import vm from "@/viewModels/MainViewModel";

const StatusFilterButton = defineComponent({
    props: {
        active: {
            type: Boolean
        }
    },
    setup() {
        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        return { defineColorByClient };
    }
});

export default StatusFilterButton;
</script>
