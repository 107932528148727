<template>
    <Modal :isOpened="status.selectMediaModalOpened" width="860px">
        <div class="bg-white rounded-xl text-center">
            <!-- Header -->
            <div class="p-7 border-b border-neutral-2 flex justify-between items-center">
                <p class="font-medium text-xl">Parâmetros e Configurações</p>
                <Icon icon="close" color="#000" size="28px" class="mx-2 cursor-pointer" @click="toogleModal" />
            </div>
            <!-- Body -->
            <div
                class="w-full h-490px 2xl:h-680px overflow-y-scroll"
            >
                <!-- Monitoramento ativo -->
                <div
                    class="flex flex-col justify-between items-center items-sZ border-b border-neutral-2 pt-4 pb-7"
                >
                    <div class="w-full bg-primary-004">
                        <p class="text-left font-medium py-2 bg-white w-full border-b-2 border-primary px-7">Monitoramento ativo</p>
                        <div class="flex flex-col px-4 w-min text-left">
                            <div class="flex items-center py-3">
                                <p class="flex-1 px-4 font-medium w-max">Alertas de face</p>
                                <Switch
                                    radioValue="Face"
                                    name="Face"
                                    v-model="state.faceChecked"
                                    :activeColor="defineColorByClient"
                                    @pressed="() => changeSwitch('face')"
                                />
                            </div>
                            <div class="flex items-center py-3">
                                <p class="flex-1 px-4 font-medium w-max">
                                    Alertas de ruído no ambiente
                                </p>
                                <Switch
                                    radioValue="Ruído"
                                    name="Ruído"
                                    v-model="state.noiseChecked"
                                    :activeColor="defineColorByClient"
                                    @pressed="() => changeSwitch('noise')"
                                />
                            </div>
                            <div class="flex items-center py-3">
                                <p class="flex-1 px-4 font-medium w-max">
                                    Alertas de conversação
                                </p>
                                <Switch
                                    radioValue="Fala"
                                    name="Fala"
                                    v-model="state.speechChecked"
                                    :activeColor="defineColorByClient"
                                    @pressed="() => changeSwitch('speech')"
                                />
                            </div>
                            <div class="flex items-center py-3">
                                <p class="flex-1 px-4 font-medium w-max">
                                    Mostrar avisos no painel
                                </p>
                                <Switch
                                    radioValue="Mostrar avisos no painel"
                                    name="Mostrar avisos no painel"
                                    v-model="state.warningsChecked"
                                    :activeColor="defineColorByClient"
                                    @pressed="() => changeSwitch('warning')"
                                />
                            </div>
                            <!-- <div class="flex items-center py-3">
                                <p class="flex-1 px-4 font-medium w-max">Pessoas</p>
                                <Switch
                                    radioValue="Pessoas"
                                    name="Pessoas"
                                    v-model="state.personChecked"
                                    :activeColor="defineColorByClient"
                                    @pressed="() => changeSwitch('person')"
                                />
                            </div>
                            <div class="flex items-center py-3">
                                <p class="flex-1 px-4 font-medium w-max">Celular</p>
                                <Switch
                                    radioValue="Celular"
                                    name="Celular"
                                    v-model="state.phoneChecked"
                                    :activeColor="defineColorByClient"
                                    @pressed="() => changeSwitch('phone')"
                                />
                            </div> -->
                        </div>
                    </div>
                    <!-- <button
                        class="py-2 px-6 w-min rounded-lg text-white font-medium mt-6"
                        :style="{ backgroundColor: `${defineColorByClient}` }"
                        @click="() => save('activeMonitoring')"
                    >
                        Salvar
                    </button> -->
                </div>

                <!-- Processamento -->
                <div
                    class="flex flex-col justify-between items-center items-sZ border-b border-neutral-2 pt-4 pb-7"
                >
                    <div class="w-full bg-primary-004">
                        <p class="text-left font-medium py-2 bg-white w-full border-b-2 border-primary px-7">Parâmetros de Processamento</p>
                        <div class="flex flex-col gap-2 items-start px-7 pt-2 pb-5">
                            <p class="py-2">Sensibilidade da detecção</p>
                            <div class="w-full flex justify-around">
                                <div class="px-6 w-2/4">
                                    <input
                                        id="labels-range-input"
                                        type="range"
                                        v-model="state.sensibilityNum"
                                        min="0"
                                        max="2"
                                        step="1"
                                        class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                                        @input="handleSensibility"
                                    />
                                    <div class="flex justify-between mt-2 text-xs text-gray-600">
                                        <span class="w-8 text-left">BAIXA</span>
                                        <span class="w-8 text-center">MÉDIA</span>
                                        <span class="w-8 text-right">ALTA</span>
                                    </div>
                                </div>
                                <p>ALTA: aumentar a sensibilidade da detecção pode gerar mais alertas</p>
                            </div>
                            <!-- <Dropdown class="w-36">
                                <div class="p-3 flex gap-4">
                                    <img src="@/assets/icons/menu.svg" class="w-4" />
                                    <p class="font-semibold">{{ translateSensibility || 'SELECIONE' }}</p>
                                </div>
                                <template #content>
                                    <div class="w-36">
                                        <ul class="py-2">
                                            <li
                                                v-for="option in PROCESSING_OPTIONS"
                                                :key="option.value"
                                                class="font-medium py-2 px-4 text-sm flex items-center"
                                                @click="() => state.sensibility = option.value"
                                            >
                                                <p class="flex-1 text-gray-400">{{ option.label }}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </Dropdown> -->
                        </div>
                    </div>
                    <!-- <button
                        class="py-2 px-6 w-min rounded-lg text-white font-medium mt-6"
                        :style="{ backgroundColor: `${defineColorByClient}` }"
                        @click="() => save('processing')"
                    >
                        Salvar
                    </button> -->
                </div>

                <!-- Regras -->
                <div
                    class="flex flex-col justify-between items-center items-sZ border-b border-neutral-2 pt-4 pb-7"
                >
                    <div class="w-full">
                        <div class="flex justify-between w-full border-b-2 border-primary">
                            <p class="text-left font-medium py-2 bg-white px-7">Motor de Regras</p>

                            <div class="flex items-center gap-4">
                                <p class="text-sm cursor-pointer text-primary" v-if="state.rulesNum !== -1" @click="() => state.rulesNum = -1">redefinir</p>
                                <Dropdown class="w-max">
                                    <div class="p-3 flex gap-4 cursor-pointer">
                                        <p class="text-sm">Escolher regras predefinidas</p>
                                        <img src="@/assets/icons/caret-down.svg" class="w-4" />
                                    </div>
                                    <template #content>
                                        <div class="w-max">
                                            <ul class="py-2">
                                                <li
                                                    v-for="option in RULES_OPTIONS"
                                                    :key="option.value"
                                                    class="font-medium py-2 px-4 text-sm flex items-center cursor-pointer"
                                                    @click="() => state.rulesNum = option.value"
                                                >
                                                    <p class="flex-1 text-gray-400">{{ option.label }}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                        </div>

                        <div class="flex flex-col w-full px-7 mt-4">
                            <!-- Header -->
                            <div class="flex  border-b p-2 font-normal">
                                <div class="flex-1 text-left">Alerta</div>
                                <div class="flex-1">Quantidade</div>
                                <div class="flex-1">Duração (s)</div>
                                <div class="flex-1 text-left">Descrição</div>
                            </div>

                            <!-- Items -->
                            <div
                                v-for="(item, index) in items"
                                :key="index"
                                class="flex p-2 border-b"
                            >
                                <!-- Alerta - Dropdown -->
                                <div class="flex-1">
                                    <select v-model="item.category" class="w-full">
                                        <option :value="item.category" selected hidden>{{
                                            alertSelected(item.category)?.description
                                        }}</option>
                                        <!-- Opções do Dropdown -->
                                        <option
                                            v-for="alert in availableCategories"
                                            :key="alert.value"
                                            :value="alert.value"
                                        >{{ alert.description }}</option
                                        >
                                        <!-- <option value="opcao1">Opção 1</option>
                                        <option value="opcao2">Opção 2</option> -->
                                        <!-- Adicione mais opções conforme necessário -->
                                    </select>
                                </div>

                                <!-- Quantidade - Input do tipo number -->
                                <div class="flex-1">
                                    <input
                                        v-model="item.quantity"
                                        type="number"
                                        class="w-full text-center"
                                    />
                                </div>

                                <!-- Duração - Input do tipo number -->
                                <div class="flex-1">
                                    <input
                                        v-if="item.category !== 96"
                                        v-model="item.duration"
                                        type="number"
                                        class="w-full text-center"
                                    />
                                    <p class="w-full text-center mt-1" v-else>N/A</p>
                                </div>

                                <!-- Descrição - Parágrafo -->
                                <div class="flex-1">
                                    <input
                                        v-if="item.category == 96"
                                        v-model="item.description"
                                        type="text"
                                        class="w-full text-center border-2 border-primary rounded"
                                    />
                                    <!-- <p>{{ alertSelected(item.category)?.description }}</p> -->
                                </div>

                                <!-- Botão para remover o item -->
                                <button @click="removeItem(index)" class="w-5 h-5 text-red-500">
                                    <Icon color="red" icon="trash" size="16px" />
                                </button>
                            </div>
                            <!-- Novo item -->
                            <div
                                class="flex p-2 border-b cursor-pointer text-gray-600"
                                @click="addItem"
                            >
                                Adicionar nova regra...
                            </div>

                            <!-- Botão para adicionar novo item em branco -->
                            <button
                                @click="saveRules"
                                class="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                            >
                                Salvar Regras
                            </button>
                        </div>
                    </div>
                    <!-- <button
                        class="py-2 px-6 w-min rounded-lg text-white font-medium mt-6"
                        :style="{ backgroundColor: `${defineColorByClient}` }"
                        @click="() => save('rules')"
                    >
                        Salvar
                    </button> -->
                </div>
            </div>

            <!-- Footer -->
            <!-- <div class="flex w-full items-stretch">
                <button
                    class="outline-none h-16 flex items-center justify-center w-full"
                    @click="toogleModal"
                >
                    Fechar
                </button>
            </div> -->
        </div>
    </Modal>
</template>

<script lang="ts">
import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    ref,
    watch,
} from "vue";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Modal from "@/components/Modal.vue";
import Icon from "@/components/Icon.vue";
import Dropdown from "./Dropdown.vue";
import Switch from "@/components/ToogleSwitch.vue";
import vm from "@/viewModels/MainViewModel";
import ExameService from "@/services/ExameService";
import { AlertCategoryEnum, RulesDTO } from "@/dtos/RulesDTO";
import { SensibilityEnum } from "@/dtos/ConfigsDTO";

interface ConfigureMediaModalState {
    faceChecked: boolean;
    personChecked: boolean;
    phoneChecked: boolean;
    noiseChecked: boolean;
    speechChecked: boolean;
    warningsChecked: boolean;

    sensibility: SensibilityEnum;

    sensibilityNum: number;
    sensibilityLastValue: number;
    
    rulesNum: number;
    rules: RulesDTO[];
}

export const PROCESSING_OPTIONS = [
    {
        label: "SENSÍVEL",
        value: SensibilityEnum.SENSITIVE,
    },
    {
        label: "REGULAR",
        value: SensibilityEnum.REGULAR,
    },
    {
        label: "PERMISSIVO",
        value: SensibilityEnum.PERMISSIVE,
    },
];

export const RULES_OPTIONS = [
    {
        label: "Menos rígido",
        value: 0,
        rules: [
            {
                id: "17aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "NoFace",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "18aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "MultipleFace",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "19aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "OtherFace",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "20aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadUp",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "21aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadDown",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "22aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadRight",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "23aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadLeft",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "24aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "CellPhone",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "25aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentMultiplePeople",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "26aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentNoPeople",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
            {
                id: "27aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 3,
                duration: 0,
                description: "resposta",
            },
            {
                id: "28aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 3,
                duration: 0,
                description: "prova",
            },
            {
                id: "29aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 3,
                duration: 0,
                description: "ajuda",
            },
            {
                id: "30aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Speech",
                type: 0,
                quantity: 5,
                duration: 2000,
                description: ' ',
            },
        ]
    },    
    {
        label: "Rígido",
        value: 1,
        rules: [
            {
                id: "1aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "NoFace",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "d6ebd07f-ad45-4be1-b081-a3c041cb0fac",
                category: "MultipleFace",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "3aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "OtherFace",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "4aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadUp",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "5aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadDown",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "6aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadRight",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "7aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadLeft",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "8aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "CellPhone",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "9aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentMultiplePeople",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "10aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentNoPeople",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
            {
                id: "11aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 2,
                duration: 0,
                description: "resposta",
            },
            {
                id: "12aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 2,
                duration: 0,
                description: "prova",
            },
            {
                id: "13aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 2,
                duration: 0,
                description: "ajuda",
            },
            {
                id: "14aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Speech",
                type: 0,
                quantity: 3,
                duration: 2000,
                description: ' ',
            },
        ]
    },
    {
        label: "Muito rígido",
        value: 2,
        rules: [
            {
                id: "31aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "NoFace",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "32aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "MultipleFace",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "33aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "OtherFace",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "34aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadUp",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "35aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadDown",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "36aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadRight",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "37aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "HeadLeft",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "38aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "CellPhone",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "39aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentMultiplePeople",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "40aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "EnvironmentNoPeople",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
            {
                id: "41aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 1,
                description: "resposta",
                duration: 0,
            },
            {
                id: "42aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 1,
                description: "prova",
                duration: 0,
            },
            {
                id: "43aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Badword",
                type: 0,
                quantity: 1,
                description: "ajuda",
                duration: 0,
            },
            {
                id: "44aaa4733-9bc8-4d44-8373-1f71f147b49d",
                category: "Speech",
                type: 0,
                quantity: 2,
                duration: 2000,
                description: ' ',
            },
        ]
    },
];

export const ALERT_CATEGORIES = [
    {
        description: "Nenhuma face",
        type: "NoFace",
        value: 1,
    },
    {
        description: "Várias faces",
        type: "MultipleFace",
        value: 2,
    },
    {
        description: "Face de outra pessoa",
        type: "OtherFace",
        value: 3,
    },
    {
        description: "Cabeça para cima",
        type: "HeadUp",
        value: 10,
    },
    {
        description: "Cabeça para baixo",
        type: "HeadDown",
        value: 11,
    },
    {
        description: "Cabeça para direita",
        type: "HeadRight",
        value: 12,
    },
    {
        description: "Cabeça para esquerda",
        type: "HeadLeft",
        value: 13,
    },
    // {
    //     description: "Cabeça inclinada",
    //     type: "HeadRolled",
    //     value: 14,
    // },
    {
        description: "Detecção de celular",
        type: "CellPhone",
        value: 27,
    },
    {
        description: "Várias pessoas",
        type: "EnvironmentMultiplePeople",
        value: 28,
    },
    {
        description: "Nenhuma pessoa",
        type: "EnvironmentNoPeople",
        value: 29,
    },
    {
        description: "Palavra inadequada",
        type: "Badword",
        value: 96,
    },
    {
        description: "Detecção de conversa",
        type: "Speech",
        value: 97,
    },
    {
        description: "Detecção de ruído",
        type: "Noise",
        value: 98,
    },
];

const ConfigModal = defineComponent({
    components: { Icon, Modal, Dropdown, Switch },
    setup() {
        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const translateSensibility = computed(() => {
            return PROCESSING_OPTIONS.find((item) => item.value === state.sensibility)
                ?.label;
        });

        // const usedCategories = computed(() => {
        //     return items.map((item: any) => item.category);
        // });

        const availableCategories = computed(() => {
            return ALERT_CATEGORIES; //.filter((alert) => !usedCategories.value.includes(alert.value));
        });

        const sensibilityToNumber = () => {
            switch(state.sensibility) {
                case SensibilityEnum.PERMISSIVE:
                    return 0;
                case SensibilityEnum.REGULAR:
                    return 1;  
                case SensibilityEnum.SENSITIVE:
                    return 2;
            }
        };

        const alertSelected = (value: number) => {
            return ALERT_CATEGORIES.find((item) => item.value === value);
        };

        const status = reactive<any>({
            selectMediaModalOpened: false,
        });

        const state = reactive<ConfigureMediaModalState>({
            faceChecked: false,
            personChecked: false,
            phoneChecked: false,
            noiseChecked: false,
            speechChecked: false,
            warningsChecked: false,

            sensibility: SensibilityEnum.REGULAR,
            sensibilityNum: 0,
            sensibilityLastValue: 0,

            rulesNum: -1,
            rules: [],
        });

        const items = reactive<any>([
            // { category: '', quantity: 0, duration: 0, description: 'teste da descrição' }
        ]);

        // Convert category type to value
        function mapCategory(category: string | AlertCategoryEnum) {
            const foundCategory = ALERT_CATEGORIES.find(
                (item) => item.type === category
            );
            return foundCategory ? foundCategory.value : category;
        }

        // Fill all descriptions on items
        const adjustDescriptions = () => {
            return items.map((item: any) => {
                return {
                    ...item,
                    duration: item.duration < 100 ? item.duration * 1000 : item.duration,
                    // description: alertSelected(item.category)?.description,
                };
            });
        };

        // GET Configs
        const populateFields = () => {
            items.forEach((item: any, index: any) => removeItem(index));

            const [request] = ExameService.GetConfigs();
            request
                .then((resp) => {
                    state.phoneChecked = resp.videoBehaviourParameters?.detectCellPhone;
                    state.personChecked = resp.videoBehaviourParameters?.detectPerson;
                    state.faceChecked = resp.videoBehaviourParameters?.detectFace;
                    state.noiseChecked = resp.videoBehaviourParameters?.detectNoise;
                    state.speechChecked = resp.videoBehaviourParameters?.detectSpeech;
                    state.warningsChecked =
            resp.videoBehaviourParameters?.showWarningAlerts;

                    state.sensibility = SensibilityEnum[resp.imageBehaviourParameters?.sensibility];

                    state.sensibilityNum = sensibilityToNumber();
                    state.sensibilityLastValue = sensibilityToNumber();
                })
                .catch((error) => console.log(error));

            const [request2] = ExameService.GetRules();
            request2
                .then((resp) => {
                    state.rules = resp;

                    items.splice(0, items.length);

                    resp.forEach(({ category, quantity, duration, description, id }) => {
                        const alreadyInList = items.find((item: any) => item.id === id);
                        if (!alreadyInList) {
                            items.push({
                                category: mapCategory(category),
                                quantity,
                                duration: duration / 1000,
                                description,
                                id,
                            });
                        }
                    });
                })
                .catch((error) => console.log(error));
        };

        const addItem = () => {
            items.push({ category: "", quantity: 0, duration: 0, description: "" });
        };

        const removeItem = (index: any) => {
            items.splice(index, 1);
        };

        const saveActiveMonitoring = () => {
            const [request] = ExameService.PostActiveMonitoring({
                videoBehaviourParameters: {
                    detectCellPhone: state.phoneChecked,
                    detectPerson: state.personChecked,
                    detectFace: state.faceChecked,
                    detectNoise: state.noiseChecked,
                    detectSpeech: state.speechChecked,
                    showWarningAlerts: state.warningsChecked,
                },
            });

            return request
                .then((resp) => {
                    // console.log(resp);
                    toast("Modificação realizada!", {
                        theme: "colored",
                        type: "success",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });
                })
                .catch((error) => {
                    toast("Modificação falhou!", {
                        theme: "colored",
                        type: "error",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });

                    throw new Error(error);
                });
        };

        const saveProcessing = () => {
            const [request] = ExameService.PostProcessing({
                sensibility: state.sensibility,
            });

            return request
                .then((resp) => {
                    // console.log(resp);
                    toast("Modificação realizada!", {
                        theme: "colored",
                        type: "success",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });
                })
                .catch((error) => {
                    toast("Modificação falhou!", {
                        theme: "colored",
                        type: "error",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });

                    throw new Error(error);
                });
        };

        const saveRules = async () => {
            const adjustedItems = adjustDescriptions();
            const [request] = ExameService.PostRules(adjustedItems);

            const confirmResult = await window.confirm('Você tem certeza que deseja alterar esse parâmetro?');

            if (confirmResult) {
                return request
                    .then((resp) => {
                        // console.log(resp);
                        toast("Modificação realizada!", {
                            theme: "colored",
                            type: "success",
                            position: "top-center",
                            transition: "slide",
                            dangerouslyHTMLString: true
                        });
                    })
                    .catch((error) => {
                        toast("Modificação falhou!", {
                            theme: "colored",
                            type: "error",
                            position: "top-center",
                            transition: "slide",
                            dangerouslyHTMLString: true
                        });
                    });
            } else {
                toast("Operação cancelada!", {
                    theme: "colored",
                    type: "warning",
                    position: "top-center",
                    transition: "slide",
                    dangerouslyHTMLString: true
                });
            }

        };

        const toogleModal = () => {
            status.selectMediaModalOpened = !status.selectMediaModalOpened;
        };

        watch(status, () => {
            status.selectMediaModalOpened && populateFields();
        });

        watch(() => state.rulesNum, () => {
            if (state.rulesNum === -1) {
                const [request] = ExameService.GetRules();
                request
                    .then((resp) => {
                        state.rules = resp;

                        items.splice(0, items.length);

                        resp.forEach(({ category, quantity, duration, description, id }) => {
                            const alreadyInList = items.find((item: any) => item.id === id);
                            if (!alreadyInList) {
                                items.push({
                                    category: mapCategory(category),
                                    quantity,
                                    duration: duration / 1000,
                                    description,
                                    id,
                                });
                            }
                        });
                    })
                    .catch((error) => console.log(error));
            } else {
                items.splice(0, items.length);

                const selectedOption = RULES_OPTIONS.find(option => option.value === state.rulesNum);

                selectedOption?.rules.forEach(({ category, quantity, duration, description, id }) => {
                    const alreadyInList = items.find((item: any) => item.id === id);
                    if (!alreadyInList) {
                        items.push({
                            category: mapCategory(category),
                            quantity,
                            duration: duration / 1000,
                            description,
                            id,
                        });
                    }
                });

            }
        });

        async function changeSwitch(type: string) {
            const confirmResult = await window.confirm('Você tem certeza que deseja alterar esse parâmetro?');

            try {
                if (confirmResult) {
                    await saveActiveMonitoring();
                } else {
                    toast("Operação cancelada!", {
                        theme: "colored",
                        type: "warning",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });
                    throw "negado";
                }
            } catch (e) {
                switch(type) {
                    case "face":
                        state.faceChecked = !state.faceChecked;
                        break;
                    case "person":
                        state.personChecked = !state.personChecked;
                        break;
                    case "phone":
                        state.phoneChecked = !state.phoneChecked;
                        break;
                    case "noise":
                        state.noiseChecked = !state.noiseChecked;
                        break;
                    case "speech":
                        state.speechChecked = !state.speechChecked;
                        break;
                    case "warning":
                        state.warningsChecked = !state.warningsChecked;
                        break;
                }
            }            
        }

        async function handleSensibility(event: any) {
            const confirmResult = await window.confirm('Você tem certeza que deseja alterar esse parâmetro?');
                        
            try {
                if (confirmResult) {
                    const inputValue = Number(event.target.value);
                    switch(inputValue) {
                        case 0:
                            state.sensibility = SensibilityEnum.PERMISSIVE;
                            break;
                        case 1:
                            state.sensibility = SensibilityEnum.REGULAR;
                            break;
                        case 2:
                            state.sensibility = SensibilityEnum.SENSITIVE;
                            break;
                    }
    
                    await saveProcessing();
                    
                    state.sensibilityNum = inputValue;
                    state.sensibilityLastValue = inputValue;
                } else {
                    toast("Operação cancelada!", {
                        theme: "colored",
                        type: "warning",
                        position: "top-center",
                        transition: "slide",
                        dangerouslyHTMLString: true
                    });

                    throw "negado";
                }
            } catch (e) {
                state.sensibilityNum = state.sensibilityLastValue;
            }
        }

        return {
            state,
            status,
            items,
            toogleModal,
            addItem,
            removeItem,
            alertSelected,
            changeSwitch,
            handleSensibility,
            saveRules,
            defineColorByClient,
            translateSensibility,
            availableCategories,
            PROCESSING_OPTIONS,
            ALERT_CATEGORIES,
            RULES_OPTIONS,
            sensibilityToNumber,
        };
    },
});

export default ConfigModal;
</script>

<style scoped>
.container {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #04986d;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
