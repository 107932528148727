
import { defineComponent, computed, PropType, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { IAlert } from "@/dtos/AlertaDTO";
import DropdownActionButton from "../DropdownActionButton.vue";
import { VALIDATE_ACTIONS_OPTIONS } from "@/components/audit/AlertList.vue";
import vm from "@/viewModels/MainViewModel";
import { tradutorAlerta } from "@/utils/stringFunctions";

const AlertCard = defineComponent({
    components: {
        Icon,
        DropdownActionButton,
    },
    emits: ['check', 'audit'],
    props: {
        filter: {
            type: String,
            required: true,
        },
        overlay: Boolean,
        alerts: {
            type: Array as PropType<IAlert[]>,
            required: true,
        },
        proctoringStatus: String
    },
    setup(props, { emit }) {
        const state = reactive({
            collapsed: true,
        });

        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const playButton = (start: number) => {
            let videoElements = document.querySelectorAll('video');

            const time = start / 1000;
            if (!videoElements) return null;
            for (let i = 0; i < videoElements.length; i++) {
                videoElements[i].currentTime = time;
            }

            if (videoElements[0].paused) document.getElementById('playBtn')?.click();
        };

        const text = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'Não auditado';
                case "RELEASED":
                    return 'Alerta liberado';
                case "BLOCKED":
                    return 'Indício de fraude';
                case "SYSTEM_FAILURE":
                    return 'Alerta inválido';
                case "WARNING":
                    return 'Aviso';
            }
        };

        const getColor = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return '#C5C5C5';
                case "RELEASED":
                    return '#2CC89B';
                case "BLOCKED":
                    return '#F66F6F';
                case "SYSTEM_FAILURE":
                    return '#FBC55D';
                case "WARNING":
                    return '#60a5fa';
            }
        };

        const handleCollapse = () => {
            state.collapsed = !state.collapsed;
        };

        const handleAudit = (value: string, id: number) => {
            // if (props.alerts.length > 1) {
            //     console.log(props.alerts);
            //     props.alerts.forEach(item => item.checked === true ? emit('audit', value, null) : emit('audit', value, id));
            // } else {
            //     emit('audit', value, id);
            // }

            if (props.alerts.length > 1) {
                let count = 0;

                props.alerts.map(item => {
                    if (item.checked === true) {
                        count++;
                    }
                });

                if (count >= 1) {
                    emit('audit', value, null);
                } else {
                    emit('audit', value, id);
                }
                // props.alerts.forEach(item => item.checked === true ? emit('audit', value, null) : emit('audit', value, id));
            } else {
                emit('audit', value, id);
            }
        };

        function millisToMinutesAndSeconds(millis: number) {
            let minutes = Math.floor(millis / 60000);
            let seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
        }

        return {
            tradutorAlerta,
            playButton,
            handleCollapse,
            text,
            handleAudit,
            state,
            VALIDATE_ACTIONS_OPTIONS,
            getColor,
            millisToMinutesAndSeconds,
            defineColorByClient
        };
    },
});

export default AlertCard;
