<template>
    <div class="flex w-80 h-200px">
        <div class="flex items-center w-full">
            <div class="flex flex-1 flex-col bg-gray-100 h-full rounded-md ml-5" v-if="alerta?.id">
                <div 
                    class="flex items-center justify-between border-gray-300 pb-2 w-full"
                >
                    <div class="rounded flex items-center justify-center w-40 h-30 cursor-pointer" @click="$emit('openImage')">
                        <img 
                            class="rounded-md transition" 
                            :class="!state.loadedImage ? 'animate-pulse' : ''"
                            :src="image"  
                            @load="onImageLoad"
                        />
                    </div>
                    <div class="flex flex-wrap mx-2 w-22 gap-2">
                        <span 
                            title="Não auditado"
                            class="bg-gray-5 rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                            :class="alerta?.alertStatus === 'REVIEWING' && 'border-blue-500 border-3'" 
                            @click="handleAudit('REVIEWING', alerta?.id, alerta?.proctoringResultFrameId)"
                        >
                            <Icon icon="timer" color="#FEFEFE" size="18px" />
                        </span>
                        <span 
                            title="Indício de fraude"
                            class="bg-error rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                            :class="alerta?.alertStatus === 'BLOCKED' && 'border-3 border-blue-500'" 
                            @click="handleAudit('BLOCKED', alerta?.id, alerta?.proctoringResultFrameId)"
                        >
                            <div class="rounded-full border text-white p-1">
                                <Icon icon="error" color="#FEFEFE" size="8px" />
                            </div>
                        </span>
                        <span 
                            title="Alerta inválido"
                            class="bg-eyellow rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                            :class="alerta?.alertStatus === 'SYSTEM_FAILURE' && 'border-3 border-blue-500'" 
                            @click="handleAudit('SYSTEM_FAILURE', alerta?.id, alerta?.proctoringResultFrameId)"    
                        >
                            <Icon icon="alert" color="#FEFEFE" size="18px" />
                        </span>
                        <span 
                            title="Alerta liberado"
                            class="bg-success rounded w-10 h-10 flex items-center justify-center cursor-pointer"
                            :class="alerta?.alertStatus === 'RELEASED' && 'border-3 border-blue-500'" 
                            @click="handleAudit('RELEASED', alerta?.id, alerta?.proctoringResultFrameId)"
                        >
                            <Icon icon="check-circle" color="#FEFEFE" size="18px" />
                        </span>
                    </div>
                </div>
                <div class="mx-2 grow text-center">
                    <span class="text-gray-1 font-bold">
                        {{ tradutorAlerta(alerta.category) }}
                    </span>
                </div>
            </div>
            <div class="flex flex-1 flex-col bg-gray-100 h-full w-full rounded-md ml-5" v-else>
                <div 
                    class="flex items-center flex-1 flex-col p-4"
                >
                    <div 
                        class="flex items-center justify-between border-gray-300 pb-2 w-full"
                    >
                        <div class="rounded flex items-center justify-center w-40 h-30">
                            <img 
                                class="rounded-md transition" 
                                :class="!state.loadedImage ? 'animate-pulse' : ''"
                                :src="image"  
                                @load="onImageLoad"
                            />
                        </div>
                        <div class="flex flex-wrap mx-2 w-22 gap-2 cursor-not-allowed opacity-30">
                            <span 
                                title="Não auditado"
                                class="bg-gray-5 rounded w-10 h-10 flex items-center justify-center cursor-not-allowed"
                            >
                                <Icon icon="timer" color="#FEFEFE" size="18px" />
                            </span>
                            <span 
                                title="Indício de fraude"
                                class="bg-error rounded w-10 h-10 flex items-center justify-center cursor-not-allowed"
                            >
                                <div class="rounded-full border text-white p-1">
                                    <Icon icon="error" color="#FEFEFE" size="8px" />
                                </div>
                            </span>
                            <span 
                                title="Alerta inválido"
                                class="bg-eyellow rounded w-10 h-10 flex items-center justify-center cursor-not-allowed"
                            >
                                <Icon icon="alert" color="#FEFEFE" size="18px" />
                            </span>
                            <span 
                                title="Alerta liberado"
                                class="bg-success rounded w-10 h-10 flex items-center justify-center cursor-not-allowed"
                            >
                                <Icon icon="check-circle" color="#FEFEFE" size="18px" />
                            </span>
                        </div>
                    </div>
                    <div class="mx-2 grow text-center">
                        <span class="text-gray-1 font-bold">
                            Nenhum alerta encontrado!
                        </span>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, PropType, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { IAlert } from "@/dtos/AlertaDTO";
import DropdownActionButton from "../DropdownActionButton.vue";
import { tradutorAlerta } from "@/utils/stringFunctions";
import { alertCategories } from "@/dtos/AlertaDTO";
import AlertListDTO from "@/dtos/AlertListDTO";

const FrameCard = defineComponent({
    components: {
        Icon,
        DropdownActionButton,
    },
    emits: ['check', 'audit', 'openImage'],
    props: {
        id: {
            type: Number,
        },
        frameId: {
            type: Number,
        },
        index: {
            type: Number,
        },
        sessionId: {
            type: Number,
        },        
        image: {
            type: String,
        },
        category: {
            type: String as PropType<alertCategories>,
        },
        alertStatus: {
            type: String,
        },
        alerta: {
            type: Object as PropType<AlertListDTO>,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            collapsed: true,
            loadedImage: false,
        });

        const playButton = (start: number) => {
            const auditVideo = document.getElementById(
                "auditVideo"
            ) as HTMLVideoElement;
            const time = start / 1000;
            auditVideo.currentTime = time;
            auditVideo.play();
        };

        const text = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return 'Não auditado';
                case "RELEASED":
                    return 'Alerta liberado';
                case "BLOCKED":
                    return 'Indício de fraude';
                case "SYSTEM_FAILURE":
                    return 'Alerta inválido';
            }
        };

        const getColor = (status: string) => {
            switch(status) {
                case "REVIEWING":
                    return '#C5C5C5';
                case "RELEASED":
                    return '#2CC89B';
                case "BLOCKED":
                    return '#F66F6F';
                case "SYSTEM_FAILURE":
                    return '#FBC55D';
            }
        };

        const handleCollapse = () => {
            state.collapsed = !state.collapsed;
        };

        const handleAudit = (value: string, id?: number, resultFrameId?: number | string ) => {
            // console.log(value, id, resultFrameId);
            emit('audit', value, id, resultFrameId);
        };

        const VALIDATE_ACTIONS_OPTIONS = [
            {
                label: "Não auditado",
                value: "REVIEWING",
            },
            {
                label: "Liberar Alerta",
                value: "RELEASED",
            },
            {
                label: "Indício de fraude",
                value: "BLOCKED",
            },
            {
                label: "Alerta Inválido",
                value: "SYSTEM_FAILURE",
            },
        ];

        const onImageLoad = () => {
            state.loadedImage = true;
        };

        return {
            tradutorAlerta,
            playButton,
            handleCollapse,
            text,
            handleAudit,
            state,
            VALIDATE_ACTIONS_OPTIONS,
            getColor,
            onImageLoad
        };
    },
});

export default FrameCard;
</script>

<style>
.btn-validation {
  min-width: 120px;
}

.alert-card:not(:first-child) {
  background-color: #523443;
}
</style>